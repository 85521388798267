export class Socialusers {
    SocialID: string;
    FirstName: string;
    LastName: string;
    EmailID: string;
    Address: string;
    City: string;
    District: string;
    State: string;
    Zipcode: string;
    AuthToken: string;
    Provider: string;
    ContactNumber: string;
    Password : string;
    Country: string;
    Company : string;
  }
  