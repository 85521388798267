import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { AuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { Socialusers } from '../../../../_models';
import { AuthenticationService, OwnerService } from '../../../../_services';
import { UserService } from '../../../../_services';
import { AppComponent } from '../../../../app.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-lostpersonalinfo',
  templateUrl: './personalinfo.component.html'
})
export class LostPersonalinfoComponent implements OnInit {


  PersonalForm: FormGroup;
  @Input() showPersonalInfo: boolean = false;
  @Input() minsideOwnerInfo;
  constructor(
    public app: AppComponent,
    private formbuilder: FormBuilder,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private UserService: UserService,
    private ownerService: OwnerService,
    private SpinnerService: NgxSpinnerService,
  ) {
  }
  submitted = false;
  showFoundSuggestion: boolean = true;
  public lat: number;
  public lng: number;
  public zoom: number;
  private geoCoder;
  private user: SocialUser;
  private loggedIn: boolean;
  currentUser: any;
  socialusers = new Socialusers();
  PersonalInfo: any;
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'short_name',
    country: 'long_name',
    postal_code: 'short_name'
  };


  @ViewChild('searchp')
  private searchElementRef: ElementRef;

  ngOnInit() {
    //this.mapsAPILoader.load().then(() => {
    //  this.setCurrentLocation();
    //});
    this.PersonalForm = this.formbuilder.group({
      FirstName: ['', [Validators.required, this.noWhitespaceValidator]],
      LastName: [''],
      EmailID: ['', [Validators.required, this.noWhitespaceValidator]],
      Address: [''],
      ZipCode: [''],
      City: [''],
      District: [''],
      State: [''],
      Country: [''],
      ContactNumber: ['', [Validators.required, this.noWhitespaceValidator]],
      GDPRUserStatus: [''],
      IsTermsAccepted: ['', [Validators.required, this.validateIfChecked]]
    });
    //      console.log(this.minsideOwnerInfo);
    //      if(this.minsideOwnerInfo){
    //       var obj = this.minsideOwnerInfo;
    //       this.PersonalForm.controls['FirstName'].patchValue(obj.FirstName);
    //       this.PersonalForm.controls['LastName'].patchValue(obj.LastName);
    //       this.PersonalForm.controls['EmailID'].patchValue(obj.Email);
    //       this.PersonalForm.controls['Address'].patchValue(obj.Address);
    //       this.PersonalForm.controls['ZipCode'].patchValue(obj.ZipCode);
    //       this.PersonalForm.controls['City'].patchValue(obj.City);
    //       this.PersonalForm.controls['ContactNumber'].patchValue(obj.Phone);
    //       this.PersonalForm.controls['District'].patchValue(obj.District);
    //       this.PersonalForm.controls['State'].patchValue(obj.State);
    //       this.PersonalForm.controls['Country'].patchValue(obj.Country);

    //     }
    //  else{
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser) {
      this.UserService.getUserByEmailID(this.currentUser.EmailID, this.currentUser.Provider).subscribe(res => {
        this.PersonalInfo = res.data;
        this.PersonalForm.controls['FirstName'].patchValue(this.PersonalInfo.FirstName)
        this.PersonalForm.controls['LastName'].patchValue(this.PersonalInfo.LastName)
        this.PersonalForm.controls['EmailID'].patchValue(this.PersonalInfo.EmailID)
        this.PersonalForm.controls['ContactNumber'].patchValue(this.PersonalInfo.ContactNumber)
        if (this.PersonalInfo.Address) {
          this.PersonalForm.controls['Address'].patchValue(this.PersonalInfo.Address)
          this.PersonalForm.controls['ZipCode'].patchValue(this.PersonalInfo.Zipcode)
          this.PersonalForm.controls['City'].patchValue(this.PersonalInfo.City)
          this.PersonalForm.controls['District'].patchValue(this.PersonalInfo.District)
          this.PersonalForm.controls['State'].patchValue(this.PersonalInfo.State)
          this.PersonalForm.controls['Country'].patchValue(this.PersonalInfo.Country)
        }
        else if (this.PersonalInfo.ContactNumber) {
          this.ownerService.getUserDetailByPhone(this.PersonalInfo.ContactNumber).subscribe(resp => {
            if (resp.Data.IsSuccess) {
              this.PersonalForm.controls['Address'].patchValue(resp.Data.fullstreet)
              this.PersonalForm.controls['ZipCode'].patchValue(resp.Data.zip)
              this.PersonalForm.controls['City'].patchValue(resp.Data.city)
              this.PersonalForm.controls['District'].patchValue(resp.Data.district)
              this.PersonalForm.controls['State'].patchValue(resp.Data.state)
              this.PersonalForm.controls['Country'].patchValue(resp.Data.country)
            } else {
              this.mapsAPILoader.load().then(() => {
                this.setCurrentLocation();
              });
            }

          });
        } else {
          this.mapsAPILoader.load().then(() => {
            this.setCurrentLocation();
          });
        }

      });

    } else {
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
      });
    }
    //}  
    //load Places Autocomplete

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // Get each component of the address from the place details,
          // and then fill-in the corresponding field on the form.
          this.PersonalForm.controls['Address'].patchValue(place.formatted_address);

          for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (this.componentForm[addressType]) {
              var val = place.address_components[i][this.componentForm[addressType]];
              if (addressType == "administrative_area_level_1") {
                //  this.state = val;
              }
              if (addressType == "locality") {
                this.PersonalForm.controls['City'].patchValue(val)
              }
              if (addressType == "postal_code") {
                this.PersonalForm.controls['ZipCode'].patchValue(val)
              }
              if (addressType == "administrative_area_level_1") {
                this.PersonalForm.controls['State'].patchValue(val)
              }
              if (addressType == "administrative_area_level_2") {
                this.PersonalForm.controls['District'].patchValue(val)
              }
              if (addressType == "country") {
                this.PersonalForm.controls['Country'].patchValue(val)
              }
            }
          }

          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 12;
        });
      });
    });


  }
  @Output() onPosted = new EventEmitter();
  @Output() onHide = new EventEmitter();
  get e() {
    return this.PersonalForm.controls;
  }
  showPetInfo() {
    this.onHide.emit("show")
  }
  onSubmit() {
    this.submitted = true;
    // console.log(this.PersonalForm.value);
    if (this.PersonalForm.invalid) {
      this.scrollToError();
      return;
    }
    this.onPosted.emit(this.PersonalForm.value)

  }
  toggleFoundSuggestion() {
    this.showFoundSuggestion = !this.showFoundSuggestion;
  }

  public noWhitespaceValidator(control: FormControl) {
    if (control.value && control.value.length > 0) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
    }
  }
  public validateIfChecked(control: FormControl) {
    if (control.value) {
      return null
    }
    else {
      return { 'checked': true };
    }

  }
  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 12;
        this.getAddress(this.lat, this.lng);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      // console.log(results);
      // console.log(status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          // console.log(results[0].formatted_address);
          this.PersonalForm.controls['Address'].patchValue(results[0].formatted_address)
          let place = results[0];
          for (var i = 0; i < place.address_components.length; i++) {
            var addressType = place.address_components[i].types[0];
            if (this.componentForm[addressType]) {
              var val = place.address_components[i][this.componentForm[addressType]];
              // console.log(addressType);
              if (addressType == "administrative_area_level_1") {
                //  this.PersonalForm.controls['State'].patchValue(val)
                //  this.state = val;
              }
              if (addressType == "locality") {
                this.PersonalForm.controls['City'].patchValue(val)
              }
              if (addressType == "postal_code") {
                this.PersonalForm.controls['ZipCode'].patchValue(val)
              }
              if (addressType == "administrative_area_level_1") {
                this.PersonalForm.controls['State'].patchValue(val)
              }
              if (addressType == "administrative_area_level_2") {
                this.PersonalForm.controls['District'].patchValue(val)
              }
              if (addressType == "country") {
                this.PersonalForm.controls['Country'].patchValue(val)
              }
              // console.log(val)

            }
          }

        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }
  getDetailByPhone() {
    let phone = this.PersonalForm.controls['ContactNumber'].value;
    let address = this.PersonalForm.controls['Address'].value;

    if (phone != '' && address == '') {
      this.SpinnerService.show();

      this.ownerService.getUserDetailByPhone(phone).subscribe(resp => {
        if (resp.Data.IsSuccess) {
          this.PersonalForm.controls['Address'].patchValue(resp.Data.fullstreet)
          this.PersonalForm.controls['ZipCode'].patchValue(resp.Data.zip)
          this.PersonalForm.controls['City'].patchValue(resp.Data.city)
          this.PersonalForm.controls['District'].patchValue(resp.Data.district)
          this.PersonalForm.controls['State'].patchValue(resp.Data.state)
          this.PersonalForm.controls['Country'].patchValue(resp.Data.country)
        }

      });
      this.SpinnerService.hide();

    }

  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }
}
