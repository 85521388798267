import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './common/header/header.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

import { SocialLoginModule } from 'angularx-social-login';
import { AuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { PetsComponent } from './pets/pets.component';
import { SidebarModule } from 'ng-sidebar';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDatepickerModule, MatInputModule,MatNativeDateModule,MatIconModule} from '@angular/material';
import { ThanksComponent } from './pets/thanks/thanks.component';
import { CommonComponent } from './common/common.component';
import { ReportComponent } from './pets/report/report.component';
import { ReportLostComponent } from './pets/report/lost/lost.component';
import { ReportFoundComponent } from './pets/report/found/found.component';

import { FoundPersonalinfoComponent } from './pets/report/found/personalinfo/personalinfo.component';
import { FoundPetinfoComponent } from './pets/report/found/petinfo/petinfo.component';
import { LostPetinfoComponent } from './pets/report/lost/petinfo/petinfo.component';
import { LostPersonalinfoComponent } from './pets/report/lost/personalinfo/personalinfo.component';

import { ListComponent } from './pets/list/list.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { DatePipe } from '@angular/common';
import { LostPetListComponent } from './pets/report/lost/petlist/petlist.component';
import { DialogComponent } from './pets/dialog/dialog.component';
import { StaticpagesComponent } from './staticpages/staticpages.component';
import { DetailComponent } from './pets/detail/detail.component';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {NgxPaginationModule} from 'ngx-pagination'; // <-- import the module
import { Ng5SliderModule } from 'ng5-slider';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import {BreadcrumbModule} from 'xng-breadcrumb';
import { NgxSpinnerModule } from "ngx-spinner";
import { ShareModule } from '@ngx-share/core';
import { UpdatepetinfoComponent } from './pets/updatepetinfo/updatepetinfo.component';
import { CompanyUserLoginComponent } from './company-user-login/company-user-login.component';
//import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import * as Hammer from 'hammerjs';
import { FacebookModule } from 'ngx-facebook';

import { QRCodeModule } from 'angularx-qrcode';

export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

const config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider('58407131780-ddtlfh64tcp0pfnmrlch9kkbel4qioko.apps.googleusercontent.com')
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('2770853809663369')
  },
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    HomeComponent,
    PetsComponent,
    ThanksComponent,
    FoundPersonalinfoComponent,
    FoundPetinfoComponent,
    CommonComponent,
    ReportComponent,
    ReportLostComponent,
    ReportFoundComponent,
    LostPetinfoComponent,
    LostPersonalinfoComponent,
    ListComponent,
    DashboardComponent,
    LostPetListComponent,
    DialogComponent,
    StaticpagesComponent,
    DetailComponent,
    UserProfileComponent,
    UpdatepetinfoComponent,
    CompanyUserLoginComponent
  ],
  imports: [
    BrowserModule,
    HammerModule,
    AppRoutingModule,
    NgbModule,
    SocialLoginModule,
    FormsModule,
    ReactiveFormsModule,
    SidebarModule.forRoot(),
    FacebookModule.forRoot(),
    HttpClientModule,
    NgSelectModule,
    NgxSpinnerModule,
    QRCodeModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDGrBJEQfuafRoEsikREQGC-_b7-FrBzmY',
      libraries: ['places']
    }),
    AgmJsMarkerClustererModule,
    BrowserAnimationsModule,
    MatDatepickerModule, MatInputModule,MatNativeDateModule,MatIconModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxPaginationModule,
    Ng5SliderModule,
    ImageCropperModule,
    BreadcrumbModule,
    ShareModule//,
    //InfiniteScrollModule
  ],
  providers: [
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig,
    }, {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    [DatePipe]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}