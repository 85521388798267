import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})

export class PetsService {
  public petListany: any = [];
  constructor(
    private http: HttpClient,
    public translate: TranslateService
  ) {
  }
  
  getCountry() {
    return this.http.get<any>(`${environment.apiUrl + environment.getCountryDropdown}`);
  }

  getSpecies() {
    let browserLang = this.translate.getBrowserLang();
    let LanguageCode = browserLang.match(/en|no/) ? browserLang : 'no';
    return this.http.get<any>(`${environment.apiUrl + environment.getSpeciesDropDown}?LanguageCode=${LanguageCode}`);
  }

  getBreeds(species: string) {
    let browserLang = this.translate.getBrowserLang();
    let LanguageCode = browserLang.match(/en|no/) ? browserLang : 'no';
    let breedURL = `${environment.apiUrl + environment.getBreedsDropDown}?SpeciesID=${species}&GetActive=true&LanguageCode=${LanguageCode}`;
    return this.http.get<any>(breedURL);
  }

  getChipInfo(chipId: string) {
    let chipInfo = `${environment.GetChipInfo}?chipId=${chipId}`;
    return this.http.get<any>(chipInfo);
  }

  IsChipIDExists(chipId: string, OperationTypeID: any) {
    let IsChipID = `${environment.apiUrl + environment.IsChipIDExists}?chipId=${chipId}&OperationTypeID=${OperationTypeID}`;
    return this.http.get<any>(IsChipID);
  }

  GetLostFoundPetsListPartialFields(filter: any) {
    let URL = `${environment.apiUrl + environment.GetLostFoundPetsListPartialFields}`;
    return this.http.get(URL, { params: filter });
    // return this.http.get<any>(URL,{params: filter});
  }
  GetLostFoundPetsListfromMincide(filter: any) {
    let URL = `${environment.apiUrlMinside + environment.GetLostFoundPetsList}`;
    return this.http.get(URL, { params: filter });
    // return this.http.get<any>(URL,{params: filter});
  }

  GetPetsForLandingPage(filter: any) {
    let URL = `${environment.apiUrl + environment.GetPetsForLandingPage}`;
    return this.http.get(URL, { params: filter });

  }
  GetPetsForLandingPage1() {
    let URL = `${environment.apiUrl + environment.GetPetsForLandingPage}`;
    return this.http.get(URL);
  }
  GetLandingPageLostPets(filter: any) {
    let URL = `${environment.apiUrl + environment.GetLandingPageLostPets}`;
    return this.http.get<any>(URL, { params: filter });
  }

  GetLandingPageFoundPets(filter: any) {
    let URL = `${environment.apiUrl + environment.GetLandingPageFoundPets}`;
    return this.http.get(URL, { params: filter });
  }

  GetLandingPageLostPets_(filter: any) {
    let URL = `${environment.apiUrl + environment.GetLandingPageLostPets}`;
    return this.http.get(URL, { params: filter });
  }

  GetLostPetsForLandingPage() {
    let URL = `${environment.apiUrl + environment.GetLostPetsForLandingPage}`;
    return this.http.get(URL);

  }

  GetFoundPetsForLandingPage() {
    let URL = `${environment.apiUrl + environment.GetFoundPetsForLandingPage}`;
    return this.http.get(URL);

  }
  GetAllPetsForLandingPage() {
    let URL = `${environment.apiUrl + environment.GetAllPetsForLandingPage}`;
    return this.http.get(URL);

  }


  addUpdateLostFoundPetDetails(petdata) {
    const HttpUploadOptions = {
      headers: new HttpHeaders(
        {
          //"Content-Type": "application/x-www-form-urlencoded",
          "APIKEY": "560C51EE-D641-487E-AD6F-1C2A525C2727"
        })
    }
    return this.http.post(`${environment.apiUrl + environment.AddUpdateLostFoundPetDetails}`, petdata, HttpUploadOptions);
  }

  GetLostFoundPetsList(filter: any) {
    let URL = `${environment.apiUrl + environment.GetLostFoundPetsFilterList}`;
    return this.http.get<any>(URL, { params: filter });
  }

  GetLostFoundPetsListForHomePage(filter: any) {
    let URL = `${environment.apiUrl + environment.GetLostFoundPetsListForHomePage}`;
    return this.http.get<any>(URL, { params: filter });
  }

  // GetReportedPetsCount(filter: any)
  // {
  //   let URL = `${environment.apiUrl + environment.GetReportedPetsCount }`;
  //   return this.http.get<any>(URL,{params: filter});
  // }

  // GetReportedPetsCountMap(filter: any)
  // {
  //   let URL = `${environment.apiUrl + environment.GetReportedPetsCountMap }`;
  //   return this.http.get<any>(URL,{params: filter});
  // }

  GetLostFoundPetDetailsByID(parameters) {
    if (parameters != null && parameters != undefined) {
      return this.http.get<any>(`${environment.apiUrl + environment.GetLostFoundPetDetailsByID}`, { params: parameters });
    }
    else
      return null;
  }

  GetLostFoundPetsListByUser(filter: any) {
    let URL = `${environment.apiUrl + environment.GetLostFoundPetsListByUser}`;
    return this.http.post<any>(URL, filter);
  }
  GetLostFoundPetsListCountByUser(filter: any) {
    let URL = `${environment.apiUrl + environment.GetLostFoundPetsListCountByUser}`;
    return this.http.post<any>(URL, filter);
  }

  markPetAsReunited(postData: any) {
    let URL = `${environment.apiUrl + environment.MarkPetasReunited}`;
    return this.http.post<any>(URL, postData);
  }
  GetPetSuggestion(postData: any) {
    let URL = `${environment.apiUrl + environment.GetPetSuggestionbyLostFoundId}`;
    return this.http.post<any>(URL, postData);
  }
  RemoveReportedPet(postData: { LostFoundId: any; UpdatedBy: any; }) {
    let URL = `${environment.apiUrl + environment.RemoveReportedPet}`;
    return this.http.post<any>(URL, postData);
  }

  UpdatePetInfo(petInfo) {
    return this.http.post(`${environment.apiUrl + environment.UpdatePetInfo}`, petInfo);
  }

  assignListData(petList: any): void {
    this.petListany = [...petList];
  }

  fetchListData(): any {
    return this.petListany ;
  }

}