import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { OwnerService, AuthenticationService } from '../../../../_services';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-lost-petlist',
  templateUrl: './petlist.component.html'
})
 
export class LostPetListComponent implements OnInit {

  petList : any;
  petCount : any;
  minsideOwnerDetail : any;
  currentUser:any;
  @Output() onMarkLost = new EventEmitter();
  constructor(private ownerService: OwnerService, private authenticationService : AuthenticationService,
    private router: Router,private SpinnerService: NgxSpinnerService) {
  //constructor() {}
  }
  ngOnInit(){
    this.currentUser = this.authenticationService.currentUserValue; 
    if (this.currentUser.AuthToken != ''){
      var authToken = this.currentUser.AuthToken;
      this.SpinnerService.show();
      this.ownerService.getOwnerPetList(authToken).subscribe((res: any) => {  
        this.SpinnerService.hide(); 
        if (res.Status ==2){
          // alert("Authentication Token is expired. Please login again");
          alert("Autentiseringstoken er utløpt. Vennligst logg inn igjen");
          //Remove Local Storage
          localStorage.removeItem('currentUser');
          localStorage.removeItem('socialusers');
          this.router.navigate(["/login"]);
        }
        if (res.Data.lostAndFoundPetDetails == null ){
          this.petList = null;
          this.petCount = 0;
        }else{
          this.minsideOwnerDetail ={
            "FirstName" : res.Data.FirstName,
            "LastName" : res.Data.LastName,
            "Email" : res.Data.Email,
            "Address" : res.Data.Address,
            "ZipCode" : res.Data.ZipCode,
            "City" : res.Data.City,
            "Phone" : res.Data.Phone,
          };
          this.petList = res.Data.lostAndFoundPetDetails;
          this.petCount = res.Data.lostAndFoundPetDetails.length;
          if (this.petCount == 1){
            this.markPetAsLost(this.petList[0]);
          }
        }
      }) 
    }else{
      // Need to remove local storage ???
      this.router.navigate(['/login']);
    }
    
  }
  markPetAsLost(pet: any): void {
    if(confirm("Are you sure you want to mark "+ pet.PetName + " as Lost ?")) {
      let petData = {
        "petDetail": pet,
        "ownerDetail" : this.minsideOwnerDetail,
        "source":"MINSIDE"
      }
      this.onMarkLost.emit(petData);
    }
  }

}