<div class="home-banner login-mar">
  <div class="container">
    <h5>{{ 'Sign-In' | translate }}</h5>
    <h2>{{ 'Lost & Found' | translate }}</h2>
    <p>{{ 'Cotainer string' | translate }}</p>
  </div>
</div>

<div class="home-browse-pet">
  <div class="container">
<div class="row find-search-section-box login-socil">
  
  
  
  
  <div class="col-4">
    <a (click)="socialSignIn('facebook')">
    <div class="find-search-section">
        <div class="login-logo">
      <img src="assets/img/fb-icon-login.svg" alt="" title="" class="mx-auto">
      </div>
      
      <p>
        <!-- <img src="assets/img/fb_text_login.png" alt="" title=""> -->
        Logg inn med Facebook
      </p>
    </div>
  </a>
  </div>
  

<div class="col-4">
      <a (click)="socialSignIn('google')">
        <div class="find-search-section">
            <div class="login-logo">
            <img src="assets/img/google-icon-login.svg" alt="" title="" class="mx-auto">
            </div>
        <p>
          <!-- <img src="assets/img/google_text_login.png" alt="" title=""> -->
          Logg inn med Google
        </p>
        </div>
      </a>
  </div>

  <div class="col-4">

    <a (click)="LoginWithMinside()">
      <div class="find-search-section">
        <div class="login-logo">
        <img src="assets/img/dyid-icon-login.svg" alt="" title="" class="mx-auto dyirlogo">
      </div>
      <p>
        <!-- <img src="assets/img/dyreid_text_login.png" alt="" title=""> -->
        Logg inn med DyreID
      </p>
    </div>
    </a>
 
 </div>
</div>
</div>
</div>
<!-- ///////////////////////////////////////////////////////////////////////////////// -->