import { Component, OnInit } from '@angular/core';
import { PetsService } from '../../_services';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { ShareService } from '@ngx-share/core';
import { FacebookService, UIParams, UIResponse, InitParams } from 'ngx-facebook';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})

export class DetailComponent implements OnInit {

  public lat: number;
  public lng: number;
  public zoom: number;
  public showhideBackButton: boolean;
  public showhomePageBrowseListing: boolean;
  public petType: string = 'current';
  public isPreviousDisabled: boolean = false;
  public isNextDisabled: boolean = false;
  public qrCodeString: string = '';
  public currentUser: any = null;
  public petName = '';
  public calledFrom = '';
  public operationTypeID: string = '';
  public searchFilters: any = null;


  constructor(
    private Pets: PetsService,
    private router: ActivatedRoute,
    private meta: Meta,
    private title: Title,
    private routeObj: Router,
    public share: ShareService,
    private fb: FacebookService
  ) {
    this.qrCodeString = window.location.href;
   }


  petImagePath = environment.apiBaseUrl + "/Static/Images/";
  LostFoundPetID;
  pet;
  detailMessageString = environment.lostFoundBaseUrl + "dashboard/pet-detail?PetID=";
  detailMessageStringEMail = "Les mer : " + environment.lostFoundBaseUrl + "dashboard/pet-detail?PetID=";
  ShareMessage: string;
  public petListany: any = [];

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.calledFrom = localStorage.getItem('_calledFrom');
    this.searchFilters = JSON.parse(localStorage.getItem('SearchFilters'));
    //console.log(this.currentUser)
    this.LostFoundPetID = atob(this.router.snapshot.paramMap.get('petId'));
    this.operationTypeID = this.router.snapshot.queryParamMap.get('opType');
    if (this.calledFrom == 'home') {
      this.showhomePageBrowseListing = true;
    }
    else {
      this.showhomePageBrowseListing = false;
    }
    //console.log(this.operationTypeID)
    this.getPetDetails();
    this.showhideBackButton = true;
    var currenturl = window.location.href;
    // if(currenturl.toLowerCase().indexOf('report-lost') > 0 || currenturl.toLocaleLowerCase().indexOf('report-found') > 0 ||  currenturl.toLocaleLowerCase().indexOf('dashboard') > 0 ){
    if (currenturl.toLowerCase().indexOf('report-lost') > 0 || currenturl.toLocaleLowerCase().indexOf('report-found') > 0) {
      this.showhideBackButton = false;
    }
    this.ShareMessage = '';
  }

  getPetDetails = () => {
    this.isPreviousDisabled = false;
    this.isNextDisabled = false;
    const page = localStorage.getItem('_page');
    const userId = (page && page === 'dashboard') ? this.currentUser.ID : '';
    let parameters: any = {
      userId: userId,
      LostFoundPetID: this.LostFoundPetID,
      type: this.petType,
      OperationTypeID: this.operationTypeID
    }

    if (page && page === 'list_page') {
      parameters.lat = this.searchFilters.lat;
      parameters.lng = this.searchFilters.lng;
      parameters.radiousDistance = this.searchFilters.radiousDistance;
      parameters.BreedID = this.searchFilters.BreedID;
      parameters.SpeciesID = this.searchFilters.SpeciesID;
      parameters.page = page;
    } else {
      parameters.lat = '';
      parameters.lng = '';
      parameters.radiousDistance = '';
      parameters.BreedID = '';
      parameters.SpeciesID = '';
      parameters.page = '';
    }

    if (this.calledFrom == 'home') {
      if (this.petType == 'next') {
        this.petListany = this.Pets.fetchListData();
        for (let i = 0; i < this.petListany.length; i++) {
          if (this.LostFoundPetID == this.petListany[i].ChipId) {
            if (i == this.petListany.length - 1)
              this.isNextDisabled = true;
            else
              this.LostFoundPetID = this.petListany[i + 1].ChipId;
            break;
          }
        }
      }
      else if (this.petType == 'previous') {
        this.petListany = this.Pets.fetchListData();
        for (let i = 0; i < this.petListany.length; i++) {
          if (this.LostFoundPetID == this.petListany[i].ChipId) {
            if (i == 0)
              this.isPreviousDisabled = true;
            else
              this.LostFoundPetID = this.petListany[i - 1].ChipId;
            break;
          }
        }
      }
      this.Pets.getChipInfo(this.LostFoundPetID).subscribe(response => {

        let titleContent = '';
        if (response) {
          this.pet = response;
          this.qrCodeString = window.location.href;
          const petName = `${this.pet.PetColor ? this.pet.PetColor : ''} ${this.pet.Breed ? this.pet.Breed : ''} ${this.pet.Species ? this.pet.Species : ''} ${(this.pet.OperationTypeID == "1") ? 'Tapt' : 'Funnet'} I ${this.pet.City ? this.pet.City : ''}`
          this.petName = petName;

          this.lat = Number(parameters.lat);
          this.lng = Number(parameters.lng);
          this.zoom = 15;
          let subtext = '';
          if (response.OperationTypeID == 1) {
            subtext = 'Tapt';
          } else if (response.OperationTypeID == 2) {
            subtext = "Funnet";
          }

          // let HairType ='';
          if (response.HairType == 1) {
            this.pet.HairType = 'Langhåret';
          } else if (response.HairType == 2) {
            this.pet.HairType = "Korthåret";
          }
          this.pet.Gender = response.PetSex;
          if (this.pet.BirthDate && this.pet.BirthMonth && this.pet.BirthYear)
            this.pet.BirthDate = this.pet.BirthDate + '.' + this.pet.BirthMonth + '.' + this.pet.BirthYear;
          let color = '';
          if (response.PetColor) {
            color = response.PetColor + " ";
          }
          if (!this.pet.PetOriginalPhoto) {
            if (parameters.SpeciesID == 1)
              this.pet.PetOriginalPhoto = "assets/img/home/lost-animl-bg.png";
            else
              this.pet.PetOriginalPhoto = "assets/img/home/homepet_cat.png";
          }
          if (this.pet.PetOriginalPhoto) {
            const img = new Image();
            img.src = this.pet.PetOriginalPhoto;
            img.onerror = () => {
              if (parameters.SpeciesID == 1)
                this.pet.PetOriginalPhoto = "assets/img/home/lost-animl-bg.png";
              else
                this.pet.PetOriginalPhoto = "assets/img/home/homepet_cat.png";
            };
          }
          if (!this.pet.PetPhoto) {
            if (parameters.SpeciesID == 1)
              this.pet.PetPhoto = "assets/img/home/lost-animl-bg.png";
            else
              this.pet.PetPhoto = "assets/img/home/homepet_cat.png";
          }
          if (this.pet.PetPhoto) {
            const img = new Image();
            img.src = this.pet.PetPhoto;
            img.onerror = () => {
              if (parameters.SpeciesID == 1)
                this.pet.PetPhoto = "assets/img/home/lost-animl-bg.png";
              else
                this.pet.PetPhoto = "assets/img/home/homepet_cat.png";
            };
          }
          let city = '';
          if (response.City) {
            city = response.City;
          }
          if (response.Breed) {
            this.ShareMessage = this.ShareMessage + color + response.Breed + " (" + response.Species + ") " + subtext + " | " + city;
          } else {
            this.ShareMessage = this.ShareMessage + color + response.Species + " " + subtext + " | " + city
          }
          this.ShareMessage = this.ShareMessage ? this.ShareMessage.replace(/^\s+|\s+$/gm, '') : '';
          // this.meta.addTags([
          //   { name: 'og:title', content: this.ShareMessage },
          //   { name: 'og:description', content: response.AdditionalInfo },
          //   { name: 'og:image', content: this.petImagePath + response.ImagePath },
          // ]);

        } else {
          if (this.petType === 'previous') {
            this.isPreviousDisabled = true;
          } else if (this.petType === 'next') {
            this.isNextDisabled = true;
          }
        }
        this.title.setTitle(this.ShareMessage);

      })
    }
    else {
      this.Pets.GetLostFoundPetDetailsByID(parameters).subscribe(response => {
        let titleContent = '';
        if (response) {
          this.pet = response;
          this.qrCodeString = window.location.href;
          const petName = `${this.pet.Color ? this.pet.Color : ''} ${this.pet.MasterBreed ? this.pet.MasterBreed : ''} ${this.pet.MasterSpecies ? this.pet.MasterSpecies : ''} ${(this.pet.OperationTypeID == "1") ? 'Tapt' : 'Funnet'} I ${this.pet.City ? this.pet.City : ''}`
          this.petName = petName;
          if (this.petType !== 'current') {
            const urlString = petName.trim().toLowerCase().split(' ').join('-');
            const encodedString = encodeURI(urlString);
            const atobStr = btoa(this.pet.LostFoundID)
            this.routeObj.navigate([`list-pets/pet-detail/${atobStr}/${encodedString}`]);
            this.LostFoundPetID = response.LostFoundID;
          }

          this.lat = Number(response.lat);
          this.lng = Number(response.lng);
          this.zoom = 15;


          let subtext = '';
          if (response.OperationTypeID == 1) {
            subtext = 'Tapt';
          } else if (response.OperationTypeID == 2) {
            subtext = "Funnet";
          }

          // let HairType ='';
          if (response.HairType == 1) {
            this.pet.HairType = 'Langhåret';
          } else if (response.HairType == 2) {
            this.pet.HairType = "Korthåret";
          }
          if (!this.pet.ShowChipNoToOthers)
            this.pet.ChipID = "";
          if (response.Gender == 1) {
            this.pet.Gender = 'Hunndyr';
          } else if (response.Gender == 2) {
            this.pet.Gender = 'Hunn';
          }

          let color = '';
          if (response.Color) {
            color = response.Color + " ";
          }
          let city = '';
          if (response.City) {
            city = response.City;
          }
          if (response.MasterBreed) {
            this.ShareMessage = this.ShareMessage + color + response.MasterBreed + " (" + response.MasterSpecies + ") " + subtext + " | " + city;
          } else {
            this.ShareMessage = this.ShareMessage + color + response.MasterSpecies + " " + subtext + " | " + city
          }
          this.ShareMessage = this.ShareMessage ? this.ShareMessage.replace(/^\s+|\s+$/gm, '') : '';
          // this.meta.addTags([
          //   { name: 'og:title', content: this.ShareMessage },
          //   { name: 'og:description', content: response.AdditionalInfo },
          //   { name: 'og:image', content: this.petImagePath + response.ImagePath },
          // ]);

        } else {
          if (this.petType === 'previous') {
            this.isPreviousDisabled = true
          } else if (this.petType === 'next') {
            this.isNextDisabled = true
          }
        }
        this.title.setTitle(this.ShareMessage);

      })
    }
  }

  openModal(event) {
    document.getElementById(event.currentTarget.parentElement.nextElementSibling.id).style.display = "flex";
  }

  closeModal(event) {
    document.getElementById(event.currentTarget.parentElement.id).style.display = "none";
  }

  shareWithFacebook(id): void {
    let shareUrl = this.detailMessageString + id;
    //shareUrl = 'https://lostfound.staging.dyreid.no/pet-detail?PetID=1099';
    let url = 'http://www.facebook.com/sharer.php?u=' + shareUrl;
    let newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus()
    }
  }

  goBack() {
    const page = localStorage.getItem('_page');
    if (page && page === 'list_page') {
      this.routeObj.navigate(['/list-pets'], { queryParams: { OperationTypeID: 1, radiousDistance: 10 } });
    } else if (page && page === 'dashboard') {
      this.routeObj.navigate(['/dashboard']);
    } else if (page && page === 'report-found') {
      this.routeObj.navigate(['/report-found']);
    } else if (page && page === 'report-lost') {
      this.routeObj.navigate(['/report-lost']);
    } else {
      this.routeObj.navigate(['/']);
    }

  }

  printPDF() {
    // const printContent = document.getElementById("containerID");
    // document.getElementById("qr-code").style.display = 'block';
    // const printRemoveContent = document.getElementById("ThumbnailImageBlockID");
    // const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
    // var html = printContent.innerHTML.replace(printRemoveContent.innerHTML, "");
    // // WindowPrt.document.write(printContent.innerHTML);
    // WindowPrt.document.write(html);
    // WindowPrt.document.close();
    // WindowPrt.focus();
    // WindowPrt.print();
    // WindowPrt.close();
    // document.getElementById("qr-code").style.display = 'none';
    this.generatePDF();
     
  }

  generatePDF() {
    var data = document.getElementById('print-me');
    data.style.display = 'block';
    html2canvas(data, {allowTaint: true, useCORS: true}).then(canvas => {
      var imgWidth = 208;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4');
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      pdf.save(this.pet.PetName);
      setTimeout(() => {
        data.style.display = 'none'
          // window.close();
      }, 500)

    });
  }

  previousItem = () => {
    this.petType = 'previous';
    this.getPetDetails();
  }

  nextItem = () => {
    this.petType = 'next';
    this.getPetDetails();
  }

  onSwipe = (e) => {
    if (e.type === "swiperight") {
      this.previousItem();
    } else {
      this.nextItem();
    }
  }

  shareOnFacebook() {

    const currentUrl = window.location.href;
    const imgUrl = this.pet.ThumbnailImagePath ? this.petImagePath + this.pet.ThumbnailImagePath : this.petImagePath + this.pet.ImagePath;
    const desc = this.pet.AdditionalInfo ? this.pet.AdditionalInfo : this.petName;
    console.log('currentUrl: ', currentUrl);
    console.log('imgUrl: ', imgUrl);
    console.log('desc: ', desc);
    this.meta.addTags([
      { name: 'og:url', content: currentUrl },
      { name: 'og:title', content: 'THIS IS NEW TITLE!!' },
      { name: 'og:description', content: desc },
      { name: 'og:image', content: imgUrl },
    ]);
    // this.meta.updateTag({ name: 'title', content: 'THIS IS NEW TITLE!!' })
    const initParams: InitParams = {
      appId: '2770853809663369',
      xfbml: true,
      version: 'v2.8'
    };
    this.fb.init(initParams).then(success => {
      console.log('START: ', success);
      const params: UIParams = {
        href: 'https://lostfound.staging.dyreid.no/list-pets/pet-detail/NTU3Nw%3D%3D/amerikansk-cocker-spaniel-hund-tapt-i-chandigarh',
        method: 'share'
      };

      this.fb.ui(params)
        .then((res: UIResponse) => console.log('RES : ', res))
        .catch((e: any) => console.log('ERROR : ', e));
    }).catch(err => {
      console.log('ERROR: ', err);
    });

  }

  errorHandler = (e) => {
		e.target.src = environment.placeholderImg;
	}


}

interface Ipetdetalils {
  LostFoundID: number;
  UserLoginID: number;
  IsChipped: string;
  ChipID: string;
  OperationTypeID: number;
  PetName: string;
  PetType: string;
  SpeciesID?: number;
  MasterSpecies: string;
  BreedID?: number;
  MasterBreed: string;
  Gender: string;
  Casted: string;
  DateOfBirth?: string;
  DisplayDateOfBirth: string;
  LostFoundDate?: string;
  DisplayLostFoundDate: string;
  DisplayUpdatedDate: string;
  Area: string;
  Commune: string;
  City: string;
  State: string;
  StateID?: number;
  Country: string;
  Company: String;
  CountryID?: number;
  AdditionalInfo: string;
  ImageID?: number;
  ImagePath: string;
  ThumbImagePath: string;
  Color: string;
  CoatLength: string;
  Description: string;
  lat: string;
  lng: string;
  DistancePoint: string;
  OwnerContactEmail: string;
  IsReunite: boolean;
  CreatedDate: string;
  CreatedBy: string;
  UpdatedDate: string;
  UpdatedBy: string;
  Status: boolean;
}