import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        // console.log(localStorage.getItem('currentUser'))
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(socialuser) {
        return this.http.post<any>(`${environment.apiUrl + environment.AddUpdateLogInUser}`, socialuser)
            .pipe(map(user => {
                // console.log(user);
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user.data));
                this.currentUserSubject.next(user.data);
                return user;
            })); 
    }
    
    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('socialUser');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('socialusers');
        this.currentUserSubject.next(null);
    }
    GetListCount() {
        return this.http.get<any>(`${environment.apiUrlM}`)
    }

    GetDailyReportedPets() {
        const HttpUploadOptions = {
            headers: new HttpHeaders({ "Content-Type": "application/json", 
              "APIKey":"32FF43A4-5CB9-424D-89BC-59242E4D4DF1"}) 
          }
        return this.http.get<any>(`${environment.DailyRegisterPetsCount}`, HttpUploadOptions)
    }
    CompanyUserLogIn(socialuser) {
        debugger;
        return this.http.post<any>(`${environment.apiUrl + environment.CompanyUserLogIn}`, socialuser)
            .pipe(map(user => {
                // console.log(user);
                if(user.data != null || user.data != undefined)
                {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user.data));
                this.currentUserSubject.next(user.data);
                return user;
                }
                else{
                    alert("invalid login details");
                }
            })); 
    }
}

