export class Pets {  
    petDetails: {
      UserLoginID: number,
      IsChipped: 1,
      ChipID: string,
      OperationTypeID: 1,
      PetName: string,
      MasterSpeciesID: 1,
      MasterSpecies: string,
      MasterBreedID: 12,
      MasterBreed: string,
      Gender: string,
      DateOfBirth: string,
      LostFoundDate: string,
      Area: string,
      Commune: string,
      State: string,
      StateID: 0,
      Country: string,
      CountryID: 1,
      AdditionalInfo: string,
      ImageID: 0,
      ImagePath: string,
      Color: string,
      CoatLength: string,
      Description: string,
      lat: string,
      lng: string,
      IsReunite: 1,  
      Status: 1,
      petImgUrl:string
    };
    userInfo: {
      LoginUserID: 0,
      FirstName: string,
      LastName: string,
      DateOfBirth: string,
      GenderID: 0,
      Gender: string,
      Age: string,
      RelationshipID: 0,
      Relationship: string,
      EmailID: string,
      ContactNumber: string,
      Address: string,
      LandID: 0,
      Land: string,
      ZipCode: string,
      City: string,
      CountryID: 1,
      Country: string,
      Municipal: string,
      IsTermsAccepted: 0,
      GDPRUserStatus: 0,
      Latitude: string,
      Longitude: string,
      Status: 1,
      State: string
    }
}