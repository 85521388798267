import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CompanyUserLoginComponent } from '../app/company-user-login/company-user-login.component'
import { HomeComponent } from './home/home.component';
import { ReportFoundComponent } from './pets/report/found/found.component';
import { ReportLostComponent } from './pets/report/lost/lost.component';
import { ListComponent } from './pets/list/list.component';

import { ThanksComponent } from './pets/thanks/thanks.component';
import { AuthGuard } from './_helpers';
import { MinsideAuthCallbackComponent } from './minside-auth-callback/minside-auth-callback.component';
import { LostPetListComponent } from './pets/report/lost/petlist/petlist.component';
import { StaticpagesComponent } from '../app/staticpages/staticpages.component';
import { DetailComponent } from './pets/detail/detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserProfileComponent } from '../app/user-profile/user-profile.component';
import { UpdatepetinfoComponent } from '../app/pets/updatepetinfo/updatepetinfo.component';

const routes: Routes = [
  // { path: 'login', component: LoginComponent,
  //  data: {breadcrumb: { label: 'login'} }
  // },

  {
    path: 'login', component: LoginComponent

  },
  {
    path: 'Companylogin', component: CompanyUserLoginComponent

  },
  {
    path: 'report-found', component: ReportFoundComponent, canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Report Found Pet' } }
  },
  {
    path: 'report-found', data: { breadcrumb: { label: 'Report Found' } },
    children: [
      {
        path: 'pet-detail',
        component: DetailComponent,
        data: { breadcrumb: { label: 'Details' } }
      }
    ]
  },
  {
    path: 'report-lost', component: ReportLostComponent, canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Report Lost Pet' } }
  },
  {
    path: 'report-lost', data: { breadcrumb: { label: 'Report Lost' } },
    children: [
      {
        path: 'pet-detail',
        component: DetailComponent,
        data: { breadcrumb: { label: 'Details' } }
      }
    ]
  },
  {
    path: 'list-pets', component: ListComponent,
    data: { breadcrumb: { label: 'Browse List' } }
  },
  {
    path: 'list-pets', data: { breadcrumb: { label: 'Browse List' } },
    children: [
      {
        path: 'pet-detail/:petId/:petName',
        component: DetailComponent,
        data: { breadcrumb: { label: 'Details' } }
      }
    ]
  },

  {
    path: 'aboutus', component: StaticpagesComponent,
    data: { breadcrumb: { label: 'About Us' } }
  },
  {
    path: 'services', component: StaticpagesComponent,
    data: { breadcrumb: { label: 'Services' } }
  },
  {
    path: 'termsandconditions', component: StaticpagesComponent,
    data: { breadcrumb: { label: 'Services' } }
  },
  {
    path: 'privacypolicy', component: StaticpagesComponent,
    data: { breadcrumb: { label: 'Privacy Policy' } }
  },
  {
    path: 'userprofile', component: UserProfileComponent,
    data: { breadcrumb: { label: 'User Profile' } }
  },
  { path: 'minside-auth-callback/:id/:referPage', component: MinsideAuthCallbackComponent },
  { path: 'minside-auth-callback/:id', component: MinsideAuthCallbackComponent },

  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard],
    data: { breadcrumb: { label: 'Dashboard' } }
  },
  {
    path: 'dashboard', data: { breadcrumb: { label: 'Dashboard' } },
    children: [
      {
        path: 'pet-detail',
        component: DetailComponent,
        data: { breadcrumb: { label: 'Pet Details' } }
      },
      {
        path: 'pet-detailupdate',
        component: UpdatepetinfoComponent,
        data: { breadcrumb: { label: 'Update Pet Details ' } }
      },

    ]
  },
  // { path: '', component: HomeComponent, data: {
  //     breadcrumb: {
  //       label: 'Home',
  //       info: { icon: 'home', iconType: 'material' }
  //     }
  //   } },
  // ------------------------------
  {
    path: '', component: HomeComponent,
    data: { breadcrumb: { label: 'Home' } }
  },
  {
    path: '', data: { breadcrumb: { label: 'Home' } },
    children: [
      {
        path: 'pet-detail',
        component: DetailComponent,
        data: { breadcrumb: { label: 'Pet Details' } }
      }
    ]
  },
  // -----------------------------
];
export const AppRoutingModule = RouterModule.forRoot(routes);