// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
   //apiUrl : "http://localhost:51679/api",
  apiUrl : "https://apilostfound.staging.dyreid.no/api",
  apiUrlM : "https://api.dyreid.no/api/Owner/GetListCount",
  DailyRegisterPetsCount :"https://api.dyreid.no/api/Pet/GetAllPetsCount?OnlyActivePet=true",
  //apiUrlM : "https://idygoapi.dyreidentitet.no/api/Owner/GetListCount",  
  apiBaseUrl : "https://apilostfound.staging.dyreid.no",
  // apiBaseUrl : "http://localhost:4200",
  getCountryDropdown : "/Common/GetCountryList",
  getSpeciesDropDown : "/Common/GetSpeciesDropDown",
  getBreedsDropDown :  "/Common/GetBindSpeciesBreed",
  GetPageStaticData :  "/Common/GetPageStaticData",
  IsChipIDExists : "/Common/IsChipIDExists",
  //GetChipInfo : "http://localhost:52470/api/Owner/GetChipInformation",
  GetChipInfo : "https://api.staging.dyreid.no/api/Owner/GetChipInformation",
  AddUpdateLogInUser: "/LoginAccount/AddUpdateLogInUser",
  CompanyUserLogIn:"/LoginAccount/CompanyUserLogIn",// for company users login.
  GetLostFoundPetsListPartial: "/LostFoundPetDetails/GetLostFoundPetsListPartial",
  AddUpdateLostFoundPetDetails: "/LostFoundPetDetails/AddUpdateLostFoundPetDetails",
  GetLostFoundPetsListPartialFields: "/LostFoundPetDetails/GetLostFoundPetsListPartialFields", // this api with filters
  GetLostFoundPetsList: "/Pet/GetLostFoundPetsImagesUpladedByOwners",
  GetLostFoundPetsFilterList: "/LostFoundPetDetails/GetLostFoundPetsFilterList",
  GetLostFoundPetsListForHomePage: "/LostFoundPetDetails/GetLostFoundPetsListForHomePage",
  GetLostFoundPetDetailsByID: "/LostFoundPetDetails/GetLostFoundPetDetailsByID",
  UpdateUserInfo:"/UserDetails/UpdateUserInfo",
  UpdatePetInfo:"/LostFoundPetDetails/UpdatePetInfo",
  GetUserByEmailID : "/LoginAccount/GetUserByEmailID",
  GetOwnerAuthToken: "/Owner/GetAuthToken",
  GetOwnerPetList: "/Owner/GetOwnerPetList",
  //urlMinsideLogin : "http://localhost:59605/Security/Login?ReturnUrl=%2f&lostfound=1",
  urlMinsideLogin : "https://minside.staging.dyreidentitet.no/Security/Login?ReturnUrl=%2f&lostfound=1",
  //lostFoundBaseUrl : "http://localhost:4200/",
  lostFoundBaseUrl : "https://lostfound.staging.dyreid.no/",
  //apiUrlMinside : "http://localhost:52470/api",
  apiUrlMinside : "https://api.staging.dyreid.no/api",
  GetLostFoundPetsListByUser : "/LostFoundPetDetails/GetLostFoundPetsListByUser",
  MarkPetasReunited : "/LostFoundPetDetails/MarkPetasReunited",
  GetPetSuggestionbyLostFoundId : "/LostFoundPetDetails/GetReportedPetSuggestion",
  RemoveReportedPet : "/LostFoundPetDetails/RemoveReportedPet",
  GetOwnerDetailEasyConnectAPI: 'https://api.dyreid.no/api/Owner/GetDetailByPhoneNumber',
  // GetReportedPetsCount :'/LostFoundPetDetails/GetReportedPetsCount',
  // GetReportedPetsCountMap :'/LostFoundPetDetails/GetReportedPetsCountMap',
  //GetProxyApi:'https://cors-anywhere.herokuapp.com/',
  GetLostFoundPetsListCountByUser :'/LostFoundPetDetails/GetLostFoundPetsListCountByUser',
  GetPetsForLandingPage:'/LostFoundPetDetails/GetPetsForLandingPage',
  GetLandingPageLostPets : '/LostFoundPetDetails/GetLandingPageLostPets',
  GetLandingPageFoundPets : '/LostFoundPetDetails/GetLandingPageFoundPets',
  GetLostPetsForLandingPage:'/LostFoundPetDetails/GetLostFoundPetsForLandingPage?OperationTypeID=1',
  GetFoundPetsForLandingPage:'/LostFoundPetDetails/GetLostFoundPetsForLandingPage?OperationTypeID=2',
  GetAllPetsForLandingPage:'/LostFoundPetDetails/GetLostFoundPetsForLandingPage?OperationTypeID=3',
  placeholderImg : 'https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
