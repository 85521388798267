<div class="profile-form">
  <div class="container">

    <div class="owner-info" style="margin-bottom: 200px;">
      <form [formGroup]="PersonalForm" (ngSubmit)="onSubmit()">
        <h2 class="mt-5 mb-5 text-center">
          <span class="left-count" (click)="showPetInfo()">1</span>
          {{ 'Contact information' | translate }}
          <span class="active right-count">2</span>
        </h2>
        <div class="row">

          <div class="col-md-9 col-sm-12 m-auto">

            <div class="form-group align-items-center">
              <label class="col-sm-3 col-form-label required-star">{{ 'First Name' | translate }}</label>
              <div class="col-sm-9 form-input-side">
                <input type="text" class="form-control-plaintext" value="" formControlName="FirstName" minlength="3"
                  maxlength="25" [ngClass]="{'is-invalid':submitted && e.FirstName.errors}">
                <div *ngIf="submitted && e.FirstName.errors" class="invalid-feedback">
                  <div *ngIf="e.FirstName.errors.required">{{'Name is required' | translate}}</div>
                  <div *ngIf="e.FirstName.errors.minlength">{{'Name is of more then 3 char'| translate}}</div>
                  <div *ngIf="e.FirstName.errors.maxlength">{{'Name is not more then 25 char'| translate}}</div>
                  <div *ngIf="e.FirstName.errors.whitespace && !e.FirstName.errors.required">{{'Space is not allowed'|
                    translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group align-items-center">
              <label class="col-sm-3 col-form-label">{{ 'Last Name' | translate }}</label>
              <div class="col-sm-9 form-input-side">
                <input type="text" class="form-control-plaintext" value="" formControlName="LastName">
              </div>
            </div>

            <div class="form-group align-items-center">
              <label class="col-sm-3 col-form-label required-star">{{ 'Email' | translate }}</label>
              <div class="col-sm-9 form-input-side">
                <input type="text" class="form-control-plaintext"
                  pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" value="" formControlName="EmailID"
                  [ngClass]="{'is-invalid':submitted && e.EmailID.errors}">
                <div *ngIf="submitted && e.EmailID.errors" class="invalid-feedback">
                  <div *ngIf="e.EmailID.errors.required">{{'Email is required'| translate}}</div>
                  <div *ngIf="e.EmailID.errors.pattern">{{'Please Enter a valid Email-ID'| translate}}</div>
                  <div *ngIf="e.EmailID.errors.whitespace && !e.EmailID.errors.required">{{'Space is not allowed'|
                    translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group align-items-center">
              <label class="col-sm-3 col-form-label required-star">{{ 'Phone No.' | translate }}</label>
              <div class="col-sm-9 form-input-side">
                <input type="text" class="form-control-plaintext" value="" minlength="5" (blur)="getDetailByPhone()"
                  maxlength="10" formControlName="ContactNumber"
                  [ngClass]="{'is-invalid':submitted && e.ContactNumber.errors}">
                <div *ngIf="submitted && e.ContactNumber.errors" class="invalid-feedback">
                  <div *ngIf="e.ContactNumber.errors.required">{{'Phone Number is required' | translate}}</div>
                  <div *ngIf="e.ContactNumber.errors.minlength">{{'Phone Number is of more then 5 char'| translate}}
                  </div>
                  <div *ngIf="e.ContactNumber.errors.maxlength">{{'Phone Number is not more then 10 char'| translate}}
                  </div>
                  <div *ngIf="e.ContactNumber.errors.whitespace && !e.ContactNumber.errors.required">{{'Space is not
                    allowed'| translate}}</div>
                </div>
              </div>
            </div>

            <div class="form-group align-items-center">
              <label class="col-sm-3 col-form-label">{{ 'Address' | translate }}</label>
              <div class="col-sm-9 form-input-side">

                <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                  formControlName="Address" placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off"
                  spellcheck="off" type="text" #searchp>
              </div>
            </div>

            <div class="form-group align-items-center">
              <label class="col-sm-3 col-form-label">{{ 'Zipcode' | translate }}</label>
              <div class="col-sm-9 form-input-side">
                <input type="text" class="form-control-plaintext" value="" formControlName="ZipCode">
              </div>
            </div>

            <div class="form-group align-items-center">
              <label class="col-sm-3 col-form-label">{{ 'City' | translate }}</label>
              <div class="col-sm-9 form-input-side">
                <input type="text" class="form-control-plaintext" value="" formControlName="City">
              </div>
            </div>

            <div class="form-group align-items-center">
              <label class="col-sm-3 col-form-label">{{ 'District' | translate }}</label>
              <div class="col-sm-9 form-input-side">
                <input type="text" class="form-control-plaintext" value="" formControlName="District">
              </div>
            </div>
            <div class="form-group align-items-center">
              <label class="col-sm-3 col-form-label">{{ 'State' | translate }}</label>
              <div class="col-sm-9 form-input-side">
                <input type="text" class="form-control-plaintext" value="" formControlName="State">
              </div>
            </div>
            <div class="form-group align-items-center">
              <label class="col-sm-3 col-form-label">{{ 'Country' | translate }}</label>
              <div class="col-sm-9 form-input-side">
                <input type="text" class="form-control-plaintext" value="" formControlName="Country">
              </div>
            </div>

            <div class="form-check align-items-center col-12 mb-4">
              <input type="checkbox" class="form-check-input" value="1" id="exampleCheck1"
                formControlName="GDPRUserStatus">
              <a class="chkbx-terms" for="exampleCheck1">{{ 'Make my profile public' | translate }}</a>
            </div>
            <div class="form-check align-items-center col-12 mb-4">
              <input type="checkbox" class="form-check-input" id="exampleCheck2" value="1"
                formControlName="IsTermsAccepted" [ngClass]="{'is-invalid':submitted && e.IsTermsAccepted.errors}">
              <a class="chkbx-terms" for="exampleCheck2" href="/termsandconditions" target="blank">{{ 'Accept terms and
                conditions' | translate }}</a>
              <div *ngIf="submitted && e.IsTermsAccepted.errors" class="invalid-feedback">
                <div *ngIf="e.IsTermsAccepted.errors.required">{{'Please accept terms and conditions'| translate}}</div>
                <div *ngIf="e.IsTermsAccepted.errors.checked && !e.IsTermsAccepted.errors.required">{{'Please accept
                  terms and conditions'| translate}}</div>
              </div>
            </div>

            <div class="btns-box row">
              <div class="mt-2 mb-4 col-md-12">
                <button type="submit" class="btn btn-primary mr-3">{{ 'Submit' | translate }}</button>
                <button type="button" (click)="this.app.Cancel()" class="btn btn-secondary">{{ 'Cancel' | translate
                  }}</button>
              </div>
            </div>

          </div>
        </div>
      </form>
    </div>


  </div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
</ngx-spinner>