import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PetsService } from '../../_services';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { DatePipe } from '@angular/common';
import { AppComponent } from '../../app.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { DateAdapter } from '@angular/material';

@Component({
  selector: 'app-updatepetinfo',
  templateUrl: './updatepetinfo.component.html',
  styleUrls: ['./updatepetinfo.component.scss']
})

export class UpdatepetinfoComponent implements OnInit {

  petUpdateForm: FormGroup;
  showFoundSuggestion: boolean = true;
  @Input() showPersonalInfo;
  @Input() minsidePetInfo: any;
  public lat: number;
  public lng: number;
  public zoom: number;
  private geoCoder;
  public Area: any;
  private user: SocialUser;
  private loggedIn: boolean;
  minDate: Date;
  maxDate: Date;
  chipFound: string = "";
  IsLost: boolean;
  IsChipID: Boolean;


  @ViewChild('search')

  private searchElementRef: ElementRef;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    public app: AppComponent,
    private pets: PetsService,
    private formbuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router: ActivatedRoute,
    // private router: Router,
    private routeObj: Router,
    private authService: AuthService,
    private datePipe: DatePipe,
    private modalService: NgbModal
  ) {
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    // const currentYear = new Date().getFullYear();
    // const currentDate = new Date().getDate();

    // this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date();

  }

  LostFoundPetID;
  pet;
  country: any;
  MasterSpecies: any;
  MasterBreeds: any;
  submitted = false;
  IsChipped = false;
  petImagePath = environment.apiBaseUrl + "/Static/Images/";
  fileData: File = null;
  croppedFileData: any = null;
  previewUrl: any = "assets/img/user_add.png";
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  imageError = "";
  closeResult = "";

  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    sublocality_level_2: 'long_name',
    administrative_area_level_1: 'long_name',
    administrative_area_level_2: 'long_name',
    country: 'long_name',
    postal_code: 'short_name',
    postal_town: 'long_name'

  };

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
  open(content) {
    this.previewUrl = "assets/img/user_add.png";
    this.croppedFileData = "";
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.previewUrl = this.croppedImage;
      this.croppedFileData = this.croppedImage;
      //return false;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.petUpdateForm.controls['Image'].patchValue("");
      //return false;
    });
    return false;
  }
  private getDismissReason(reason: any): string {
    this.petUpdateForm.controls['Image'].patchValue("");
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.router.queryParams.subscribe(ReqParams => {
      this.LostFoundPetID = ReqParams.PetID;
    });
    this.petUpdateForm = this.formbuilder.group({
      IsChipped: ['0'],
      ChipID: [''],
      PetName: [''],
      MasterSpeciesID: ['', Validators.required],
      MasterBreedID: ['', Validators.required],
      LostFoundDate: [new Date(), Validators.required],
      DateOfBirth: [''],
      Color: [''],
      Gender: ['1'],
      HairType: ['1'],
      Area: ['', Validators.required],
      State: ['', [Validators.required, this.noWhitespaceValidator]],
      Commune: ['', [this.noWhitespaceValidator]],
      District: ['', [this.noWhitespaceValidator]],
      City: ['', [Validators.required, this.noWhitespaceValidator]],
      Country: ['', Validators.required],
      ZipCode: ['', Validators.required],
      AdditionalInfo: [''],
      LostFoundID: [''],
      ImagePath: [''],
      Image: [''],
      croppedFileData: [''],
      lat: [''],
      lng: [''],
      OperationTypeID: ['']
    });

    this.showPersonalInfo = false;
    this.petUpdateForm.value.IsChipped = 0;

    this.pets.getCountry().subscribe(res => {
      this.country = res;
    });
    this.pets.getSpecies().subscribe(species => {
      this.MasterSpecies = species;

    });


    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      // this.getAddress(this.lat, this.lng);
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.fillAddress(place)
          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 15;
        });
      });
    });
    // get pet details to update
    let parameters: any = {
      userId: '',
      LostFoundPetID: this.LostFoundPetID,
      type: 'current',
      OperationTypeID: '',
      lat:'',
      lng:'',
      radiousDistance:'',
      BreedID:'',
      SpeciesID:'',
      page:''
    }

    this.pets.GetLostFoundPetDetailsByID(parameters).subscribe(response => {

      this.pet = response;
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.pets.getSpecies().subscribe(species => {
      // 		this.MasterSpecies = species;
      // 		if (this.pet.MasterSpeciesID) {
      // 			this.petUpdateForm.controls['MasterSpeciesID'].patchValue(Number(this.pet.MasterSpeciesID))
      // 			this.getBreeds()
      // 		}
      //   });




      //   this.petUpdateForm.controls["MasterSpeciesID"].patchValue(this.pet.SpeciesID);
      //   this.petUpdateForm.controls["MasterBreedID"].patchValue(this.pet.BreedID);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);
      //   this.petUpdateForm.controls["PetName"].patchValue(this.pet.PetName);

      //   let titleContent = '';
      //   if(response){
      //     this.lat=Number(response.lat);
      //     this.lng = Number(response.lng);
      //     this.zoom = 15;


      //     let subtext = '';
      //     if(response.OperationTypeID == 1){
      //       subtext = 'Lost';
      //     }else if (response.OperationTypeID == 2){
      //       subtext = "Found";
      //     }

      //     // let HairType ='';
      //     if(response.HairType == 1){
      //       this.pet.HairType = 'Long Hair';
      //     }else if (response.HairType == 2){
      //       this.pet.HairType = "Short Hair";
      //     }

      //     if(response.Gender == 1){
      //       this.pet.Gender = 'Male';
      //     }else if (response.Gender == 2){
      //       this.pet.Gender = "Female";
      //     }
      //     let color = '';
      //     if(response.Color){
      //         color = response.Color + " "; 
      //     }
      //     let city = '';
      //     if(response.City){
      //       city = response.City ;
      //     }
      //     if(response.MasterBreed){
      //       this.ShareMessage = this.ShareMessage + color +  response.MasterBreed + " (" + response.MasterSpecies + ") " + subtext + " | " + city;
      //     }else{
      //       this.ShareMessage = this.ShareMessage + color+  response.MasterSpecies + " " + subtext + " | " + city
      //     }
      //     this.ShareMessage = this.ShareMessage ? this.ShareMessage.replace(/^\s+|\s+$/gm, '') : '';
      //     this.meta.addTags([
      //       {name: 'og:title', content: this.ShareMessage},
      //       {name: 'og:description', content: response.AdditionalInfo},
      //       {name: 'og:image', content: this.petImagePath + response.ImagePath},
      //     ]);

      //   }else{
      //     this.routeObj.navigate(['']); //in case id doesnot exist
      //   }
      //   this.title.setTitle(this.ShareMessage);

      //  })

      // end get pet details to update
      this.minsidePetInfo = response;
      this.IsLost = (response.OperationTypeID == 1 ? true : false);
      this.IsChipID = (response.ChipID ? true : false);

      if (this.minsidePetInfo) {
        var genderValue = 0;
        var hairDisplayValue = 0;
        var obj = this.minsidePetInfo;
        var dob = null;
        if (obj.BirthYear && obj.BirthMonth && obj.BirthDate) {
          dob = new Date(obj.BirthYear, obj.BirthMonth, obj.BirthDate);
        }
        // this.petUpdateForm.controls['IsChipped'].patchValue('1');
        this.petUpdateForm.controls['ChipID'].patchValue(obj.ChipID);
        // Chip id bind
        if (obj.ChipID) {
          this.petUpdateForm.controls['ChipID'].disable();
        }
        // pet name bind
        this.petUpdateForm.controls['PetName'].patchValue(obj.PetName);
        if (obj.PetName && obj.OperationTypeID == 1) {
          this.petUpdateForm.controls['PetName'].disable();
        }
        // species id bind
        this.pets.getSpecies().subscribe(species => {
          this.MasterSpecies = species;
          if (this.pet.MasterSpeciesID) {
            this.petUpdateForm.controls['MasterSpeciesID'].patchValue(Number(this.pet.MasterSpeciesID))
            this.getBreeds()
          }
        });
        //this.petUpdateForm.controls['MasterSpeciesID'].patchValue(obj.MasterSpeciesID);
        if (obj.MasterSpeciesID) {
          this.petUpdateForm.controls['MasterSpeciesID'].patchValue(obj.MasterSpeciesID);
          //this.petUpdateForm.controls['MasterSpeciesID'].disable();

        }


        //Additional info bind
        this.petUpdateForm.controls["AdditionalInfo"].patchValue(obj.AdditionalInfo);
        this.petUpdateForm.controls["LostFoundID"].patchValue(this.LostFoundPetID);

        this.petUpdateForm.controls['DateOfBirth'].patchValue(obj.DateOfBirth);
        this.petUpdateForm.controls['LostFoundDate'].patchValue(obj.LostFoundDate);
        if (obj.LostFoundDate) {
          this.petUpdateForm.controls['LostFoundDate'].disable();
        }
        this.petUpdateForm.controls['Color'].patchValue(obj.Color);
        // if (obj.Color) {
        //   this.petUpdateForm.controls['Color'].disable();
        // }
        if (obj.Area) {
          this.petUpdateForm.controls["Area"].patchValue(obj.Area);
          this.petUpdateForm.controls["ZipCode"].patchValue(obj.ZipCode);
          this.petUpdateForm.controls["City"].patchValue(obj.City);
          this.petUpdateForm.controls["District"].patchValue(obj.District);
          this.petUpdateForm.controls["State"].patchValue(obj.State);
          this.petUpdateForm.controls["Country"].patchValue(obj.Country);

          this.petUpdateForm.controls["Area"].disable();
          this.petUpdateForm.controls["ZipCode"].disable();
          this.petUpdateForm.controls["City"].disable();
          this.petUpdateForm.controls["State"].disable();
          this.petUpdateForm.controls["Country"].disable();
        }

        if (obj.Gender) {
          genderValue = obj.Gender;
        }

        this.petUpdateForm.controls['Gender'].patchValue(genderValue);

        if (obj.HairType) {
          hairDisplayValue = obj.HairType.toString();
        }
        this.petUpdateForm.controls['HairType'].patchValue(hairDisplayValue);


        // this.pets.getBreeds(obj.MasterSpeciesID).subscribe(breeds => {
        //   debugger;
        //   this.MasterBreeds = breeds;
        //   this.petUpdateForm.controls['MasterBreedID'].patchValue(obj.MasterBreedID);
        //   let data = this.MasterBreeds.find(ob => ob.Value === obj.MasterBreedID);


        //   if (data) {
        //     this.petUpdateForm.controls['MasterBreedID'].disable();
        //   } else {
        //     if (obj.BreedName) {
        //       let data1 = this.MasterBreeds.find(ob => ob.Text.toLowerCase() === obj.BreedName.toLowerCase());
        //       if (data1) {
        //         obj.BreedId = data1.Value; // in case id does not match patch with Name
        //         this.petUpdateForm.controls['MasterBreedID'].disable();
        //       }
        //     }
        //   }
        //   this.petUpdateForm.controls['MasterBreedID'].patchValue(obj.BreedId);
        // });
      }
    })
  }

  get petf() {
    return this.petUpdateForm.controls;
  }
  // getChipInfo() {
  //   this.petUpdateForm.controls['PetName'].patchValue("");
  //   this.petUpdateForm.controls['MasterSpeciesID'].patchValue("");
  //   this.petUpdateForm.controls['Color'].patchValue("");
  //   this.petUpdateForm.controls['MasterBreedID'].patchValue("");

  //   // this.pets.IsChipIDExists(this.petUpdateForm.value.ChipID, this.petUpdateForm.value.OperationTypeID).subscribe(IsChipExist =>{
  //   this.pets.IsChipIDExists(this.petUpdateForm.value.ChipID, 1).subscribe(IsChipExist => {
  //     if (IsChipExist == true && this.petUpdateForm.value.ChipID.trim() != "") {
  //       this.chipFound = "Pet with ChipID " + this.petUpdateForm.value.ChipID + " Already exists";
  //       this.petUpdateForm.controls['ChipID'].patchValue("");
  //     }
  //     else {
  //       this.pets.getChipInfo(this.petUpdateForm.value.ChipID).subscribe(chipInfo => {
  //         if (chipInfo.ChipId) {
  //           this.petUpdateForm.controls['PetName'].patchValue(chipInfo.PetName);
  //           this.petUpdateForm.controls['MasterSpeciesID'].patchValue(chipInfo.SpeciesID);
  //           this.petUpdateForm.controls['Color'].patchValue(chipInfo.PetColor);
  //           this.pets.getBreeds(chipInfo.SpeciesID).subscribe(breeds => {
  //             this.MasterBreeds = breeds;
  //             this.petUpdateForm.controls['MasterBreedID'].patchValue(chipInfo.BreedID);
  //           });
  //         }
  //         else {
  //           this.chipFound = "No data found for this chipId. Please fill details below."
  //         }
  //       });
  //     }
  //   });
  // }

  // getChipInfo() {

  //     this.petUpdateForm.controls['PetName'].patchValue("");
  //     this.petUpdateForm.controls['MasterSpeciesID'].patchValue("");
  //     this.petUpdateForm.controls['Color'].patchValue("");
  //     this.petUpdateForm.controls['MasterBreedID'].patchValue("");
  //     this.pets.getChipInfo(this.petUpdateForm.value.ChipID).subscribe(chipInfo => {
  //     if (chipInfo.ChipId) {
  //       this.petUpdateForm.controls['PetName'].patchValue(chipInfo.PetName);
  //       this.petUpdateForm.controls['MasterSpeciesID'].patchValue(chipInfo.SpeciesID);
  //       this.petUpdateForm.controls['Color'].patchValue(chipInfo.PetColor);
  //       this.pets.getBreeds(chipInfo.SpeciesID).subscribe(breeds => {
  //         this.MasterBreeds = breeds;
  //         this.petUpdateForm.controls['MasterBreedID'].patchValue(chipInfo.BreedID);
  //       });
  //     }
  //     else {
  //       this.chipFound = "No data found for this chipId. Please fill details below."
  //     }
  //   });
  // }

  getBreeds() {
    //debugger;
    this.petUpdateForm.controls['MasterBreedID'].patchValue("");
    this.pets.getBreeds(this.petUpdateForm.value.MasterSpeciesID).subscribe(breeds => {
      this.MasterBreeds = breeds;
      if (this.pet.MasterBreedID) {
        this.petUpdateForm.controls['MasterBreedID'].patchValue(Number(this.pet.MasterBreedID))
        this.petUpdateForm.controls['MasterSpeciesID'].disable();
        if (this.pet.OperationTypeID == 1) {
          this.petUpdateForm.controls['MasterBreedID'].disable();
        }
      }
    });
  }
  @Output() onPosted = new EventEmitter();

  onSubmit() {


    debugger;
    this.submitted = true;
    // console.log(this.petUpdateForm.value);
    if (this.petUpdateForm.invalid) {
      return;
    }
    this.pets.UpdatePetInfo(this.petUpdateForm.value).subscribe(Response => {
      alert("Info oppdatert suksessivt !!!");
    });

    // debugger;
    // this.submitted = true;
    // if (this.imageError) {
    //   this.scrollToError();
    //   return;
    // }
    // if (this.petUpdateForm.invalid) {
    //   this.scrollToError();
    //   return;
    // }
    // this.petUpdateForm.controls['ImagePath'].patchValue(this.fileData);
    // this.petUpdateForm.controls['croppedFileData'].patchValue(this.croppedFileData);
    // this.petUpdateForm.controls['lat'].patchValue(this.lat);
    // this.petUpdateForm.controls['lng'].patchValue(this.lng);
    // // if (this.minsidePetInfo) {
    // //   this.onPosted.emit(this.petUpdateForm.getRawValue())
    // // } else {    
    //   this.onPosted.emit(this.petUpdateForm.value)
    // // }
    // const firstElementWithError = document.querySelector('.xng-breadcrumb-root');
    // this.scrollTo(firstElementWithError);


  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 15;
        this.getAddress(this.lat, this.lng);
      });
    }
  }

  // toggleFoundSuggestion() {
  //   this.showFoundSuggestion = !this.showFoundSuggestion;
  // }

  markerDragEnd($event: any) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.getAddress(this.lat, this.lng);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.fillAddress(results[0])
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  fillAddress(mapresult) {
    this.zoom = 15;
    let area = mapresult.formatted_address;
    //this.petUpdateForm.controls['Area'].patchValue(results[0].formatted_address)
    let place = mapresult;
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      if (this.componentForm[addressType]) {
        var val = place.address_components[i][this.componentForm[addressType]];

        if (addressType == "administrative_area_level_1") {
          this.petUpdateForm.controls['State'].patchValue(val)
          area = area.replace(val, "")

          //  this.state = val;
        }
        if (addressType == "administrative_area_level_2") {
          this.petUpdateForm.controls['District'].patchValue(val)
          area = area.replace(val, "")

          //  this.state = val;
        }
        if (addressType == "country") {
          this.petUpdateForm.controls['Country'].patchValue(val)
          area = area.replace(val, "")
          //  this.state = val;
        }
        if (addressType == "locality") {
          this.petUpdateForm.controls['City'].patchValue(val)
          area = area.replace(val + ", ", "")
          //  this.state = val;
        }
        if (addressType == "postal_town") {
          this.petUpdateForm.controls['City'].patchValue(val)
          area = area.replace(val + ", ", "")
        }
        if (addressType == "postal_code") {
          this.petUpdateForm.controls['ZipCode'].patchValue(val)
          area = area.replace(val + ", ", "")
          //  this.state = val;
        }


      }
    }
    area = area.trim();
    let comma = area.substring(area.length, area.length - 1)
    if (comma == ",") {
      area = area.substring(0, area.length - 1)
    }
    area = area.trim();
    this.petUpdateForm.controls['Area'].patchValue(area)
  }

  fileProgress(fileInput: any) {
    this.imageError = null;
    if (<File>fileInput.target.files && <File>fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 15000000;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 + 'kb';

        return false;
      }
      if (!allowed_types.includes(fileInput.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }

      let Content: HTMLElement = document.getElementById("contentNew") as HTMLElement
      Content.click();
      this.imageChangedEvent = event;
      this.fileData = <File>fileInput.target.files[0];
      // this.preview();
    }
  }

  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }
  public noWhitespaceValidator(control: FormControl) {
    if (control.value && control.value.length > 0) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
    }
  }

  // public onIsChippedChange() {
  //   this.petUpdateForm.controls["ChipID"].clearValidators();
  //   if (this.petUpdateForm.controls.IsChipped.value == 1) {
  //     this.petUpdateForm.controls["ChipID"].setValidators([Validators.required, this.noWhitespaceValidator]);
  //   }
  //   this.petUpdateForm.controls["ChipID"].updateValueAndValidity()
  // }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

  openModal(event) {
    document.getElementById(event.currentTarget.parentElement.nextElementSibling.id).style.display = "block";
  }
  closeModal(event) {
    document.getElementById(event.currentTarget.parentElement.id).style.display = "none";
  }

}

