import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwnerService } from '../_services/owner.service';
import { AuthenticationService } from '../_services';
import { Socialusers } from '../_models';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-minside-auth-callback',
  templateUrl: './minside-auth-callback.component.html',
  styleUrls: ['./minside-auth-callback.component.scss']
})
export class MinsideAuthCallbackComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, 
    private ownerService : OwnerService, 
    private authenticationService:AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,) { }

  userToken : string;
  socialusers = new Socialusers(); 
  referPage : string;
  ngOnInit(): void {
    
    this.referPage = this.activatedRoute.snapshot.params['referPage'];
    this.userToken = this.activatedRoute.snapshot.params['id'];
    
    this.GetAuthTokenByUserToken(this.userToken);
  }

  GetAuthTokenByUserToken(uToken : string){
    this.ownerService.getOwnerAuthToken(uToken).subscribe((res: any) => {  
     
      if (res.Status == 1){
        this.socialusers.SocialID = res.Data.Id;
        this.socialusers.FirstName = res.Data.FirstName;
        this.socialusers.LastName = res.Data.LastName;
        this.socialusers.EmailID = res.Data.Email;
        this.socialusers.Address = res.Data.Address;
        this.socialusers.Zipcode = res.Data.ZipCode;
        this.socialusers.City = res.Data.City;
        this.socialusers.AuthToken = res.Data.AuthToken;
        this.socialusers.Provider = res.Data.Provider;
        this.socialusers.ContactNumber = res.Data.ContactNumber;
        this.socialusers.District = res.Data.District;
        this.socialusers.State = res.Data.StateName;
        this.socialusers.Country= res.Data.CountryName
        this.Savesresponse(this.socialusers);
      }
      else{
        alert("User Token is expired. Please login with Minside again");
        this.router.navigate(['/login']);
      }
    })  
  }
  Savesresponse(socialusers: Socialusers) {  
    this.authenticationService.login(socialusers).subscribe((res: any) => {  
      this.socialusers = res.data;  
      localStorage.setItem('socialusers', JSON.stringify( this.socialusers)); 
      if(this.referPage == "found"){
        window.opener.location.href = `${environment.lostFoundBaseUrl}/report-found`;
        window.close();
      } else{
        window.opener.location.href = `${environment.lostFoundBaseUrl}/report-lost`;
        window.close();
      }
      
      
    });  
  }  
}
