import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pets } from '../../../_models';
import { Router } from '@angular/router';
import { PetsService } from '../../../_services';
import { AuthenticationService } from '../../../_services';
import { environment } from '../../../../environments/environment';
import { DialogComponent } from '../../dialog/dialog.component';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-lost',
  templateUrl: './lost.component.html',
  styleUrls: ['./lost.component.scss']
})
export class ReportLostComponent implements OnInit {

  showFoundSuggestion: boolean = false;
  public showPersonalInfo: boolean = false;
  public petsinfo: any;
  public personalsinfo: any;
  public minsidePetInfo: any;
  public minsideOwnerInfo: any;
  private petDetails = { petDetails: {}, Image: {} };
  pets = new Pets();
  petsSuggestion: any;
  showMinsidePetList: boolean = false;
  currentUser: any;
  petImagePath = environment.apiBaseUrl + "/Static/Images/";
  petDetailPath = environment.apiBaseUrl + "/pet-detail?PetID=";

  constructor(
    private router: Router,
    private petsService: PetsService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {

    this.currentUser = this.authenticationService.currentUserValue;

    if (this.currentUser.Provider == 'MINSIDE') {
      this.showMinsidePetList = true;
      this.showPersonalInfo = false;
    }
    localStorage.removeItem('ListPageNo');
    localStorage.removeItem('ListView');
    localStorage.removeItem('SearchFilters');
  }
  onMarkLost(data) {
    if (data.source == "MINSIDE") {
      this.showMinsidePetList = false;
      this.showPersonalInfo = false;
      this.minsidePetInfo = data.petDetail;
      this.minsideOwnerInfo = data.ownerDetail;
    }

  }
  onPosted(data) {
    if (data) {

      if (data.SearchFilters) {
        // console.log(data.SearchFilters);
        this.petsService.GetLostFoundPetsListPartialFields(data.SearchFilters).subscribe(res => {
          this.petsSuggestion = res;
          if (this.petsSuggestion.RecordsFiltered == 0) {
            this.petsSuggestion = ""
          }
        });
      }
      if (data.IsChipped) {
        this.petsinfo = data;
        this.showPersonalInfo = true;
        window.scroll(0, 0);
      }
      if (data.FirstName) {
        this.personalsinfo = data;
        // console.log(this.petsinfo);
        this.petsinfo.MasterSpeciesID = parseInt(this.petsinfo.MasterSpeciesID);
        this.petsinfo.MasterBreedID = parseInt(this.petsinfo.MasterBreedID);
        let Image = this.petsinfo.ImagePath;
        console.log('IMAGE : ', Image)
        let croppedFileData = this.petsinfo.croppedFileData;
        this.petsinfo.ImagePath = "";
        this.petsinfo.croppedFileData = "";
        this.petsinfo.Image = "";
        // this.petsinfo.DateOfBirth = "";
        // this.petsinfo.DateOfLost = "";
        this.petsinfo.IsChipped = (this.petsinfo.IsChipped == "1") ? 1 : 0;
        this.petsinfo.ShowChipNoToOthers=(this.petsinfo.IsChipIDinDetail) ? 1 : 0;
        this.petsinfo.OperationTypeID = 1;
        this.petsinfo.UserLoginID = this.currentUser.ID;
        this.personalsinfo.LoginUserID = this.currentUser.ID;
        this.personalsinfo.GDPRUserStatus = (this.personalsinfo.GDPRUserStatus) ? 1 : 0;
        this.personalsinfo.IsTermsAccepted = (this.personalsinfo.IsTermsAccepted) ? 1 : 0;
        delete this.petsinfo['IsChipIDinDetail'];
        this.pets.petDetails = this.petsinfo;
        this.pets.userInfo = this.personalsinfo;
        console.log(typeof Image);
        if(typeof Image == 'string'){
          this.pets.petDetails.petImgUrl = Image;
        }
        let petDetail = JSON.stringify(this.pets);
        const formData = new FormData();
        formData.append('petDetails', petDetail);
        if(typeof Image != 'string'){
          formData.append('Image', Image);
        }
        formData.append('croppedFileData', croppedFileData);
        this.Savesresponse(formData);
      }
    }
  }

  onHide(data) {
    this.showPersonalInfo = false
  }

  Savesresponse(petDetails) {
    this.SpinnerService.show();
    this.petsService.addUpdateLostFoundPetDetails(petDetails).subscribe((res: any) => {
      this.SpinnerService.hide();
      if (res.error == "") {
        alert("Innlegget ditt er sendt. Klikk OK for å se kjæledyr som finnes i området der du mistet kjæledyret.");
        // alert("Your post has been successfully submitted. Click OK to see pets that are found in the area where you lost the pet.");
        this.router.navigate(['/list-pets'], {
          queryParams: {
            OperationTypeID: 2,
            radiousDistance: 10,
            lat: this.petsinfo.lat,
            lng: this.petsinfo.lng,
            SpeciesID: this.petsinfo.MasterSpeciesID,
            BreedID: this.petsinfo.MasterBreedID
          }
        });
      }
      else {
        alert(res.error);
      }
    })
  }

  toggleFoundSuggestion() {
    this.showFoundSuggestion = !this.showFoundSuggestion;
  }
  CloseSuggestion(eve: any) {
    eve.target.parentNode.parentNode.remove();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '250px',
      data: { name: "string name", animal: "this.animal" }
    });
  }

  newLostPet(): void {
    this.showMinsidePetList = false;
  }

  //go to pet details page
	goToPetDetails = (petDetail:any) => {
		const petName = `${petDetail.Color ? petDetail.Color: '' } ${petDetail.MasterBreed ? petDetail.MasterBreed : ''} ${petDetail.MasterSpecies ? petDetail.MasterSpecies : ''} ${(petDetail.OperationTypeID == "1") ? 'Tapt' : 'Funnet'} I ${petDetail.City ? petDetail.City : ''}`
		const urlString = petName.trim().toLowerCase().split(' ').join('-');
		const removeChar = urlString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const encodedString = encodeURI(removeChar);
		const atobStr = btoa(petDetail.LostFoundID);
		localStorage.setItem('_page','report-lost');
    window.open(`/list-pets/pet-detail/${encodeURIComponent(atobStr)}/${encodedString}?opType=1`, '_blank');

	}
}