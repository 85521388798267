import { Component, ViewChild, OnInit, NgZone, ElementRef } from '@angular/core';
import { AlertService, AuthenticationService } from '../_services';
import { PetsService } from '../_services';
import { environment } from '../../environments/environment';
import { interval, Subscription } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { Options } from 'ng5-slider';
import { Router } from '@angular/router';
import * as $ from 'jquery'

@Component({
  selector: 'app-home',
  //directives: [InfiniteScrollModule],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  //   styles: [
  //     `.search-results {
  //         height: 20rem;
  //         overflow: scroll;
  //     }`
  // ],
  template: `
    <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollUpDistance]="1.5"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScrollDown()"
      (scrolledUp)="onScrollUp()"
    ></div>
  `,

})
export class HomeComponent implements OnInit {
  totalPetCount: any;
  totalPetCount_Lost: any;
  totalPetCount_Found: any;
  totalCount: any;
  totalDogs: any;
  totalCats: any;
  MtotalCount: any;
  MtotalDogs: any;
  MtotalCats: any;
  LostpetDetailsList: any;
  FoundpetDetailsList: any;
  LostFoundpetDetailsList: any;
  alldatalist: any;
  OperationTypeID: any = 3;
  OperationTypeHeading: string;
  petImagePath = environment.apiBaseUrl + "/Static/Images/";
  avgRegesteredPetsPerDay: number;
  public valt: number;
  public finalt: number;
  public countvalue: boolean = false;
  submitted = false;
  page = 1;
  pageSize = 12;
  zoom: any = 13;
  searchForm: FormGroup;
  lat: any;//= 59.9138688;
  lng: any;//= 7.809007;
  radiousMils: any;
  minClusterSize = 50;
  options: Options = {
    floor: 0,
    ceil: 100
  };
  currentActiveTabFilter = null;
  sum = 6;
  array = [];
  direction = "";
  throttle = 1;
  scrollDistance = 1;
  private geoCoder;
  @ViewChild('search')
  private searchElementRef: ElementRef;

  scrollUpDistance = 2;

  subscription: Subscription;
  statusText: string;


  constructor(
    private pets: PetsService,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private formbuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router: Router,
  ) { }

  ngOnInit() {
    this.searchForm = this.formbuilder.group({
      SpeciesID: [],
      BreedID: [],
      Area: [''],
      lat: ['59.9138688'],
      lng: ['10.7522'],
      // lat: ['30.73333'],
      // lng: ['76.7794'],
      radiousDistance: ['10'],
      OperationTypeID: ['1']
    });


    // this.totalCount = "600 000";
    // this.totalCats = "200 000";
    // this.totalDogs = "400 000";
    // this.MtotalCount = "600 000";
    // this.MtotalCats = "200 000";
    // this.MtotalDogs = "400 000";



    //this.getFoundPets();

    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          // debugger;
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // this.searchForm.controls['Area'].patchValue(place.formatted_address)
          // this.searchForm.controls['lat'].patchValue(place.geometry.location.lat());
          // this.searchForm.controls['lng'].patchValue(place.geometry.location.lng());
          // debugger;
          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          //this.zoom = 13;
          //localStorage.setItem('_searchFilters', JSON.stringify({ lat: this.lat, lng: this.lng, area: place.formatted_address, radiousDistance: this.searchForm.value.radiousDistance, OperationTypeID: this.OperationTypeID }))
          this.onSubmit();

        });
      });
    });



    this.mapsAPILoader.load().then(() => {
      // debugger;
      // this.lat = 59.9138688;
      // this.lng = 10.7522;
      this.setCurrentLocation();
    });
    
    this.authenticationService.GetDailyReportedPets().subscribe((res: any) => {
      if (res.Status == 1) {
        this.avgRegesteredPetsPerDay = res.Data.PetRegisteredPerDay;
        this.totalCount = new Number(res.Data.PetCount).toLocaleString("fi-FI");

        var avgTimeToChangeTotalCount = (8 * 60 * 60) / (this.avgRegesteredPetsPerDay);

        const source = interval(avgTimeToChangeTotalCount * 1000);
        source.subscribe(val => this.updateTotalCount());
      }
    });

    this.authenticationService.GetListCount().pipe().subscribe(response => {

      this.totalCount = new Number(response.Data.TotalPets).toLocaleString("fi-FI");
      this.totalDogs = new Number(response.Data.TotalDogs).toLocaleString("fi-FI");
      this.totalCats = new Number(response.Data.TotalCats).toLocaleString("fi-FI");
      this.MtotalCount = this.transform(response.Data.TotalPets,);
      this.MtotalDogs = this.transform(response.Data.TotalDogs,);
      this.MtotalCats = this.transform(response.Data.TotalCats,);
    });

    localStorage.removeItem('ListPageNo');
    localStorage.removeItem('ListView');
    localStorage.removeItem('SearchFilters');
    localStorage.removeItem('_calledFrom');

    window.addEventListener('scroll', this.handleScroll, true);

  }

  transform(number: number, args?: any): any {
    if (isNaN(number)) return null; // will only work value is a number
    if (number === null) return null;
    if (number === 0) return null;
    let abs = Math.abs(number);
    const rounder = Math.pow(10, 1);
    const isNegative = number < 0; // will also work for Negetive numbers
    let key = '';

    const powers = [
      { key: 'Q', value: Math.pow(10, 15) },
      { key: 'T', value: Math.pow(10, 12) },
      { key: 'B', value: Math.pow(10, 9) },
      { key: 'M', value: Math.pow(10, 6) },
      { key: 'K', value: 1000 }
    ];

    for (let i = 0; i < powers.length; i++) {
      let reduced = abs / powers[i].value;
      reduced = Math.round(reduced * rounder) / rounder;
      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }
    //return (isNegative ? '-' : '') + abs + key;
    return ((isNegative ? '-' : '') + abs + key).replace(".", ",");
  }

  handleScroll = (event) => {
    var distance = $('#main-div').offset().top;
    const navbar = document.getElementById("menu-div");
    if (!navbar) return;
    if (distance < 0) {
      navbar.classList.add("sticky")
    } else {
      navbar.classList.remove("sticky");
    }
  }

  private setCurrentLocation() {
    this.mapsAPILoader.load().then(() => {
      if ("geolocation" in navigator) {
        //debugger;
        navigator.geolocation.getCurrentPosition((position) => {
          this.lat = position.coords.latitude;
          this.lng = position.coords.longitude;
          this.getLostPets(1, false);
          //this.getAddress(this.lat, this.lng);
        },
          () => {
            if (this.lat == 0 || this.lat == undefined || this.lat == null) {
              //debugger;
              this.lat = 59.9138688;
              this.lng = 10.7522;
              this.getLostPets(1, false);
            }
            // debugger;
            // this.lat = 0;
            // this.getAddress(0, 0);
          });
      }

    });

    this.getLostPets(1, false);
    // const searchFilter = JSON.parse(localStorage.getItem('_searchFilters'));
    // if (searchFilter) {
    //   this.lat = searchFilter.lat;
    //   this.lng = searchFilter.lng;
    //   if (searchFilter.radiousDistance) this.searchForm.controls['radiousDistance'].patchValue(searchFilter.radiousDistance);
    //   if (searchFilter.OperationTypeID) {
    //     this.OperationTypeID = searchFilter.OperationTypeID;
    //     this.searchForm.controls['OperationTypeID'].patchValue(searchFilter.OperationTypeID);
    //   }

    //this.getAddress(this.lat, this.lng);

    // } else {
    //   if ("geolocation" in navigator) {
    //     navigator.geolocation.getCurrentPosition((position) => {
    //       this.lat = position.coords.latitude;
    //       this.lng = position.coords.longitude;
    //       this.getAddress(this.lat, this.lng);
    //     },
    //       () => {
    //         // debugger;
    //         this.lat = 0;
    //         this.getAddress(0, 0);
    //       });
    //   }
    // }

  }

  getAddress(latitude, longitude) {
    // debugger;
    if (latitude == 0) {
      this.getLostPets(this.page);
    }
    else {
      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
        // console.log(results);
        // console.log("status ??");
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 13;
            // console.log(results[0].formatted_address);
            this.searchForm.controls['Area'].patchValue(results[0].formatted_address);

            this.getLostPets(this.page);
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }


      });
    }
  }


  getLostPets(page: number, isPagination?: boolean) {
    this.page = page;
    let startIndex = (this.page == 1) ? 0 : (page - 1) * 50;
    let endIndex = startIndex + 50;

    let filters = {
      "startIndex": startIndex,
      "endIndex": endIndex,
      "lat": this.lat,
      "lng": this.lng,
      "radiousDistance": this.searchForm.value.radiousDistance,
      "operationTypeFliter": 0,//this.currentActiveTabFilter == null ? 3 : this.currentActiveTabFilter,
      "days": 15
    }
    if (this.lat == 0) {

      let startIndex = (this.page == 1) ? 0 : (page - 1) * 25;
      let endIndex = startIndex + 25;

      let nullFilter = {
        "startIndex": startIndex,
        "endIndex": endIndex
      }

      this.pets.GetLandingPageLostPets_(nullFilter).subscribe(Response => {
        this.alldatalist = Response;
        this.totalPetCount_Lost = this.alldatalist.RecordsTotal;
        this.LostpetDetailsList = this.alldatalist.data;//.slice(0,Math.ceil(this.alldatalist.RecordsFiltered/2));//.slice(0,3);
        // this.FoundpetDetailsList = this.alldatalist.data.slice(3,6);
      });

      // this.pets.GetLandingPageFoundPets(nullFilter).subscribe(Response => {
      //   this.alldatalist = Response;
      //   this.totalPetCount_Found = this.alldatalist.RecordsTotal; 
      //   this.FoundpetDetailsList = this.alldatalist.data.slice(0,Math.ceil(this.alldatalist.RecordsFiltered/2));//.slice(3,6);
      //   this.totalPetCount = this.totalPetCount_Lost + this.totalPetCount_Found;
      // });
    }
    else {

      //this.pets.GetLandingPageLostPets(filters).subscribe(Response => {
        if(!this.lat) return;
      this.pets.GetPetsForLandingPage(filters).subscribe(Response => {
        this.alldatalist = Response;
        // this.OperationTypeID = 3;
        this.totalPetCount = this.alldatalist.RecordsTotal;
        this.LostpetDetailsList = this.alldatalist.data;//.slice(0,Math.ceil(this.alldatalist.RecordsFiltered/2));
        //this.FoundpetDetailsList = this.alldatalist.data.slice(Math.ceil(this.alldatalist.RecordsFiltered/2),Math.ceil(this.alldatalist.RecordsFiltered));
      });
    }
    if (isPagination) this.scrollTop();
  }

  scrollTop = () => {
    const element = document.querySelector('#main-div');
    element.scrollIntoView({ behavior: 'smooth' });
  }

  public updateTotalCount() {
    this.valt = parseInt(this.totalCount.replace(/\s/g, ''));

    var numvalue = (this.valt) + 1;
    var str = new Number(numvalue).toLocaleString("fi-FI");
    this.totalCount = str;
  }
  public ChangeFilterData(event: any) {
    this.lat = event.coords.lat;
    this.lng = event.coords.lng;
    this.GetLandingPagePets(0, 1);
  }
  public GetLandingPagePets(FilterData: any, page: number) {
    //  debugger;
    this.page = page;
    let startIndex = (this.page == 1) ? 0 : (page - 1) * 50;
    let endIndex = startIndex + 50;

    if (FilterData)
      this.OperationTypeID = FilterData;
    //localStorage.setItem('_searchFilters', JSON.stringify({ lat: this.lat, lng: this.lng, area: this.searchForm.value.Area, radiousDistance: this.searchForm.value.radiousDistance, OperationTypeID: this.OperationTypeID }))
    let LandingPagefilters = {
      "startIndex": startIndex,
      "endIndex": endIndex,
      "lat": this.lat,
      "lng": this.lng,
      "radiousDistance": this.searchForm.value.radiousDistance,
      "operationTypeFliter": 0,//FilterData == null ? 3 : FilterData,
      "days": 15
    }


    // if (FilterData == 1) {
    this.currentActiveTabFilter = 1;
    this.pets.GetPetsForLandingPage(LandingPagefilters).subscribe(Response => {

      this.alldatalist = Response;

      this.totalPetCount = this.alldatalist.RecordsTotal;
      //this.LostpetDetailsList= this.alldatalist.data;
      this.LostpetDetailsList = this.alldatalist.data;//.slice(0,Math.ceil(this.alldatalist.RecordsFiltered/2)); 
      //this.FoundpetDetailsList = this.alldatalist.data.slice(Math.ceil(this.alldatalist.RecordsFiltered/2),this.alldatalist.RecordsFiltered);

    });

  }

  onSubmit() {
    localStorage.setItem('_searchFilters', JSON.stringify({ lat: this.lat, lng: this.lng, area: this.searchForm.value.Area, radiousDistance: this.searchForm.value.radiousDistance, OperationTypeID: this.OperationTypeID }))
    this.submitted = true;
    this.page = 1;
    let ListPageNo = localStorage.getItem('ListPageNo');
    if (ListPageNo) {
      this.page = Number(ListPageNo);
    }
    if (this.currentActiveTabFilter == null) {
      this.currentActiveTabFilter = 3;
    }
    if (this.lat != 0 && this.lat != null && this.lat != undefined)
      this.GetLandingPagePets(this.currentActiveTabFilter, this.page);

  }

  //go to pet details page
  goToPetDetails = (petDetail: any) => {
    const petName = `${petDetail.Color ? petDetail.Color : ''} ${petDetail.BreedsName ? petDetail.BreedsName : ''} ${petDetail.SpeciesName ? petDetail.SpeciesName : ''} ${(petDetail.OperationTypeID == "1") ? 'Tapt' : 'Funnet'} I ${petDetail.City ? petDetail.City : ''}`
    const urlString = petName.trim().toLowerCase().split(' ').join('-');
    const removeChar = urlString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const encodedString = encodeURI(removeChar);
    const atobStr = btoa(petDetail.LostFoundID);
    localStorage.setItem('_page', 'home_page');
    const param = this.OperationTypeID == '3' ? { opType: '' } : { opType: this.OperationTypeID };
    this.router.navigate([`list-pets/pet-detail/${atobStr}/${encodedString}`], { queryParams: param });

  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.handleScroll, true);
  }

}