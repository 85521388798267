<nav class="navbar top_navbr">
    <div class="container-fluid dipltop-nav">
      
        <a class="navbar-brand" routerLink=""><img src="assets/img/logo.png" alt="" title=""></a>
        <div class="menutop_burgr">
        <div class="hdr-dropdown" *ngIf="!this.app.currentUser">
            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline-primary" id="dropdownBasic1" [routerLink]="['login']" ngbDropdownToggle>
                    <span class="usernam">{{ "Login" | translate }} </span><img src="assets/img/user_icn.svg" alt=""
                        title="" class="hide"> </button>
            </div>
        </div>
        
        <div class="hdr-dropdown" *ngIf="this.app.currentUser">
            <div ngbDropdown class="d-inline-block">
                <!-- <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{ this.app.currentUser.FirstName + " " +  this.app.currentUser.LastName +" ("+ this.app.currentUser.Provider.toLowerCase() +")"}} <img src="assets/img/user_icn.svg" alt="" title=""></button> -->
                <button class="btn btn-outline-primary b-1" id="dropdownBasic1" ngbDropdownToggle>
                    <p class="m-1">
                        {{ this.app.currentUser.FirstName + " " + this.app.currentUser.LastName }}
                    </p>
                    <img src="assets/img/user_icn.svg" alt="" title="">
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem><img src="assets/img/profile.svg" alt="" title=""> <a
                            href="/userprofile">Profile</a></button>
                    <button ngbDropdownItem (click)="this.app.signOut()"><img src="assets/img/logout.svg" alt=""
                            title=""> {{ "Logout" | translate }} </button>
                </div>
            </div>
        </div>
        <button (click)="_toggleSidebar()" class="demo-header__toggle">
            <svg xmlns="http://www.w3.org/2000/svg" width="63" height="49.282" viewBox="0 0 63 49.282">
                <g id="menu_bar" transform="translate(-6.935 -24.339)">
                    <path id="Union_5" data-name="Union 5"
                        d="M6.6,51.04a3.734,3.734,0,0,1,0-7.467H62.132a3.734,3.734,0,0,1,0,7.467Zm0-20.908a3.734,3.734,0,0,1,0-7.467H62.132a3.734,3.734,0,0,1,0,7.467Zm0-20.908a3.733,3.733,0,1,1,0-7.466H62.132a3.733,3.733,0,1,1,0,7.466Z"
                        transform="translate(4.069 22.581)" fill="#fff" />
                </g>
            </svg>
        </button>

        </div>

    </div>
</nav>
<!-- Page content -->


