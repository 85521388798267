<div class="profile-form list-view">
    <div class="container">
		<div class="row">
            <div class="col-md-12 list-right-cntnt pl-5 static-design">
			<div id="aboutus" *ngIf="aboutus">
				<div *ngFor="let m of pagedata; let i = index">
					<div>
						<strong> PageContent: {{ m.PageContent }}</strong>
					<!-- <br>
						<strong> Image: {{ m.Image }}</strong>
					<br>
						<strong> PageContent: {{ m.PageContent }}</strong>
					<br>
						<strong> DisplayOrder: {{ m.DisplayOrder }}</strong>
					<br>
						<strong> Status: {{ m.Status }}</strong> -->
					</div>
				</div>
			</div>
			
			<div id="services"  *ngIf="services">
				<div *ngFor="let m of pagedata; let i = index">
					<div>
						<strong> Title: {{ m.Title }}</strong>
					<!-- <br>
						<strong> Description: {{ m.Description }}</strong>
					<br>
						<strong> DisplayOrder: {{ m.DisplayOrder }}</strong>
					<br>
						<strong> Status: {{ m.Status }}</strong> -->
					</div>
				</div>
				
			</div>
			
			<div id="termsandconditions"  *ngIf="termsandconditions">
				<div *ngFor="let m of pagedata; let i = index">
					<div>
						<strong> TermAndCondition: {{ m.TermAndCondition }}</strong>
					<br>
						<strong> Status: {{ m.Status }}</strong>
					</div>
				</div>
			</div>
			
			<div id="privacypolicy"  *ngIf="privacypolicy">
				<div *ngFor="let m of pagedata; let i = index">
					<div>
						<strong><h3> {{ m.Title }}</h3></strong>
						<p> {{ m.Description }}</p>
					</div>
				</div>
			</div>
			</div>
		</div>
	</div>
</div>