<div class="home-banner">
  <div class="container">
    <h5>{{ 'Report Pets' | translate }}</h5>
    <h2>{{ 'Lost & Found' | translate }}</h2>
    <p class="">{{ 'Cotainer string' | translate }} </p>

  </div>
  <div class="btn-box">
    <div class="browcallbtn">
      <a routerLink="/report-lost" class="primary-btn report-prmy">{{ 'Report Lost' | translate }}</a>
    </div>
    <div class="browcallbtn">
      <a routerLink="/report-found" class="green-btn">{{ 'Report Found' | translate }}</a>
    </div>


    <!-- <a class="primary-btn" href="">Report Lost</a>
    <a class="green-btn" href="">Report Found</a> -->
  </div>
</div>


<div class="count-top-box">

  <div class="count-bg">
    <div class="container">
      <div class="cusmter-numsec">
        <div class="cust-number">
          <div class="count-top">
            <div class="count-cntnt">
              <img src="assets/img/home/marked_pets.svg" alt="" title="" class="dogfot-mbl">
              <div class="count-inner">
                <span class="count-span countspn-desktop">{{ totalCount }}</span>
                <span class="count-span countspn-mbl">{{ MtotalCount }}</span>
                <span class="count-title">{{'ID-Marked Pets' | translate }}</span>
              </div>
            </div> 
          </div>
        </div>
        <div class="cust-number">
          <div class="count-top">
            <div class="count-cntnt">
              <img src="assets/img/home/Ikon_hund.png" alt="" title="" class="dog-mbl">
              <div class="count-inner">
                <span class="count-span countspn-desktop">{{ totalDogs }}</span>
                <span class="count-span countspn-mbl">{{ MtotalDogs }}</span>
                <span class="count-title">{{'ID-Marked Dogs' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="cust-number">
          <div class="count-top no-bordr">
            <div class="count-cntnt">
              <img src="assets/img/home/Ikon_katt.png" alt="" title="" class="cat-mbl">
              <div class="count-inner">
                <span class="count-span countspn-desktop">{{ totalCats }}</span>
                <span class="count-span countspn-mbl">{{ MtotalCats }}</span>
                <span class="count-title">{{'ID-Marked Cats' | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>





<div class="home-browse-pet">
  <div class="container">
    <div class="row">
      <div class="col-sm-6">
        <div class="lostaniml-lostsec">
          <div class="lost-animls-bg">
            <img src="assets/img/home/lost-animl-bg.png" class="img-fluid">
            <div class="trasnparent-bg"></div>
          </div>
          <div class="lost-animl-descript">
            <h1> <span>Har du funnet et savnet dyr?</span></h1>
            <div class="btn-box-lostnaimal">

              <a [routerLink]="['list-pets']"
                [queryParams]="{ OperationTypeID:1,radiousDistance:10,callingFrom:'home',speciesId:1}"
                class="primary-btn">{{'See dogs' | translate}}</a>
              <a [routerLink]="['list-pets']"
                [queryParams]="{ OperationTypeID:1,radiousDistance:10,callingFrom:'home',speciesId:2}"
                class="green-btn"> {{'See cats' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="lostaniml-lostsec">
          <div class="lost-animls-bg">
            <img src="assets/img/home/map_image.png" class="img-fluid">
            <div class="trasnparent-bg"></div>
          </div>
          <div class="lost-animl-descript">
            <h1> <span>Har du funnet et savnet dyr?</span></h1>
            <div class="btn-box-lostnaimal">

              <a [routerLink]="['list-pets']"
                [queryParams]="{ OperationTypeID:1,radiousDistance:10,callingFrom:'menu'}"
                class="primary-btn">{{'Browse Lost Pets' | translate}}</a>
              <a [routerLink]="['list-pets']"
                [queryParams]="{ OperationTypeID:2,radiousDistance:10,callingFrom:'menu'}"
                class="green-btn"> {{'Browse Found Pets' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
      <!--div class="col-sm-6">
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" class="map-sec-view">
          <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true" (dragEnd)="ChangeFilterData($event)">
          </agm-marker>
        </agm-map>-->
        <!-- <div class="map-browse-home">
      <div class="map-browse-home-cntnt"> -->
        <!-- <h4>{{ 'Browse Pets In your Area' | translate }}</h4> -->
        <!-- <div class="location-map"><img src="assets/img/home/location.png"></div> -->


        <!-- <h4>See pets in yours local area</h4> -->



        <!-- <div class="btn-box">

          <a [routerLink]="['list-pets']" [queryParams]="{ OperationTypeID:1,radiousDistance:10}" class="primary-btn">{{ 'Browse Lost' | translate }}</a>
          <a [routerLink]="['list-pets']" [queryParams]="{ OperationTypeID:2,radiousDistance:10}" class="green-btn">{{ 'Browse Found' | translate }}</a> -->

        <!-- <a class="primary-btn" href="">Browse Lost</a>
          <a class="green-btn" href="">Browse Found</a> -->
        <!-- </div> -->
        <!-- </div>
    </div> -->
      
    </div>


  </div>
</div>




<div class="filter-listsec hide">
  <div class="container">


    <div class="row">

      <div class="list-filter-sidebar-box col-md-12 top-filter-search">
        <div class="row">

          <div class="width-80 mx-auto homeslfltr">
            <div class="row">
              <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="search-inner-form">
                <div class="list-filter-sidebar">
                  <div class="list-filter-sidebar-cntnt">
                    <div class="col-md-6 search-area">
                      <img src="assets/img/ic_location.svg" alt="">
                      <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                        formControlName="Area" placeholder="Search Nearest Location" autocorrect="off"
                        autocapitalize="off" spellcheck="off" type="text" #search>
                    </div>

                    <div class="col-md-6">
                      <!--<p>{{ 'Distance' | translate }}</p>-->
                      <div class="distance-range-box">
                        <ng5-slider radiousDistance [options]="options" formControlName="radiousDistance"
                          (userChangeEnd)="onSubmit()"></ng5-slider>
                      </div>
                    </div>

                  </div>



                </div>
              </form>

            </div>
          </div>
        </div>

      </div>
    </div>


  </div>

</div>



<div class="homepet-list-section">
  <div class="hometop-head hide">
    <div class="container">
      <h4>{{'Latest Lost & Found in Norway' | translate}}</h4>
      <p>{{'Latest Lost & Found in Norway string' | translate}}</p>
    </div>
  </div>
  <div class="container">
    <div class="col-sm-12">
      <div class="btn-box">
        <div class="browcallbtn width-100 mb-2">
          <a routerLink="/report-lost" class="custbtn btn-orange report-prmy">{{ 'Report Lost' | translate }}</a>
        </div>
        <div class="browcallbtn width-100 mb-2">
          <a routerLink="/report-found" class="custbtn btn-darkgreen">{{ 'Report Found' | translate }}</a>
        </div>


        <!-- <a class="primary-btn" href="">Report Lost</a>
        <a class="green-btn" href="">Report Found</a> -->
      </div>
    </div>

  </div>
</div>


<div class="homepet-list-section">
  <div class="hometop-head hide">
    <div class="container">
      <h4>{{'Latest Lost & Found in Norway' | translate}}</h4>
      <p>{{'Latest Lost & Found in Norway string' | translate}}</p>
    </div>
  </div>
  <div class="container">
    <div class="col-sm-12">
      <div class="btn-box">
        <div class="browcallbtn width-100 mb-2">
          <a routerLink="/report-lost" class="custbtn btn-orange report-prmy">{{ 'Report Lost' | translate }}</a>
        </div>
        <div class="browcallbtn width-100 mb-2">
          <a routerLink="/report-found" class="custbtn btn-darkgreen">{{ 'Report Found' | translate }}</a>
        </div>


        <!-- <a class="primary-btn" href="">Report Lost</a>
        <a class="green-btn" href="">Report Found</a> -->
      </div>
    </div>

  </div>
</div>

<div class="home-pet-list-box">
  <div class="hometop-head hide">
    <div class="container">
      <h4>{{'Latest Lost & Found in Norway' | translate}}</h4>
      <p>{{'Latest Lost & Found in Norway string' | translate}}</p>
    </div>
  </div>
  <div class="container">
    <!-- <div class="search-results"
      infinite-scroll 
      [infiniteScrollThrottle]="throttle"
      [scrollWindow]="true"
      (scrolled)="onScrollDown()"
      (scrolledUp)="onUp()"> -->

    <div class="home-pet-list-cntnt" id="main-div">
      <!-- <div class="row sticky-menu" id="menu-div">
        <div class="col-sm">
          <div class="count-top count-top-tab">
            <div class="count-cntnt">
              <div class="count-inner"> -->
      <!-- <h5 class="count-title">{{'Tabs' | translate }} : {{'Tapt' | translate }} &nbsp; &nbsp; {{'Funnet' | translate }} &nbsp; &nbsp; {{'Alle' | translate }} </h5> 
                 -->
      <!-- <h5 class="count-title">{{'Tabs' | translate }}</h5>  -->
      <!-- <ul class="nav nav-pills" role="tablist">
                  <li class="nav-item">
                    <div class="stats-inner nav-link"  data-toggle="pill" [ngClass]="{'active' : this.OperationTypeID == 3}"  (click)="GetLandingPagePets(3,1)"> <span>{{'All' | translate}}</span></div>
                  </li>
                  <li class="nav-item">
                   <div class="stats-inner nav-link" data-toggle="pill" [ngClass]="{'active' : this.OperationTypeID == 1}" (click)="GetLandingPagePets(1,1)"> <span>{{'Lost'| translate }}</span></div>
                  </li>
                  <li class="nav-item">
                    <div class="nav-link"  data-toggle="pill" [ngClass]="{'active' : this.OperationTypeID == 2}"  (click)="GetLandingPagePets(2,1)"> <span>{{'Found' | translate}}</span></div>
                  </li>
                  
                              
                 
                  </ul>
    
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="row">
        <div class="col-sm-12">
        <div class="home-petlistsec">
          <h2>Siste savnet og funnet i Norge</h2>
          <p>Mitt Spor Lorem ipsum dolor sit amet, consectetur adipis cing elit. Sed et interdum ex. Mauris estue amei
            enim, convallis </p>

        </div>
      </div>
      </div>

      <div class="row">

          <div class="col-sm-12 pets-list">
        <!-- <div class="col-6" > -->
          

        <div class="pet-list-tile homeproduct "
          *ngFor="let petDetail of LostpetDetailsList | paginate: { id: 'server', itemsPerPage: 50, currentPage: page, totalItems: totalPetCount }">
          <!-- <div [routerLink]="['pet-detail']" [queryParams]="{ PetID:petDetail.LostFoundID}">  -->
          <div class="open-detail-click" (click)="goToPetDetails(petDetail)">
            <div class="pet-img-box">
              <img class="pet-img" *ngIf="petDetail.ThumbImagePath" src="{{ petImagePath + petDetail.ThumbImagePath }}"
                alt="" title="">
              <img class="pet-img" *ngIf="! petDetail.ThumbImagePath" src="{{ petImagePath + petDetail.ImagePath }}"
                alt="" title="">
            </div>
            <!-- ------------------------------- -->
            <div class="ProdyctDescrtp">
              <div class="homeproduct_decription">
                <h5 *ngIf="! petDetail.MasterBreed"> {{ ((petDetail.OperationTypeID == "1") ? 'Lost' : 'Found') |
                  translate}} <b *ngIf="! petDetail.MasterBreed"> <strong> <span
                        *ngIf="petDetail.Color">{{petDetail.Color +" "}}</span>{{ petDetail.BreedsName +" "+
                      petDetail.SpeciesName}}<span *ngIf="petDetail.City"> i {{petDetail.City}}</span></strong></b>
                </h5>
                <h5 *ngIf="petDetail.MasterBreed"> {{ ((petDetail.OperationTypeID == "1") ? 'Lost' : 'Found') |
                  translate}}<b *ngIf="petDetail.MasterBreed"><strong> <span *ngIf="petDetail.Color">{{petDetail.Color
                        +" "}}</span>{{ petDetail.BreedsName }} ({{petDetail.SpeciesName}}) <span
                        *ngIf="petDetail.City"> i {{petDetail.City}}</span></strong></b> </h5>
                <!-- ------------------------------- -->
                <p class="desktop-addressdtls"><img src="assets/img/home/locatn_home.png" alt="" title="">{{
                  ((petDetail.Area == null) ? "" : petDetail.Area) +" "+ ((petDetail.ZipCode == null) ? "" :
                  petDetail.ZipCode) +" "+((petDetail.City == null) ? "" : petDetail.City)}}</p>
                <p class="mobile-addressdtls"><img src="assets/img/home/locatn_home.png" alt=""
                    title="">{{((petDetail.City == null) ? "" : petDetail.City)}}</p>
                <p><img src="assets/img/home/time_home.png" alt="" title=""> {{ petDetail.DisplayLostFoundDate}}</p>
                <!-- <div class="btn-box">
              <a class="btn btn-secondary" (click)="goToPetDetails(petDetail)" ngbTooltip="View"><img src="assets/img/post_view.svg" title=""></a>
              <button type="submit" class="btn btn-primary mr-3" ngbTooltip="Share" ><img src="assets/img/post_share.svg" title=""></button>
              </div> -->
              </div>
            </div>
          </div>
        </div>

        <!-- </div>

      <div class="col-6" >
          
        <div class="pet-list-tile homeproduct" *ngFor="let petDetail of FoundpetDetailsList | paginate: { id: 'server', itemsPerPage: 50, currentPage: page, totalItems: totalPetCount }">
         <div [routerLink]="['pet-detail']" [queryParams]="{ PetID:petDetail.LostFoundID}">
<div class="pet-img-box">
          <img class="pet-img" *ngIf="petDetail.ThumbnailImagePath" src="{{ petImagePath + petDetail.ThumbnailImagePath }}" alt="" title="">
          <img class="pet-img" *ngIf="! petDetail.ThumbnailImagePath" src="{{ petImagePath + petDetail.ImagePath }}" alt="" title="">
</div>

<div class="ProdyctDescrtp">
          <h5 *ngIf="! petDetail.MasterBreed"> {{ ((petDetail.OperationTypeID == "1") ? 'Lost' : 'Found') | translate}} <b *ngIf="! petDetail.MasterBreed"> <strong> <span *ngIf="petDetail.Color">{{petDetail.Color +" "}}</span>{{ petDetail.BreedsName +" "+ petDetail.SpeciesName}}<span *ngIf="petDetail.City">I {{petDetail.City}}</span></strong></b> </h5>
          <h5 *ngIf="petDetail.MasterBreed"> {{ ((petDetail.OperationTypeID == "1") ? 'Lost' : 'Found')| translate}}<b *ngIf="petDetail.MasterBreed"><strong> <span *ngIf="petDetail.Color">{{petDetail.Color +" "}}</span>{{ petDetail.BreedsName }} ({{petDetail.SpeciesName}})  <span *ngIf="petDetail.City">I {{petDetail.City}}</span></strong></b> </h5>

          <p><img src="assets/img/home/locatn_home.png" alt="" title=""> {{petDetail.Area}}</p>
          <p><img src="assets/img/home/time_home.png" alt="" title=""> {{ petDetail.DisplayLostFoundDate}}</p>
          <div class="btn-box">
            <a class="btn btn-secondary" [routerLink]="['pet-detail']" ngbTooltip="View"  [queryParams]="{ PetID:petDetail.LostFoundID}"><img src="assets/img/post_view.svg" title=""></a>
            <button type="submit" class="btn btn-primary mr-3" ngbTooltip="Share" ><img src="assets/img/post_share.svg" title=""></button>
            </div>
        </div>
      </div>
      </div>
      
    </div> -->

    </div>

      </div>
      <!-- <pagination-controls (pageChange)="getLostPets($event, true)" id="server"></pagination-controls>  -->
      <!-- </div> -->
    </div>
    <div class="row">
    <div class="col-sm-12">
    <div class="browse-all-btn btn-box">

      <div class="see-allbtn">
        <a [routerLink]="['list-pets']" [queryParams]="{ OperationTypeID:1,radiousDistance:10}"
          class="primary-btn">{{'See all lost' | translate}}</a>&nbsp;
        <a [routerLink]="['list-pets']" [queryParams]="{ OperationTypeID:2,radiousDistance:10}"
          class="primary-btn">{{'See all found' | translate}}</a>
        <!-- <a [routerLink]="['list-pets']" [queryParams]="{ OperationTypeID:2,radiousDistance:10}" class="primary-btn">See all lost and found</a> -->


      </div>
      <!-- <div class="browcallbtn">
      <a [routerLink]="['list-pets']" [queryParams]="{ OperationTypeID:1,radiousDistance:10}" class="primary-btn">{{ 'Browse All Lost' | translate }}</a>
    </div> -->
      <!-- <div class="browcallbtn">
          <a [routerLink]="['list-pets']" [queryParams]="{ OperationTypeID:2,radiousDistance:10}" class="green-btn">{{ 'Browse All Found' | translate }}</a>
        </div> -->

      <!-- <a href="">Browse all Lost & Found</a> -->

    

        </div>
      </div>
    </div>

  </div>
</div>
<div class="home-browse-pet">
  <div class="container">


    <div class="row find-search-section-box">
      <div class="col-6">
        <a href="https://www.dyreid.no/hvem-id-merker.html" target="_blank">
          <div class="find-search-section">
            <img class="main-icn" src="assets/img/find_veterinarian.svg" alt="" title="">
            <!-- <img class="main-icn" src="assets/img/home/find_veterinarian.png" alt="" title=""> -->
            <!-- <h4><span>{{ 'Find veterinarians' | translate }}</span></h4> -->
            <h4> {{'Find veterinarians' | translate}} </h4>
            <!-- <p>{{ 'nearest veterinarian string'| translate }}</p> -->
            <div class="search-box-main">
              <div class="search-box">
                <input type="text" placeholder="{{'Search by Zipcode' | translate}}">
                <img src="assets/img/home/find_vertin.png" alt="" title="" class="search-box-zip">
              </div>
            </div>
          </div>
        </a>
      </div>
      <div class="col-6">
        <a href="https://www.dyreid.no/idmerking/finn-ditt-dyr.html" target="_blank">
          <div class="find-search-section">
            <img class="main-icn" src="assets/img/home/search_id.png" alt="" title="">
            <h4><span>{{ 'Search by ID number' | translate }}</span></h4>
            <!-- <p>{{'Search by ID number string'| translate }}</p> -->
            <div class="search-box-main">
              <div class="search-box">
                <input type="text" placeholder="{{'Search by ID number' | translate}}">
                <img src="assets/img/home/search_icn.png" onclick=chipID(event) alt="" title="">
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

<div class="home-ftr">
  <div class="container">
    <div class="ftr-logo"><a><img src="assets/img/dyrlogofooter.svg" alt="" title=""> </a></div>
    <ul class="ftr-nav">
      <li><a href="">{{ 'Help and service' | translate }}</a></li>
      <li><a href="">{{ 'Terms' | translate }}</a></li>
      <li><a href="">{{ 'Om ID-merking' | translate }}</a></li>
      <li><a href="">{{ 'Contact Us' | translate }}</a></li>
    </ul>
  </div>
</div>


<!-- ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// -->