import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OwnerService {

  constructor(private http: HttpClient) { }

  getOwnerAuthToken(UserToken: string) {
    const HttpUploadOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", 
        "APIKEY":"yUkZFfIo0Ujw48uQ7oeMQJ6XlWu7aCbU"}) 
    }
    const UserTokenRequest = {
      "UserToken" : UserToken
    }
    
    return this.http.post(`${environment.apiUrlMinside + environment.GetOwnerAuthToken}`, UserTokenRequest,HttpUploadOptions);
  }

  getOwnerPetList(AuthToken: string) {
    const HttpUploadOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", 
        "APIKEY":"yUkZFfIo0Ujw48uQ7oeMQJ6XlWu7aCbU",
        "AUTHTOKEN":AuthToken}) 
    }
    return this.http.post(`${environment.apiUrlMinside + environment.GetOwnerPetList}`, null,HttpUploadOptions);
  }
  getUserDetailByPhone(phone: string) {
    const postData = {"Phone": phone};
    const HttpUploadOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json", 
        "APIKEY":"yUkZFfIo0Ujw48uQ7oeMQJ6XlWu7aCbU"}) 
    }
    return this.http.post<any>(`${environment.GetOwnerDetailEasyConnectAPI}`,postData,HttpUploadOptions);
  }
}
