import { Injectable } from '@angular/core';
import { HttpClient,  HttpHeaders  } from '@angular/common/http';

import { User } from '../_models';
import { config } from 'process';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })

export class UserService {

    constructor(private http: HttpClient) { }

    getUserByEmailID(emailId: string,provider: string) {
        let getUserByEmailIDUrl= `${environment.apiUrl + environment.GetUserByEmailID}?emailID=${emailId}&provider=${provider}`;
        return this.http.get<any>(getUserByEmailIDUrl);
      }

      UpdateUserInfo(userInfo){
        
        return this.http.post(`${environment.apiUrl + environment.UpdateUserInfo}`, userInfo);
      }
      

}