import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthenticationService, AlertService } from '../_services';
import { AuthService } from 'angularx-social-login';
import { Socialusers } from '../_models';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-company-user-login',
  templateUrl: './company-user-login.component.html',
  styleUrls: ['./company-user-login.component.scss']
})
 
export class CompanyUserLoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    returnUrl: string;  
    socialusers = new Socialusers();

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private alertService: AlertService
    ) {
        // redirect to home if already logged in
        if (this.authenticationService.currentUserValue) {
            this.router.navigate(['/']);
        }
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$"),this.noWhitespaceValidator]],
            password: ['', Validators.required],
            company:['ViKing']
        });

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    // convenience getter for easy access to form fields
     //get socialusers() { return this.loginForm.controls; }

     
     Savesresponse(socialusers: Socialusers) {  
      this.authenticationService.CompanyUserLogIn(socialusers).subscribe((res: any) => {  
          
        debugger;
        if(res != null || res != undefined)
                {
                  this.socialusers = res.data;
                  localStorage.setItem('socialusers', JSON.stringify( this.socialusers));  
                  this.router.navigate([this.returnUrl]);  
                }
      }) 
      this.loading = false; 
    } 
     get f() { return this.loginForm.controls; }
     onSubmit() {
       debugger;
       console.log(JSON.stringify(this.loginForm.value));
         this.submitted = true;
 
         // reset alerts on submit
         this.alertService.clear();
 
         // stop here if form is invalid
         if (this.loginForm.invalid) {
             return;
         }
      debugger;
      this.socialusers.EmailID = this.f.username.value;
      this.socialusers.Password = this.f.password.value;
      this.socialusers.Company = this.f.company.value;

      this.loading = true;
        this.Savesresponse(this.socialusers);
      }
      public noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }
 }