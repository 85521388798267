import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportLostComponent } from '../report/lost/lost.component';
import { ReportFoundComponent } from '../report/found/found.component';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent{

  constructor(
    public dialogReportLost: MatDialogRef<ReportLostComponent>,
    public dialogReportFound: MatDialogRef<ReportFoundComponent>,
    ) {}

  ngOnInit(): void {
  }

  CloseDialogPopup(): void {
    this.dialogReportLost.close();
    this.dialogReportFound.close();
  }
}
