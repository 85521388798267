<div class="profile-form list-view" id="scrollId">
  <div class="container">
    <div class="mobil-viewdt">
      <div class="row">
        <div class="mbl-fixdheader">
          <div class="mblsearch-filter"><img src="assets/img/mbl-search-icon.svg" alt="search-icon" id="showmenu"></div>
          <div class="mbllist-map-filter">

            <div class="icon-view list-icn">
              <img *ngIf="!GridView" class="normal-img" src="assets/img/Ikon_liste.png" ngbTooltip="List View"
                (click)="showGridView()" title="">
              <img *ngIf="GridView" class="active-img" src="assets/img/Ikon_liste.png" ngbTooltip="List View"
                (click)="showGridView()" title="">
            </div>

            <div class="icon-view map-icn" style="display: none;">
              <img *ngIf="!MapView" class="normal-img" src="assets/img/Ikon_lokasjon.png" ngbTooltip="Map View"
                (click)="showMapView()" title="">
              <img *ngIf="MapView" class="active-img" src="assets/img/Ikon_lokasjon.png" ngbTooltip="Map View"
                (click)="showMapView()" title="">
            </div>
          </div>

        </div>
      </div>
    </div>
    <div class="row mbl-fixeddrpdw">
      <div class="mbl-fliterdropw">
        <div class="col-md-12">
          <div *ngIf="!hideLostFoundDetail" class="map-hdng">
            <h1 *ngIf="OperationTypeID == 1">{{ 'Lost' | translate }} {{ 'Pets' | translate }} </h1>
            <h1 *ngIf="OperationTypeID == 2">{{ 'Found' | translate }} {{ 'Pets' | translate }} </h1>
            <h1 *ngIf="OperationTypeID == 3">{{ 'Re-Unite' | translate }} {{ 'Pets' | translate }} </h1>

            <div class="list-right-filter">
              <div class="hdng-right-result-txt">
                <p *ngIf="totalCount == 0">{{'No Pets Found' | translate }}</p>
                <p *ngIf="totalCount > 0">{{'Total ' | translate}} {{totalCount}} {{'Pets' | translate }}</p>
                <!-- <p>{{(totalCount > 0)? 'Total '+ totalCount +' Pets Found' :'No Pets Found' | translate }}</p> -->

                <p class="small-txt">{{'' | translate}}
                  <span *ngIf="OperationTypeID == 1">{{ 'Lost' | translate }} </span>
                  <span *ngIf="OperationTypeID == 2">{{ 'Found' | translate }} </span>
                  <span *ngIf="OperationTypeID == 3">{{ 'Re-Unite' | translate }} </span>
                  {{'pets within' | translate}} {{ searchForm.value.radiousDistance }} km
                </p>
              </div>
              <div class="list-right-filter-row hide-mblfltr">
                <div class="icon-view-box col">
                  <div class="icon-view list-icn">
                    <img *ngIf="!GridView" class="normal-img" src="assets/img/Ikon_liste.png" ngbTooltip="List View"
                      (click)="showGridView()" title="">
                    <img *ngIf="GridView" class="active-img" src="assets/img/Ikon_liste.png" ngbTooltip="List View"
                      (click)="showGridView()" title="">
                  </div>

                  <div class="icon-view map-icn">
                    <img *ngIf="!MapView" class="normal-img" src="assets/img/Ikon_lokasjon.png" ngbTooltip="Map View"
                      (click)="showMapView()" title="">
                    <img *ngIf="MapView" class="active-img" src="assets/img/Ikon_lokasjon.png" ngbTooltip="Map View"
                      (click)="showMapView()" title="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="hideLostFoundDetail" class="map-hdng">
            <h1 class="pet-title">
              {{Pets.petName | translate}} {{'in your area' | translate}}
              <!-- <span *ngIf="pet.Color">{{pet.Color +" "}}</span>{{ pet.MasterBreed +" "+ pet.MasterSpecies}}
                 {{ (pet.OperationTypeID == "1") ? 'Lost' : 'Found'}} <span *ngIf="pet.City">I {{pet.City}}</span> -->

            </h1>

            <div class="list-right-filter">

              <div class="list-right-filter-row hide-mblfltr">
                <div class="icon-view-box col">
                  <div class="icon-view list-icn">
                    <img *ngIf="!GridView" class="normal-img" src="assets/img/Ikon_liste.png" ngbTooltip="List View"
                      (click)="showGridView()" title="">
                    <img *ngIf="GridView" class="active-img" src="assets/img/Ikon_liste.png" ngbTooltip="List View"
                      (click)="showGridView()" title="">
                  </div>

                  <div class="icon-view map-icn">
                    <img *ngIf="!MapView" class="normal-img" src="assets/img/Ikon_lokasjon.png" ngbTooltip="Map View"
                      (click)="showMapView()" title="">
                    <img *ngIf="MapView" class="active-img" src="assets/img/Ikon_lokasjon.png" ngbTooltip="Map View"
                      (click)="showMapView()" title="">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- heading end-->


        <div class="list-filter-sidebar-box col-md-12 top-filter-search">
          <div class="row">
            <div *ngIf="!hideLostFoundDetail" class="col-md-3 search-filter-stats-box">
              <div class="search-filter-stats">

                <div class="stats-inner" style="width:30%" [ngClass]="{'active-stat' : OperationTypeID == 1}"
                  (click)="changeOperationType( 1, $event)"> {{ totalLost }} <span>{{'Lost' | translate}}</span></div>
                <div class="stats-inner" style="width:30%" [ngClass]="{'active-stat' : OperationTypeID == 2}"
                  (click)="changeOperationType( 2, $event)"> {{ totalFound }} <span>{{'Found' | translate}}</span></div>
                <div class="stats-inner" style="width:40%" [ngClass]="{'active-stat' : OperationTypeID == 3}"
                  (click)="changeOperationType( 3, $event)"> {{ totalReUnite }} <span>{{'Re-Unite'| translate}}</span>
                </div>
              </div>

            </div>
            <div class="col-md-9">
              <div class="row">
                <form [formGroup]="searchForm" (ngSubmit)="onSubmit()" class="search-inner-form">
                  <div class="list-filter-sidebar">
                    <div class="list-filter-sidebar-cntnt">
                      <div class="col-md-6 search-area">
                        <img src="assets/img/ic_location.svg" alt="">
                        <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()"
                          formControlName="Area" placeholder="Search Nearest Location" autocorrect="off"
                          autocapitalize="off" spellcheck="off" type="text" #search>
                      </div>

                      <div class="col-md-6">
                        <!--<p>{{ 'Distance' | translate }}</p>-->
                        <div class="distance-range-box">
                          <ng5-slider radiousDistance [options]="options" formControlName="radiousDistance"
                            (userChangeEnd)="onSubmit()"></ng5-slider>
                        </div>
                      </div>

                    </div>

                    <div class="list-filter-sidebar-cntnt">
                      <div class="col-md-6">
                        <div class=" form-group align-items-center custom-po-m-0 choose-sprcies-box">
                          <!--<p for="MasterSpecies" class="col-sm-12 col-form-label">{{ 'Choose Species' | translate }}</p>-->
                          <div class="col-sm-12 form-input-side">
                            <!-- <ng-select bindValue="Value" bindLabel="Text" placeholder="{{'Choose Species' | translate }}" [items]="MasterSpecies" formControlName="SpeciesID"
                        (change)=getBreeds()>
                      </ng-select>          -->
                            <select class="form-control drop-down left-margin" formControlName="SpeciesID"
                              (change)=getBreeds()>
                              <option value="" selected disabled>{{'Choose Species' | translate }}</option>
                              <option *ngFor="let species of MasterSpecies" [value]="species.Value">{{species.Text}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 choose-breed-box">
                        <div class=" form-group align-items-center custom-po-m-0 short-sm">
                          <!--<p for="MasterBreeds" class="col-sm-12 col-form-label">{{ 'Choose Breeds' | translate }}</p>-->
                          <div class="col-sm-12 form-input-side">
                            <!-- <ng-select bindValue="Value" bindLabel="Text"  placeholder="{{'Choose Breeds' | translate }}" [items]="MasterBreeds" formControlName="BreedID" (change)="onSubmit()">
                      </ng-select>               -->
                            <select class="form-control drop-down mob-22" formControlName="BreedID"
                              (change)="onSubmit()">
                              <option value="" selected disabled>{{'Choose Breeds' | translate }}</option>
                              <option *ngFor="let breeds of MasterBreeds" [value]="breeds.Value">{{breeds.Text}}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="btn-box-bottom">
                          <!-- <button class="btn btn-orange submit-btn" type="button" (click)="resetSearchFilters()">Submit</button> -->
                          <div class="mbl-lost-fnd">
                            <div class="mblbtn-sec">
                              <select class="form-control drop-down left-margin browcallbtn"
                                formControlName="OperationTypeID" (change)="onSubmit()">
                                <option value="" selected disabled>{{'Choose Type' | translate }}</option>
                                <option value="2">Browse Found</option>
                                <option value="1">Browse Lost</option>
                                <option value="3">RE-UNITE</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div class="btn-box-bottom" style="margin-top: 15px;">
                          <!-- <button class="btn btn-orange submit-btn" type="button" (click)="resetSearchFilters()">Submit</button> -->
                          <div class="mbl-lost-fnd">
                            <div class="mblbtn-sec">
                              <div class="browcallbtn mb-2 hidembl">
                                <a routerLink="/report-lost" class="mbl-orangebtn ">{{ 'Report Lost' | translate }}</a>
                              </div>
                              <div class="browcallbtn  hidembl">
                                <a routerLink="/report-found" class="mbl-orangebtn">{{ 'Report Found' | translate }}</a>
                              </div>


                              <button class="btn btn-orange reset-btn large-lg " type="button"
                                (click)="resetSearchFilters()" ngbTooltip="Reset Filters">
                                <!-- <img src="assets/img/ic_refresh.svg" title=""> -->
                                Tilbakestill
                              </button>
                              <button class="btn btn-orange reset-btn gobtn" type="button" id="gobtnclk">{{ 'Send' |
                                translate }}</button>

                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div *ngIf="!hideLostFoundDetail" class="col-md-12 list-right-cntnt">

        <div class="list-filter row">
          <div class="all-list-box" *ngIf="GridView">
            <div class="col-md-12 text-center" *ngIf="petDetailsList?.length === 0">
              <p>{{'No matching records found' | translate}}</p>
            </div>
            <div class="all-list-box-cntnt">
              <div class="col-md-6"
                *ngFor="let petDetail of petDetailsList | paginate: { id: 'server', itemsPerPage: 10, currentPage: page, totalItems: totalCount }">
                <div class="list-card">
                  <!-- <div class="list-card-img"   *ngIf="! petDetail.ThumbnailImagePath">
                    <img  src="{{ petImagePath + petDetail.ImagePath }}" (click)="openModal($event)" class="hover-shadow-dashboard cursor-dashboard image-class-dashboard" alt="">
                  </div>
                  <div class="list-card-img"  *ngIf="petDetail.ThumbnailImagePath">
                    <img  src="{{ petImagePath + petDetail.ThumbnailImagePath }}" (click)="openModal($event)" class="hover-shadow-dashboard cursor-dashboard image-class-dashboard" alt="">
                  </div>
                  <div attr.id="{{petDetail.LostFoundID}}" class="slide-modal-dashboard">
                    <span class="close-dashboard cursor-dashboard" (click)="closeModal($event)">&times;</span>
                    <div class="slide-modal-content-dashboard">
                        <img class="images-dashboard image-class-dashboard" src="{{ petImagePath + petDetail.ImagePath }}">
                    </div>
                </div> -->

                  <div class="list-card-img">
                    <div class="image-row-dashboard" *ngIf="petDetail.ThumbnailImagePath">
                      <img src="{{ petImagePath + petDetail.ThumbnailImagePath }}" (click)="openModal($event)" (error)="errorHandler($event)"
                        class="hover-shadow-dashboard cursor-dashboard image-class-dashboard">
                    </div>
                    <div class="image-row-dashboard" *ngIf="! petDetail.ThumbnailImagePath">
                      <img src="{{ petImagePath + petDetail.ImagePath }}" (click)="openModal($event)" (error)="errorHandler($event)"
                        class="hover-shadow-dashboard cursor-dashboard image-class-dashboard">
                    </div>
                    <div attr.id="{{petDetail.LostFoundID}}" class="slide-modal-dashboard">
                      <span class="close-dashboard cursor-dashboard" (click)="closeModal($event)">&times;</span>
                      <div class="slide-modal-content-dashboard">
                        <img class="images-dashboard image-class-dashboard" (error)="errorHandler($event)"
                          src="{{ petImagePath + petDetail.ImagePath }}">
                      </div>
                    </div>
                  </div>


                  <div class="list-card-cntnt">
                    <div class="open-detail-click" (click)="goToPetDetails(petDetail)">
                      <h5>
                        <b *ngIf="! petDetail.MasterBreed"><strong><span *ngIf="petDetail.Color">{{petDetail.Color +"
                              "}}</span>{{ petDetail.MasterBreed +" "+ petDetail.MasterSpecies}} {{ ((OperationTypeID ==
                            "1") ? 'Lost' : 'Found')| translate}} <span *ngIf="petDetail.City">i
                              {{petDetail.City}}</span></strong></b>
                        <b *ngIf="petDetail.MasterBreed"><strong><span *ngIf="petDetail.Color">{{petDetail.Color +"
                              "}}</span>{{ petDetail.MasterBreed }} ({{petDetail.MasterSpecies}}) {{ ((OperationTypeID
                            == "1") ? 'Lost' : 'Found') | translate}} <span *ngIf="petDetail.City">i
                              {{petDetail.City}}</span></strong></b>
                      </h5>
                      <!-- <p><span>{{ 'Species' | translate }}:</span> {{ petDetail.MasterSpecies }} </p> -->
                      <p>{{ (petDetail.Area == null) ? "" : petDetail.Area }} {{ ((petDetail.ZipCode == null) ? "" : petDetail.ZipCode) +" "+((petDetail.City == null) ? "" : petDetail.City)}}</p>
                      <p style="display: block;"><span>{{ ((OperationTypeID == "1") ? 'Lost' : 'Found') | translate}} {{ 'Date' | translate
                          }}:&nbsp;{{ petDetail.DisplayLostFoundDate}}</span> </p>
                      <!-- <p><span>{{ 'Last Updated' | translate }}:</span> {{ petDetail.DisplayUpdatedDate}}</p> -->

                    </div>
                    <!-- <div class="btn-box">
                      <a class="btn btn-secondary" ngbTooltip="View" (click)="goToPetDetails(petDetail)"><img src="assets/img/post_view.svg" title=""></a>
                      <button type="submit" class="btn btn-primary mr-3" ngbTooltip="Share" ><img src="assets/img/post_share.svg" title=""></button>
                      </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center" *ngIf="petDetailsList?.length > 0">
              <pagination-controls (pageChange)="getFilterPetsGrid($event, true)" id="server"></pagination-controls>
            </div>
          </div>
          <div class="map-box map-box-full" *ngIf="MapView">
            <div class="col-md-12 text-center" *ngIf="markers?.length === 0">
              <p>{{'No matching records found' | translate}}</p>
            </div>
            <agm-map (mapClick)="close_window()" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker-cluster
                imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                <agm-marker *ngFor="let m of markers; let i = index" (markerClick)="clickedMarker('fgf', i, infoWindow)"
                  [latitude]="m.lat" [longitude]="m.lng" [iconUrl]="m.petIcon" [label]="" [markerDraggable]="true">
                  
                  <agm-info-window #infoWindow>
                    <div style="max-width: 200px; max-height: 400px;" (click)="goToPetDetails(m);">
                      <!-- <img src="{{ petImagePath + petDetail.ImagePath }}" alt=""> -->
                      <img *ngIf="!m.ThumbImagePath" (error)="errorHandler($event)" src="{{ petImagePath + m.ImagePath }}" alt="Image"
                        style="max-width: 100%!important;max-height: 20%!important;"><br>
                      <img *ngIf="m.ThumbImagePath" (error)="errorHandler($event)" src="{{ petImagePath + m.ThumbImagePath }}" alt="Image"
                        style="max-width: 100%!important;max-height: 20%!important;"><br>


                      <strong><b *ngIf="! m.BreedsName">
                          <span *ngIf="m.Color">{{m.Color +" " }}</span>{{ m.BreedsName +" "+ m.SpeciesName}} {{
                          ((OperationTypeID == "1") ? 'Lost' : 'Found')| translate}} <span *ngIf="m.City">i
                            {{m.City}}</span></b></strong>
                      <strong><b *ngIf="m.BreedsName">
                          <span *ngIf="m.Color">{{m.Color +" "}}</span>{{ m.BreedsName }} ({{m.SpeciesName}}) {{
                          ((OperationTypeID == "1") ? 'Lost' : 'Found') | translate }} <span *ngIf="m.City">i
                            {{m.City}}</span></b></strong>

                      <div class="width-100">
                        <div class="missing-popmap">{{ ((OperationTypeID == "1") ? 'Missing' : 'Found')| translate}}:
                        </div>
                        <div class="missing-popmapdetails"> {{ m.DisplayLostFoundDate }}</div>
                      </div>
                      <div class="width-100">
                        <div class="missing-popmap"> {{ 'Last Updated' | translate }}: </div>
                        <div class="missing-popmapdetails">{{ m.DisplayUpdatedDate }}</div>

                      </div>

                      <!-- <a  [routerLink]="['pet-detail']" [queryParams]="{ PetID:m.LostFoundID}" >Detail</a> -->
                    </div>
                  </agm-info-window>

                </agm-marker>
              </agm-marker-cluster>
            </agm-map>
          </div>
        </div>
      </div>
      <div *ngIf="hideLostFoundDetail" class="col-md-12 list-right-cntnt">

        <div class="list-filter row">
          <div class="all-list-box" *ngIf="GridView">
            <div class="col-md-12 text-center" *ngIf="petDetailsList?.length === 0">
              <p>{{'No matching records found' | translate}}</p>
            </div>
            <div class="all-list-box-cntnt">
              <div class="col-md-6"
                *ngFor="let petDetail of petDetailsList | paginate: { id: 'server', itemsPerPage: 10, currentPage: page, totalItems: totalCount }">
                <div class="list-card">
                  <!-- <div class="list-card-img"   *ngIf="! petDetail.ThumbnailImagePath">
                    <img  src="{{ petImagePath + petDetail.ImagePath }}" (click)="openModal($event)" class="hover-shadow-dashboard cursor-dashboard image-class-dashboard" alt="">
                  </div>
                  <div class="list-card-img"  *ngIf="petDetail.ThumbnailImagePath">
                    <img  src="{{ petImagePath + petDetail.ThumbnailImagePath }}" (click)="openModal($event)" class="hover-shadow-dashboard cursor-dashboard image-class-dashboard" alt="">
                  </div>
                  <div attr.id="{{petDetail.LostFoundID}}" class="slide-modal-dashboard">
                    <span class="close-dashboard cursor-dashboard" (click)="closeModal($event)">&times;</span>
                    <div class="slide-modal-content-dashboard">
                        <img class="images-dashboard image-class-dashboard" src="{{ petImagePath + petDetail.ImagePath }}">
                    </div>
                </div> -->

                  <div class="list-card-img">
                    <div class="image-row-dashboard" *ngIf="petDetail.ThumbImagePath">
                      <img src="{{ petDetail.ThumbImagePath }}" (click)="openModal($event)" (error)="errorHandler($event)"
                        class="hover-shadow-dashboard cursor-dashboard image-class-dashboard">
                    </div>
                    <div class="image-row-dashboard" *ngIf="! petDetail.ThumbImagePath">
                      <img src="{{ petDetail.ImagePath }}" (click)="openModal($event)" (error)="errorHandler($event)"
                        class="hover-shadow-dashboard cursor-dashboard image-class-dashboard">
                    </div>
                    <div attr.id="{{petDetail.LostFoundID}}" class="slide-modal-dashboard">
                      <span class="close-dashboard cursor-dashboard" (click)="closeModal($event)">&times;</span>
                      <div class="slide-modal-content-dashboard">
                        <img class="images-dashboard image-class-dashboard" src="{{ petDetail.ImagePath }}" (error)="errorHandler($event)">
                      </div>
                    </div>
                  </div>


                  <div class="list-card-cntnt">
                    <div class="open-detail-click" (click)="goToPetDetails(petDetail)">
                      <h5>
                        <b *ngIf="! petDetail.BreedsName"><strong><span *ngIf="petDetail.Color">{{petDetail.Color +"
                              "}}</span>{{ petDetail.BreedsName +" "+ petDetail.SpeciesName}} <span
                              *ngIf="petDetail.City">i
                              {{petDetail.City}}</span></strong></b>
                        <b *ngIf="petDetail.BreedsName"><strong><span *ngIf="petDetail.Color">{{petDetail.Color +"
                              "}}</span>{{ petDetail.BreedsName }} ({{petDetail.SpeciesName}}) <span
                              *ngIf="petDetail.City">i
                              {{petDetail.City}}</span></strong></b>
                      </h5>
                      <!-- <p><span>{{ 'Species' | translate }}:</span> {{ petDetail.MasterSpecies }} </p> -->

                      <p>{{ (petDetail.Country == null) ? "" : petDetail.Country }}&nbsp;</p>
                      <p>{{ ((petDetail.ZipCode == null) ? "" : petDetail.ZipCode) +" "+((petDetail.City == null) ? "" :
                        petDetail.City)}}</p>
                      <!-- <p><span>{{ 'Last Updated' | translate }}:</span> {{ petDetail.DisplayUpdatedDate}}</p> -->

                    </div>
                    <!-- <div class="btn-box">
                      <a class="btn btn-secondary" ngbTooltip="View" (click)="goToPetDetails(petDetail)"><img src="assets/img/post_view.svg" title=""></a>
                      <button type="submit" class="btn btn-primary mr-3" ngbTooltip="Share" ><img src="assets/img/post_share.svg" title=""></button>
                      </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center" *ngIf="petDetailsList?.length > 0">
              <pagination-controls (pageChange)="getFilterPetsGrid($event, true)" id="server"></pagination-controls>
            </div>
          </div>
          <div class="map-box map-box-full" *ngIf="MapView">
            <div class="col-md-12 text-center" *ngIf="markers?.length === 0">
              <p>{{'No matching records found' | translate}}</p>
            </div>
            <agm-map (mapClick)="close_window()" [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker-cluster
                imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
                <agm-marker *ngFor="let m of markers; let i = index" (markerClick)="clickedMarker('fgf', i, infoWindow)"
                  [latitude]="m.lat" [longitude]="m.lng" [iconUrl]="m.petIcon" [label]="" [markerDraggable]="true">

                  <agm-info-window #infoWindow>
                    <div style="max-width: 200px; max-height: 400px;" (click)="goToPetDetails(m);">
                      <!-- <img src="{{ petImagePath + petDetail.ImagePath }}" alt=""> -->
                      <img *ngIf="! m.ThumbImagePath" src="{{  m.ThumbImagePath }}" alt="Image" (error)="errorHandler($event)"
                        style="max-width: 100%!important;max-height: 20%!important;"><br>
                      <img *ngIf=" m.ThumbImagePath" src="{{ m.ImagePath }}" alt="Image" (error)="errorHandler($event)"
                        style="max-width: 100%!important;max-height: 20%!important;"><br>


                      <strong><b *ngIf="! m.BreedsName">
                          <span *ngIf="m.Color">{{m.Color +" " }}</span>{{ m.BreedsName +" "+ m.SpeciesName}}
                          <span *ngIf="m.City">i{{m.City}}</span></b></strong>
                      <strong><b *ngIf="m.BreedsName">
                          <span *ngIf="m.Color">{{m.Color +" "}}</span>{{ m.BreedsName }} ({{m.SpeciesName}})
                          <span *ngIf="m.City">i{{m.City}}</span></b></strong>

                      <!-- <div class="width-100">
                        <div class="missing-popmap">{{ ((OperationTypeID == "1") ? 'Missing' : 'Found')| translate}}:
                        </div>
                        <div class="missing-popmapdetails"> {{ m.DisplayLostFoundDate }}</div>
                      </div>
                      <div class="width-100">
                        <div class="missing-popmap"> {{ 'Last Updated' | translate }}: </div>
                        <div class="missing-popmapdetails">{{ m.DisplayUpdatedDate }}</div>

                      </div> -->

                      <!-- <a  [routerLink]="['pet-detail']" [queryParams]="{ PetID:m.LostFoundID}" >Detail</a> -->
                    </div>
                  </agm-info-window>

                </agm-marker>
              </agm-marker-cluster>
            </agm-map>
          </div>
        </div>
      </div>
      <!--filter end-->
      <div class="col-md-12">
        <div class="row">
          <div class="mobile-fixdbtn">
            <div class="mobile-allbtn">
              <div class="browcallbtn">
                <!-- <a href="" class="orangembl-btn">Dog</a> -->
                <a (click)="SearchPetByType(1)" class="orangembl-btn" [ngClass]="{'b1': tabActive == 1}">{{ 'Dog' |
                  translate }}</a>
              </div>
              <div class="browcallbtn">
                <!-- <a href="" class="orangembl-btn"> Cat</a> -->
                <a (click)="SearchPetByType(2)" class="orangembl-btn" [ngClass]="{'b1': tabActive == 2}">{{ 'Cat' |
                  translate }}</a>
              </div>
              <div class="browcallbtn">
                <!-- <a href="" class="orangembl-btn"> All</a> -->
                <a (click)="SearchPetByType(3)" class="orangembl-btn" [ngClass]="{'b1': tabActive == 3}">{{ 'All' |
                  translate }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>