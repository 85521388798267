export class User {
    provider: string;  
    id: number;
    name: string;
    email: string;
    image: string;  
    token?: string;  
    idToken?: string;  
    FirstName?: string;
    LastName?: string;
    Company?:string;
    Password?:string;
}