import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PetsService } from '../../../../_services';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';

import { AuthenticationService, OwnerService } from '../../../../_services';
import { AuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { DatePipe } from '@angular/common';
import { AppComponent } from '../../../../app.component';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DateAdapter } from '@angular/material';

@Component({
  selector: 'app-lostpetinfo',
  templateUrl: './petinfo.component.html',
  styleUrls: ['./petinfo.component.scss']
})

export class LostPetinfoComponent implements OnInit {

  registerForm: FormGroup;
  showFoundSuggestion: boolean = true;
  isChipIdToShowInDetail:boolean=false;
  @Input() showPersonalInfo;
  @Input() minsidePetInfo: any;
  public lat: number;
  public lng: number;
  public zoom: number;
  private geoCoder;
  public Area: any;
  private user: SocialUser;
  private loggedIn: boolean;
  minDate: Date;
  maxDate: Date;
  lostMaxDate: Date;
  chipFound: string = "";
  petImgUrl:string = null

  @ViewChild('search')

  private searchElementRef: ElementRef;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    public app: AppComponent,
    private pets: PetsService,
    private formbuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router: Router,
    private authService: AuthService,
    private datePipe: DatePipe,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService,
  ) {
    this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
    // const currentYear = new Date().getFullYear();
    // const currentDate = new Date().getDate();

    // this.minDate = new Date(currentYear - 20, 0, 1);
    var dateChangedvalue = new Date();
    this.maxDate = dateChangedvalue;
    this.lostMaxDate = dateChangedvalue;
  }

  country: any;
  MasterSpecies: any;
  MasterBreeds: any;
  submitted = false;
  IsChipped = false;
  currentUser: any;
  fileData: File = null;
  croppedFileData: any = null;
  previewUrl: any = "assets/img/user_add.png";
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  imageError = "";
  closeResult = "";
  isMinsideUser: boolean = false;

  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    sublocality_level_1: 'long_name',
    sublocality_level_2: 'long_name',
    administrative_area_level_1: 'long_name',
    administrative_area_level_2: 'long_name',
    country: 'long_name',
    postal_code: 'short_name',
    postal_town: 'long_name'

  };

  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {

  }
  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }

  open(content) {
    this.previewUrl = "assets/img/user_add.png";
    this.croppedFileData = "";
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.previewUrl = this.croppedImage;
      this.croppedFileData = this.croppedImage;
      //return false;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.registerForm.controls['Image'].patchValue("");
      //return false;
    });
    return false;
  }
  private getDismissReason(reason: any): string {
    this.registerForm.controls['Image'].patchValue("");
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  UpdateDobMaxDate() {
    var dateChangedvalue = this.registerForm.controls["LostFoundDate"].value;
    dateChangedvalue.setDate(dateChangedvalue.getDate() - 1);
    this.lostMaxDate = dateChangedvalue;
  }
  ngOnInit() {

    this.registerForm = this.formbuilder.group({
      IsChipped: ['0'],
      ChipID: [''],
      PetName: ['', [Validators.required, this.noWhitespaceValidator]],
      MasterSpeciesID: ['', Validators.required],
      MasterBreedID: ['', Validators.required],
      LostFoundDate: [new Date(), Validators.required],
      DateOfBirth: [''],
      Color: [''],
      Gender: [1],
      HairType: ['1'],
      Area: ['', Validators.required],
      State: ['', [Validators.required, this.noWhitespaceValidator]],
      Commune: ['', [this.noWhitespaceValidator]],
      District: ['', [this.noWhitespaceValidator]],
      City: ['', [Validators.required, this.noWhitespaceValidator]],
      Country: ['Norway', Validators.required],
      ZipCode: ['', Validators.required],
      AdditionalInfo: [''],
      ImagePath: [''],
      Company: [''],
      Image: [''],
      croppedFileData: [''],
      lat: [''],
      lng: [''],
      OperationTypeID: [''],
      IsChipIDinDetail:['0']
    });

    this.showPersonalInfo = false;
    this.registerForm.value.IsChipped = 0;

    this.pets.getCountry().subscribe(res => {
      this.country = res;
    });
    this.pets.getSpecies().subscribe(species => {
      this.MasterSpecies = species;

    });
    this.currentUser = this.authenticationService.currentUserValue;
    if (this.currentUser.Provider == 'MINSIDE') {
      this.isMinsideUser = true;
      this.registerForm.controls['LostFoundDate'].patchValue('');
    }
    else {
      //load Places Autocomplete
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;

        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
          types: []
        });
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();

            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
            this.fillAddress(place)
            //set latitude, longitude and zoom
            this.lat = place.geometry.location.lat();
            this.lng = place.geometry.location.lng();
            this.zoom = 15;
            this.getSuggestion();
          });
        });
      });
    }


    if (this.minsidePetInfo) {
      var genderValue = 0;
      var hairtypeValue = 0;
      var obj = this.minsidePetInfo;
      var dob = null;
      if (obj.BirthYear && obj.BirthMonth && obj.BirthDate) {
        dob = new Date(obj.BirthYear, obj.BirthMonth, obj.BirthDate);
      }
      this.registerForm.controls['IsChipped'].patchValue('1');
      this.registerForm.controls['ChipID'].patchValue(obj.ChipId);

      if (obj.ChipId) {
        this.registerForm.controls['IsChipped'].patchValue('1');
        this.registerForm.controls['IsChipped'].disable();
        this.registerForm.controls['ChipID'].patchValue(obj.ChipId);
        this.registerForm.controls['ChipID'].disable();
      }
      this.registerForm.controls['PetName'].patchValue(obj.PetName);
      // debugger;
      this.registerForm.controls['Company'].patchValue(this.currentUser.Company)
      if (obj.PetName) {
        this.registerForm.controls['PetName'].disable();
      }
      this.registerForm.controls['MasterSpeciesID'].patchValue(obj.SpeciesId);
      if (obj.SpeciesId) {
        this.registerForm.controls['MasterSpeciesID'].patchValue(obj.SpeciesId);
        this.registerForm.controls['MasterSpeciesID'].disable();
      }

      this.registerForm.controls['DateOfBirth'].patchValue(dob);
      if (dob) {
        this.registerForm.controls['DateOfBirth'].disable();
      }
      this.registerForm.controls['Color'].patchValue(obj.Color);
      if (obj.Color) {
        this.registerForm.controls['Color'].disable();
      }

      if (obj.Gender) {
        if (obj.Gender.toLowerCase() == "male") {
          genderValue = 1;
        } else if (obj.Gender.toLowerCase() == "female") {
          genderValue = 2;
        }
      }

      this.registerForm.controls['Gender'].patchValue(genderValue);
      if (obj.HairType) {
        if (obj.HairType.toLowerCase() == 'longhair') {
          hairtypeValue = 1;
        }
        else if (obj.HairType.toLowerCase() == 'sorthair') {
          hairtypeValue = 2;
        }
      }
      this.registerForm.controls['HairType'].patchValue(hairtypeValue);


      this.pets.getBreeds(obj.SpeciesId).subscribe(breeds => {
        this.MasterBreeds = breeds;
        this.registerForm.controls['MasterBreedID'].patchValue(obj.BreedId);
        let data = this.MasterBreeds.find(ob => ob.Value === obj.BreedId);


        if (data) {
          this.registerForm.controls['MasterBreedID'].disable();
        } else {
          if (obj.BreedName) {
            let data1 = this.MasterBreeds.find(ob => ob.Text.toLowerCase() === obj.BreedName.toLowerCase());
            if (data1) {
              obj.BreedId = data1.Value; // in case id does not match patch with Name
              this.registerForm.controls['MasterBreedID'].disable();
            }
          }
        }
        this.registerForm.controls['MasterBreedID'].patchValue(obj.BreedId);
        this.getSuggestion();
      });
    }
  }

  get petf() {
    return this.registerForm.controls;
  }

  getSuggestion() {
    this.chipFound = "";

    if (this.minsidePetInfo) {   // In case you Minside Pet
      let filters = {
        "SearchFilters": {
          "SpeciesID": this.registerForm.getRawValue().MasterSpeciesID,
          "BreedID": this.registerForm.getRawValue().MasterBreedID,
          "lat": this.lat,
          "lng": this.lng,
          "radiousDistance": 20,
          "OperationTypeID": 2
        }
      }
      this.onPosted.emit(filters)
    } else {
      let filters = {
        "SearchFilters": {
          "SpeciesID": this.registerForm.value.MasterSpeciesID,
          "BreedID": this.registerForm.value.MasterBreedID,
          "lat": this.lat,
          "lng": this.lng,
          "radiousDistance": 10,
          "OperationTypeID": 2
        }
      }
      this.onPosted.emit(filters)
    }


  }

  getChipInfo() {
    this.registerForm.controls['PetName'].patchValue("");
    this.registerForm.controls['MasterSpeciesID'].patchValue("");
    this.registerForm.controls['Color'].patchValue("");
    this.registerForm.controls['MasterBreedID'].patchValue("");

    // this.pets.IsChipIDExists(this.registerForm.value.ChipID, this.registerForm.value.OperationTypeID).subscribe(IsChipExist =>{
    this.pets.IsChipIDExists(this.registerForm.value.ChipID, 1).subscribe(IsChipExist => {
      if (IsChipExist == true && this.registerForm.value.ChipID.trim() != "") {
        // this.chipFound = "Pet with ChipID " + this.registerForm.value.ChipID + " Already exists";
        this.chipFound = "Kjæledyr med ChipID " + this.registerForm.value.ChipID + " Eksisterer allerede";
        this.registerForm.controls['ChipID'].patchValue("");
      }
      else {
        this.pets.getChipInfo(this.registerForm.value.ChipID).subscribe(chipInfo => {
          var genderValue: any;
          var hairtypeValue: any;
          if (!chipInfo.IsAlive)// data from api came true as false and false as true.
          {
            if (chipInfo.ChipId) {
              var dob = null;
              if (chipInfo.BirthYear && chipInfo.BirthMonth && chipInfo.BirthDate) {
                dob = new Date(chipInfo.BirthYear, chipInfo.BirthMonth, chipInfo.BirthDate);
              }
              //PetOriginalPhoto
              if(chipInfo.PetPhoto !== ''){
                this.previewUrl = chipInfo.PetPhoto;
              }
              this.petImgUrl = chipInfo.PetOriginalPhoto;
              this.registerForm.controls['PetName'].patchValue(chipInfo.PetName);
              this.registerForm.controls['MasterSpeciesID'].patchValue(chipInfo.SpeciesID);
              this.registerForm.controls['Color'].patchValue(chipInfo.PetColor);
              this.registerForm.controls['DateOfBirth'].patchValue(dob);
              // this.registerForm.controls['Gender'].patchValue(genderValue);
              this.registerForm.controls['LostFoundDate'].patchValue(new Date());
              if (chipInfo.PetSex) {
                if (chipInfo.PetSex.toLowerCase() == "male" || chipInfo.PetSex.toLowerCase() == "hanndyr") {
                  genderValue = 1;
                } else if (chipInfo.PetSex.toLowerCase() == "female" || chipInfo.PetSex.toLowerCase() == "hunndyr") {
                  genderValue = 2;
                }
              }
              this.registerForm.controls['Gender'].patchValue(genderValue);
              if (chipInfo.HairType) {
                if (chipInfo.HairType.toLowerCase() == 'longhair') {
                  hairtypeValue = 1;
                }
                else if (chipInfo.HairType.toLowerCase() == 'sorthair') {
                  hairtypeValue = 2;
                }
              }
              this.registerForm.controls['HairType'].patchValue(hairtypeValue);
              this.pets.getBreeds(chipInfo.SpeciesID).subscribe(breeds => {
                this.MasterBreeds = breeds;
                this.registerForm.controls['MasterBreedID'].patchValue(chipInfo.BreedID);
                this.getSuggestion();
              });
            }
            else {
              // this.chipFound = "No data found for this chipId. Please fill details below."
              this.chipFound = "Ingen data funnet for denne brikken. Vennligst fyll detaljene nedenfor";
            }
          }
          else {
            var chipID = this.registerForm.value.ChipID;
            this.registerForm.controls['ChipID'].patchValue("");
            this.chipFound = "Kjæledyr med ChipId :" + chipID + " er død, og du kan ikke rapportere dette kjæledyret";
          }
        });
      }
    });
  }


  getBreeds() {
    this.registerForm.controls['MasterBreedID'].patchValue("");
    this.pets.getBreeds(this.registerForm.value.MasterSpeciesID).subscribe(breeds => {
      this.MasterBreeds = breeds;
      this.getSuggestion();
    });
  }
  @Output() onPosted = new EventEmitter();

  onSubmit() {
    this.submitted = true;
    if (this.imageError) {
      this.scrollToError();
      return;
    }

    if(this.registerForm.value.Image == '' && this.registerForm.value.IsChipped == '0'){
      this.imageError = 'Pet Image is required';
      const ele = document.getElementById('error-msg');
      this.scrollTo(ele);
      return;
    }
    if(this.registerForm.value.Image == '' && this.registerForm.value.IsChipped == '1' && this.previewUrl == 'assets/img/user_add.png'){
      this.imageError = 'Pet Image is required';
      const ele = document.getElementById('error-msg');
      this.scrollTo(ele);
      return;
    }
    
    if (this.registerForm.invalid) {
      this.scrollToError();
      return;
    }
    // debugger;
    if(this.petImgUrl && !this.fileData){
      this.registerForm.controls['ImagePath'].patchValue(this.petImgUrl);
    }else{
      this.registerForm.controls['ImagePath'].patchValue(this.fileData);
    }
    this.registerForm.controls['Company'].patchValue(this.currentUser.Company);
    this.registerForm.controls['croppedFileData'].patchValue(this.croppedFileData);
    this.registerForm.controls['lat'].patchValue(this.lat);
    this.registerForm.controls['lng'].patchValue(this.lng);
    if (this.minsidePetInfo) {
      this.onPosted.emit(this.registerForm.getRawValue())
    } else {
      this.onPosted.emit(this.registerForm.value)
    }
    const firstElementWithError = document.querySelector('.xng-breadcrumb-root');
    this.scrollTo(firstElementWithError);
    //this.router.navigate(['/personal']);

  }

  private setCurrentLocation() {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.zoom = 15;
        this.getAddress(this.lat, this.lng);
      });
    }
  }

  toggleFoundSuggestion() {
    this.showFoundSuggestion = !this.showFoundSuggestion;
  }

  markerDragEnd($event: any) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.getAddress(this.lat, this.lng);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.fillAddress(results[0])
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  fillAddress(mapresult) {
    this.zoom = 15;
    let area = mapresult.formatted_address;
    //this.registerForm.controls['Area'].patchValue(results[0].formatted_address)
    let place = mapresult;
    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0];
      if (this.componentForm[addressType]) {
        var val = place.address_components[i][this.componentForm[addressType]];

        if (addressType == "administrative_area_level_1") {
          this.registerForm.controls['State'].patchValue(val)
          area = area.replace(val, "")

          //  this.state = val;
        }
        if (addressType == "administrative_area_level_2") {
          this.registerForm.controls['District'].patchValue(val)
          area = area.replace(val, "")

          //  this.state = val;
        }
        if (addressType == "country") {
          //this.registerForm.controls['Country'].patchValue(val)
          area = area.replace(val, "")
          //  this.state = val;
        }
        if (addressType == "locality") {
          this.registerForm.controls['City'].patchValue(val)
          area = area.replace(val + ", ", "")
          //  this.state = val;
        }
        if (addressType == "postal_town") {
          this.registerForm.controls['City'].patchValue(val)
          area = area.replace(val + ", ", "")
        }
        if (addressType == "postal_code") {
          this.registerForm.controls['ZipCode'].patchValue(val)
          // area = area.replace(val + ", ", "")
          area = area.replace(val+", " , "")
          //  this.state = val;
        }
        if (addressType == "postal_code") {
          this.registerForm.controls['ZipCode'].patchValue(val)
          // area = area.replace(val + ", ", "")
          area = area.replace(val , "")
          //  this.state = val;
        }


      }
    }
    area = area.trim();
    let comma = area.substring(area.length, area.length - 1)
    if (comma == ",") {
      area = area.substring(0, area.length - 1)
    }
    area = area.trim();
    this.registerForm.controls['Area'].patchValue(area)
  }

  fileProgress(fileInput: any) {
    this.imageError = null;
    if (<File>fileInput.target.files && <File>fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 15000000;
      const allowed_types = ['image/png', 'image/jpeg'];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        this.imageError =
          'Maximum size allowed is ' + max_size / 1000 + 'kb';

        return false;
      }
      if (!allowed_types.includes(fileInput.target.files[0].type)) {
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }

      let Content: HTMLElement = document.getElementById("contentNew") as HTMLElement
      Content.click();
      this.imageChangedEvent = event;
      this.fileData = <File>fileInput.target.files[0];
      // this.preview();
    }
  }

  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    }
  }
  public noWhitespaceValidator(control: FormControl) {
    if (control.value && control.value.length > 0) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true };
    }
  }

  public onIsChippedChange() {
    this.registerForm.controls["ChipID"].clearValidators();
    if (this.registerForm.controls.IsChipped.value == 1) {
      this.registerForm.controls["ChipID"].setValidators([Validators.required, this.noWhitespaceValidator]);
      this.registerForm.controls["IsChipIDinDetail"].patchValue(0);
    }
    this.registerForm.controls["ChipID"].updateValueAndValidity()
  }

  public onClickFillDate() {
    this.registerForm.controls['LostFoundDate'].patchValue(new Date())
  }
  public onClickFillAddress() {
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
        types: []
      });
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          //get the place result
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          //verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.fillAddress(place)
          //set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 15;
          this.getSuggestion();
        });
      });
    });
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
    this.scrollTo(firstElementWithError);
  }

}




