<h4 mat-dialog-title>{{'Contact Reporter'| translate }}</h4>
<div mat-dialog-content>
  
  <p> Needs String :Your Contact detail will be sent to the selected person's email. He will contact you by the details given by you.</p>
  <mat-form-field>
    <!-- <mat-label>Hello Information is here.</mat-label> -->
    <!-- <input matInput [(ngModel)]="data.animal"> -->
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button class="btn btn-secondary" mat-button (click)="CloseDialogPopup()">OK</button>
</div>