import { Component, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Pets } from '../../../_models';
import { Router } from '@angular/router';
import { PetsService } from '../../../_services';
import { AuthenticationService } from '../../../_services';
import { environment } from '../../../../environments/environment';
import { DialogComponent } from '../../dialog/dialog.component';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-found',
  templateUrl: './found.component.html',
  styleUrls: ['./found.component.scss']
})
export class ReportFoundComponent implements OnInit {

  showFoundSuggestion: boolean = false;
  public showPersonalInfo: boolean = false;
  public petsinfo: any;
  public personalsinfo: any;
  private petDetails = { petDetails: {}, Image: {} };
  pets = new Pets();
  petsSuggestion: any;
  currentUser: any;
  petImagePath = environment.apiBaseUrl + "/Static/Images/";
  petDetailPath = environment.apiBaseUrl + "/pet-detail?PetID=";

  constructor(
    private router: Router,
    private petsService: PetsService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    public SpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;
    localStorage.removeItem('ListPageNo');
    localStorage.removeItem('ListView');
    localStorage.removeItem('SearchFilters');
  }

  onPosted(data) {
    if (data) {
      if (data.SearchFilters) {
        // console.log(data.SearchFilters);
        this.petsService.GetLostFoundPetsListPartialFields(data.SearchFilters).subscribe(res => {
          this.petsSuggestion = res;
          if (this.petsSuggestion.RecordsFiltered == 0) {
            this.petsSuggestion = ""
          }
        });
      }

      if (data.IsChipped) {
        this.petsinfo = data;
        this.showPersonalInfo = true
      }
      if (data.FirstName) {
        this.personalsinfo = data;
        let Image = this.petsinfo.ImagePath;
        let croppedFileData = this.petsinfo.croppedFileData;
        this.petsinfo.MasterSpeciesID = parseInt(this.petsinfo.MasterSpeciesID)
        this.petsinfo.MasterBreedID = parseInt(this.petsinfo.MasterBreedID)
        this.petsinfo.ImagePath = "";
        this.petsinfo.croppedFileData = "";
        this.petsinfo.DateOfBirth = "";
        this.petsinfo.IsChipped = (this.petsinfo.IsChipped == "1") ? 1 : 0;
        this.petsinfo.OperationTypeID = 2;
        this.petsinfo.UserLoginID = this.currentUser.ID;
        this.personalsinfo.LoginUserID = this.currentUser.ID;
        this.personalsinfo.GDPRUserStatus = (this.personalsinfo.GDPRUserStatus) ? 1 : 0;
        this.personalsinfo.IsTermsAccepted = (this.personalsinfo.IsTermsAccepted) ? 1 : 0;
        this.pets.petDetails = this.petsinfo;
        this.pets.userInfo = this.personalsinfo;
        if(typeof Image == 'string'){
          this.pets.petDetails.petImgUrl = Image;
        }
        let petDetail = JSON.stringify(this.pets);
        const formData = new FormData();
        formData.append('petDetails', petDetail);
        if(typeof Image != 'string'){
          formData.append('Image', Image);
        }
        formData.append('croppedFileData', croppedFileData);
        this.Savesresponse(formData);
      }
    }
  }
  onHide(data) {
    this.showPersonalInfo = false
  }

  Savesresponse(petDetails) {

    this.SpinnerService.show();
    this.petsService.addUpdateLostFoundPetDetails(petDetails).subscribe((res: any) => {
      this.SpinnerService.hide();
      if (res.error == "") {
        alert("Innlegget ditt er sendt. Klikk OK for å se kjæledyr som går tapt i området der du fant kjæledyret.");
        //alert("Your post has been successfully submitted. Click OK to see pets that are lost in the area where you found the pet.");
        //this.router.navigate(['/list-pets'], { queryParams: { OperationTypeID: 1,radiousDistance:5,lat: this.petsinfo.lat,lng:this.petsinfo.lng } });
        this.router.navigate(['/list-pets'], {
          queryParams: {
            OperationTypeID: 1,
            radiousDistance: 10,
            lat: this.petsinfo.lat,
            lng: this.petsinfo.lng,
            SpeciesID: this.petsinfo.MasterSpeciesID,
            BreedID: this.petsinfo.MasterBreedID
          }
        });
      }
      else {
        alert(res.error);
      }
    })
  }

  toggleFoundSuggestion() {
    this.showFoundSuggestion = !this.showFoundSuggestion;
  }
  CloseSuggestion(eve: any) {
    eve.target.parentNode.parentNode.remove();
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: '250px',
      data: { name: "string name", animal: "this.animal" }
    });
  }

  //go to pet details page
  goToPetDetails = (petDetail: any) => {

    const petName = `${petDetail.Color ? petDetail.Color : ''} ${petDetail.MasterBreed ? petDetail.MasterBreed : ''} ${petDetail.MasterSpecies ? petDetail.MasterSpecies : ''} ${(petDetail.OperationTypeID == "1") ? 'Tapt' : 'Funnet'} I ${petDetail.City ? petDetail.City : ''}`
    const urlString = petName.trim().toLowerCase().split(' ').join('-');
    const removeChar = urlString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const encodedString = encodeURI(removeChar);
    const atobStr = btoa(petDetail.LostFoundID);
    localStorage.setItem('_page', 'report-found');
    window.open(`/list-pets/pet-detail/${encodeURIComponent(atobStr)}/${encodedString}?opType=2`, '_blank');

  }
}