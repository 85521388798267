// import { Injectable } from '@angular/core';
// import { HttpClient, HttpHeaders  } from '@angular/common/http';
// import { environment } from '../../environments/environment';


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../_models';
import { config } from 'process';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class StaticPagesService {

  constructor(private http: HttpClient) { }
 
    GetPageStaticData(DisplayPageName: string)
    {
    let data = `${environment.apiUrl + environment.GetPageStaticData }?DisplayPageName=${DisplayPageName}`;
    return this.http.get<any>(data);
    }
}