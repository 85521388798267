import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PetsService, AuthenticationService } from '../_services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  petImagePath = environment.apiBaseUrl + "/Static/Images/";
  detailMessageString = environment.lostFoundBaseUrl + "dashboard/pet-detail?PetID=";
  detailMessageStringEMail = "Les mer : " + environment.lostFoundBaseUrl + "dashboard/pet-detail?PetID=";
  petDetailsList: any;
  currentUser: any;
  totalCount: any;
  DastotalLost: any;
  DastotalFound: any;
  DastotalReUnite: any;
  OperationTypeID: any;
  OperationTypeHeading: string;
  petLostIdToReunite: any = 0;
  petFoundIdToReunite: any = 0;
  petSuggestionList: any;
  showReportedPetSuggestion: boolean;
  showReunitePetButton: boolean;
  showReuniteDate: boolean;
  page = 1;
  pageSize = 10;
  HideSugg: boolean = false;


  constructor(private petService: PetsService, private authenticationService: AuthenticationService,
    private router: ActivatedRoute, private route: Router,) { }

  ngOnInit() {
    this.currentUser = this.authenticationService.currentUserValue;

    this.ChangePetList(1); /// by default show Lost Pet


  }

  ChangePetList(opTypeID: any) {
    this.page = 1;
    this.OperationTypeID = opTypeID

    if (this.OperationTypeID == 1) {
      this.OperationTypeHeading = "Lost";
      this.showReunitePetButton = true;
      this.showReuniteDate = false;
    } else if (this.OperationTypeID == 2) {
      this.OperationTypeHeading = "Found";
      this.showReunitePetButton = true;
      this.showReuniteDate = false;
    } else if (this.OperationTypeID == 3) {
      this.OperationTypeHeading = "Reunited";
      this.showReunitePetButton = false;
      this.showReuniteDate = true;
    } else {
      this.OperationTypeHeading = "Lost";
      this.showReunitePetButton = true;
      this.showReuniteDate = true;
    }
    this.getPaginationGrid(this.page);
  }

  ReunitePet(lostFoundId: any) {
    // debugger;
    if (this.OperationTypeID > 2) {
      return false;
    }
    let subtext = this.OperationTypeID == 1 ? "Found" : "Lost";
    if (this.OperationTypeID == 1) {
      this.petLostIdToReunite = lostFoundId;
      this.petFoundIdToReunite = 0;
      subtext = "found";
    } else {
      this.petLostIdToReunite = 0;
      this.petFoundIdToReunite = lostFoundId;
      subtext = "lost";
    }
    let message = "Are you sure wants to Re-Unite this pet";
    let flag = confirm(message);

    if (flag) {
      this.MarkPetAsReunite(this.petLostIdToReunite, this.petFoundIdToReunite);
    }
  }

  ReunitePetSuggestion(lostFoundId: any) {
    // debugger;
    if (this.OperationTypeID > 2) {
      return false;
    }
    let subtext = this.OperationTypeID == 1 ? "Found" : "Lost";
    if (this.OperationTypeID == 1) {
      this.petLostIdToReunite = lostFoundId;
      this.petFoundIdToReunite = 0;
      subtext = "found";
    } else {
      this.petLostIdToReunite = 0;
      this.petFoundIdToReunite = lostFoundId;
      subtext = "lost";
    }
    let message = "Do you want to check for any previously " + subtext + " reported pets";
    let flag = confirm(message);

    if (flag) {
      this.HideSugg = false;
      let postData = {
        "lostFoundId": lostFoundId
      }
      this.petService.GetPetSuggestion(postData).subscribe(response => {
        this.petSuggestionList = response;
        if (this.petSuggestionList.RecordsFiltered == 0) {
          this.petSuggestionList = ""
          alert("No previously reported Pet Found");
        } else {
          this.showReportedPetSuggestion = true;
        }
      });
    }
  }




  RemoveReportedPet(lostFoundId: any) {
    if (this.OperationTypeID > 2) {
      return false;
    }
    let flag = confirm("Are you sure you want to remove the reported pet ?");
    if (flag) {
      let postData = { "LostFoundId": lostFoundId, "UpdatedBy": this.currentUser.ID }
      this.petService.RemoveReportedPet(postData).subscribe(response => {
        if (response.data > 0) {
          alert("Reported Pet is removed Successfully !!!!");
          this.ChangePetList(this.OperationTypeID);
        } else {
          alert("error :" + response.error);
        }
      });
    }
  }
  toggleReportedPetSuggestion() {
    this.showReportedPetSuggestion = !this.showReportedPetSuggestion;
  }

  associatePetandReunite(assocoatedLostFoundId: any) {
    if (assocoatedLostFoundId > 0) {
      if (this.OperationTypeID == 1) {
        //this.petLostIdToReunite = lostFoundId;
        this.petFoundIdToReunite = assocoatedLostFoundId;
      } else {
        this.petLostIdToReunite = assocoatedLostFoundId;
        //this.petFoundIdToReunite = lostFoundId;
      }
      this.MarkPetAsReunite(this.petLostIdToReunite, this.petFoundIdToReunite);
      this.petSuggestionList = "";
    }
  }

  MarkPetAsReunite(PetLostID: any, PetFoundId: any) {
    let postData = { "PetLostId": PetLostID, "PetFoundId": PetFoundId, "UpdatedBy": this.currentUser.ID }
    this.petService.markPetAsReunited(postData).subscribe(response => {
      if (response.data > 0) {
        alert("Pet is Marked Re-United Sucessfully !!!");
        this.ChangePetList(this.OperationTypeID);
      } else {
        alert(response.error);
      }
    });
  }

  getPaginationGrid(page: number) {
    // debugger;
    this.page = page;
    let startIndex = (this.page == 1) ? 0 : (page - 1) * 10;
    let endIndex = startIndex + 10;


    let filters = {
      "StartIndex": startIndex,
      "EndIndex": endIndex,
      "UserId": this.currentUser.ID,
      "ListType": this.OperationTypeID,
      "Company": this.currentUser.Company
    }

    this.petService.GetLostFoundPetsListCountByUser(filters).subscribe(data => {
      this.DastotalLost = data.TotalLost;
      this.DastotalFound = data.TotalFound;
      this.DastotalReUnite = data.TotalReUnite;
    });

    this.petService.GetLostFoundPetsListByUser(filters).subscribe(response => {
      console.log(response);
      this.petDetailsList = response.data;
      this.totalCount = response.RecordsTotal;
    });


  }

  scrollTop = () => {
		const element = document.querySelector('#scrollId');
		element.scrollIntoView({ behavior: 'smooth' });
	}
  
  CloseSuggestion(eve: any) {
    this.HideSugg = true;
    //eve.target.parentNode.parentNode.setAttribute('class','bottom-search-msg-box hide-sugg');
    // eve.target.parentNode.parentNode.remove();
  }


  openModal(event) {
    document.getElementById(event.currentTarget.parentElement.nextElementSibling.id).style.display = "block";
  }
  closeModal(event) {
    document.getElementById(event.currentTarget.parentElement.id).style.display = "none";
  }

  shareWithFacebook(id): void {
    let shareUrl = this.detailMessageString + id;
    //shareUrl = 'https://lostfound.staging.dyreid.no/pet-detail?PetID=1099';
    let url = 'http://www.facebook.com/sharer.php?u=' + shareUrl;
    let newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus()
    }
  }

  //go to pet details page
  goToPetDetails = (petDetail: any) => {
    const petName = `${petDetail.Color ? petDetail.Color : ''} ${petDetail.MasterBreed ? petDetail.MasterBreed : ''} ${petDetail.MasterSpecies ? petDetail.MasterSpecies : ''} ${(this.OperationTypeID == "1") ? 'Tapt' : 'Funnet'} I ${petDetail.City ? petDetail.City : ''}`
    const urlString = petName.trim().toLowerCase().split(' ').join('-');
    const removeChar = urlString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    const encodedString = encodeURI(removeChar);
    const atobStr = btoa(petDetail.LostFoundID);
    localStorage.setItem('_page', 'dashboard');
    this.route.navigate([`list-pets/pet-detail/${atobStr}/${encodedString}`], {queryParams:{opType:this.OperationTypeID}});

  }
}
