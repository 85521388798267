import { Component } from '@angular/core';
import { AuthenticationService } from './_services';
import { User } from './_models';
import { AuthService } from 'angularx-social-login';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';
import { DateAdapter } from '@angular/material';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  currentUser: User;
  title = 'Lost and Found | DyreIDLostAndFound';
  report: boolean = false;
  list: boolean = false;
  static: boolean = false;
  currentPath:string = '';

  constructor(
    private authService: AuthService,
    public router: Router,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private titleService: Title, private metaService: Meta,
    private dateAdapter: DateAdapter<Date>
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
    translate.addLangs(['en', 'no']);
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|no/) ? browserLang : 'no');
    this.dateAdapter.setLocale('no');
  }
  ngOnInit() {
    this.router.events.subscribe((val: any) => {
      if (val && val.url) this.currentPath = val.url;
    });
    this.titleService.setTitle(this.title);

     if (environment.production == true) {
       if (location.protocol === 'http:') {
         window.location.href = location.href.replace('http', 'https');
       }
     }
  }

  ReportAddRemove() {
    this.report = !this.report;
    this.static = false;
    this.list = false;
    event.preventDefault();
  }
  ListAddRemove() {
    this.list = !this.list;
    this.report = false;
    this.static = false;
    event.preventDefault();
  }
  StaticAddRemove() {
    this.static = !this.static;
    this.report = false;
    this.list = false;
    event.preventDefault();
  }


  public _opened: boolean = false;
  public _closeOnClickOutside = true;

  public _toggleOpened(): void {
    this._opened = !this._opened;
  }

  public _onBackdropClicked(): void {
    console.info('Backdrop clicked');
  }
  Cancel() {
    this.router.navigate(['/']);
  }
  signOut(): void {
    this.authenticationService.logout();
    // this.router.navigate(['/login']);
    this.router.navigate(['']);
  }
  onActivate(event) {
    // console.log("Route activate")
    const firstElementWithError = document.querySelector('.xng-breadcrumb-root');
    this.scrollTo(firstElementWithError);
    //or document.body.scrollTop = 0;
    //or document.querySelector('body').scrollTo(0,0)

  }
  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ block: 'center' });
    }
  }
}


