<div class="profile-form list-view detail-cntnt">
  <div class="container" id="containerID">
    <div *ngIf="!showhomePageBrowseListing" class="row">
      <div class="col-md-12 list-right-cntnt" *ngIf="pet">
        <div class="row mob-margin">
          <div class="col-lg-12 mt-4 mb-3" *ngIf="! pet.MasterBreed">
            <button class="pet-title nav-btn" *ngIf="showhideBackButton" (click)="goBack()"><img src="assets/img/back_icn_nw.svg" alt=""
              title=""> {{'Back' | translate}} </button>
            <h2 class="pet-title">
              <span class="pet-title" *ngIf="pet.Color">{{pet.Color +" "}}</span>{{ pet.MasterSpecies}} {{ ((pet.OperationTypeID == "1") ?
              'Lost' : 'Found')| translate }} <span class="pet-title" *ngIf="pet.City">i {{pet.City}}</span>
              <!-- <span *ngIf="pet.Color">{{pet.Color +" "}}</span>{{ pet.MasterBreed +" "+ pet.MasterSpecies}}  {{ (pet.OperationTypeID == "1") ? 'Lost' : 'Found'}} <span *ngIf="pet.City">I {{pet.City}}</span>  -->

              
            </h2>
          </div>
          <div class="col-lg-12 mt-4 mb-3" *ngIf="pet.MasterBreed">
            <button class="pet-title nav-btn" *ngIf="showhideBackButton" (click)="goBack()"><img src="assets/img/back_icn_nw.svg" alt=""
              title=""> {{'Back' | translate}} </button>
            <h2 class="pet-title">
              <span class="pet-title" *ngIf="pet.Color">{{pet.Color +" "}}</span>{{pet.MasterBreed }} ({{pet.MasterSpecies}}) {{
              ((pet.OperationTypeID == "1") ? 'Lost' : 'Found') | translate}} <span class="pet-title" *ngIf="pet.City">i
                {{pet.City}}</span>
              <!-- <span *ngIf="pet.Color">{{pet.Color +" "}}</span>{{ pet.MasterBreed +" "+ pet.MasterSpecies}} {{ (pet.OperationTypeID == "1") ? 'Lost' : 'Found'}} <span *ngIf="pet.City">I {{pet.City}}</span> -->
              
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 flex-box">
            <button class="nav-btn" (click)="previousItem()" [disabled]="isPreviousDisabled"><img src="assets/img/back_icn_nw.svg" > {{'Previous' | translate}} </button>
            <button class="nav-btn" (click)="nextItem()" [disabled]="isNextDisabled"> {{'Next' | translate}} <img src="assets/img/front_icn_nw-01.svg" > </button>
          </div>
          
        </div>
        <div class="row mt-4">
          <div class="col-lg-6">
            <!-- <div class="img-box">
              <img src="{{ petImagePath + pet.ImagePath }}" alt=""  style="">
            </div> -->
              
            <div class="list-card-img scroller" (swipeleft)="onSwipe($event)" (swiperight)="onSwipe($event)">
              <div class="image-row-dashboard" *ngIf="pet.ThumbnailImagePath" id="ThumbnailImageBlockID">
                <img src="{{ petImagePath + pet.ThumbnailImagePath }}" (click)="openModal($event)" (error)="errorHandler($event)"
                  class="hover-shadow-dashboard cursor-dashboard image-class-dashboard">
              </div>
              <div class="image-row-dashboard" *ngIf="! pet.ThumbnailImagePath">
                <img src="{{ petImagePath + pet.ImagePath }}" (click)="openModal($event)" (error)="errorHandler($event)"
                  class="hover-shadow-dashboard cursor-dashboard image-class-dashboard">
              </div>
              <div attr.id="{{pet.LostFoundID}}" class="slide-modal-dashboard" style="justify-content: center;align-items: center;">
                <span class="close-dashboard cursor-dashboard" (click)="closeModal($event)">&times;</span>
                <div class="slide-modal-content-dashboard">
                  <img class="images-dashboard image-class-dashboard" src="{{ petImagePath + pet.ImagePath }}" (error)="errorHandler($event)">
                </div>
              </div>
            </div>


          </div>

          <div class="col-lg-6">
            <div class="cntnt-detail-box">
              <div class="">
                <span *ngIf="! pet.MasterBreed">
                  <!--<h1><span *ngIf="pet.Color">{{pet.Color +" "}}</span>{{ pet.MasterBreed +" "+ pet.MasterSpecies}} {{ (pet.OperationTypeID == "1") ? 'Lost' : 'Found'}} <span *ngIf="pet.City">I {{pet.City}}</span></h1>-->
                </span>

                <div class="pet-info-icn" *ngIf="pet.PetName">
                  <span class="img-box"><img src="assets/img/name_icn.svg" title=""></span>
                  {{"Name" | translate }}: <b>{{pet.PetName}}</b>
                </div>

                <div class="pet-info-icn" *ngIf="pet.ChipID">
                  <span class="img-box"><img src="assets/img/chip_id.svg" title=""></span>
                  {{"Chip ID" | translate }}: <b>{{pet.ChipID}}</b>
                </div>

                <div class="pet-info-icn" *ngIf="pet.Area">
                  <span class="img-box"><img src="assets/img/location_nw.svg" title=""></span>
                  <span class="location-address">
                    <span class="pet-info-areas">{{"Area"| translate }}: </span>
                    <span class="location-areddres">
                    <span class="addrespets"><b>{{pet.Area}}</b></span>
                    <!--<br class="mob"> -->
                    <span class="pet-zipcode"><b>{{' '+pet.ZipCode +' '+ pet.City}} </b></span>
                  </span>
                </span>
                </div>

                <div class="pet-info-icn pet-info-icn-other" *ngIf="pet.DisplayUpdatedDate">
                  <span class="img-box daticn"><img src="assets/img/date_icn.svg" title=""></span>
                  <span class="updatelostdte" *ngIf="pet.DisplayLostFoundDate">
                    <span class="lostfound">{{"Lost/Found"| translate }}:</span>
                    <span class="lostfounddate"> <b>{{pet.DisplayLostFoundDate}} </b></span>
                  
                  </span>
                    <!-- <span class="mob-p">|&nbsp;</span> <br class="desktop-br"> -->
                  <span class="updatedte" *ngIf="pet.DisplayUpdatedDate">
                    <span class="uplostfound">{{"Last Updated"| translate }}:</span>
                    <span class="uplostfounddate"><b>{{pet.DisplayUpdatedDate}}</b></span>
                  </span>
                </div>



                <div class="pet-info-small" *ngIf="pet.GDPRStatus">
                  <span class="petemld">
                    <a href="mailto:?subject={{ShareMessage}}&body={{detailMessageStringEMail}}{{pet.LostFoundID}}" class="petemlddtils">
                      <img src="assets/img/Ikon_mail.png" title="" /> <span class="petemilid"> {{ pet.userEmailID}} </span></a>
                  </span>
                  <span class="petcontnumbr">
                    <img src="assets/img/Ikon_mobil.png" title=""> <span class="petcontdetils">{{ pet.userContactNumber}} </span>
                  </span>
                </div>

                <div class="pet-info-social">
                  <a (click)="shareOnFacebook()" class="share-link share-facebook"><img src="assets/img/Ikon_facebook.png" title="facebook"></a>
                  <!-- <a href="#" target="popup" shareButton="facebook" [url]="detailMessageString + pet.LostFoundID"
                    class="share-link share-facebook"><img src="assets/img/ic-social_facebook.svg" title="facebook"></a> -->
                  <!-- <a href=""><img src="assets/img/ic-social_twitter.svg" title="twitter"></a> -->
                  <!-- <a class="share-link share-mail" href="mailto:?subject={{ShareMessage}}&body={{detailMessageStringEMail}}{{pet.LostFoundID}}"> -->
                  <a class="share-link share-mail pointer">
                    <img src="assets/img/ic-social_print.svg" title="Generate Pdf file" (click)="printPDF()"></a>
                </div>


              </div>
            </div>

          </div>
        </div>
        <!-- <qrcode [qrdata]="qrCodeString" [width]="70" [errorCorrectionLevel]="'M'" [elementType]="'svg'"></qrcode> -->
        <div class="pet-additional-info" *ngIf="pet.AdditionalInfo">
          <div class="row">
            <div class="col-md-12">
              <h4 class="lft-line-hdng">{{"Additional Info" | translate}}</h4>
            </div>
            <div class="col-md-12">
              <div class="additional-info-cntnt">
                <p [innerHTML]="pet.AdditionalInfo">{{pet.AdditionalInfo}} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="pet-specifications">
          <div class="row">
            <div class="col-md-12">
              <h4 class="lft-line-hdng">{{"Specifications" | translate }}</h4>
            </div>
            <div class="col-md-6 border-rght-box">
              <div class="specification-cntnt" *ngIf="pet.MasterSpecies">
                <span>{{"Species" | translate }}</span> {{pet.MasterSpecies}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.MasterBreed">
                <span>{{"Breed" | translate }}</span> {{pet.MasterBreed}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.Gender">
                <span>{{ 'Gender' | translate }}</span> {{ pet.Gender}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.HairType">
                <span>{{ 'HairType' | translate }}</span> {{ pet.HairType}}
              </div>
            </div>

            <div class="col-md-6 padd-lft-box">
              <div class="specification-cntnt" *ngIf="pet.Color">
                <span>{{"Color" | translate }}</span> {{pet.Color}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.DisplayDateOfBirth">
                <span>{{"Birth Date" | translate }}</span> {{pet.DisplayDateOfBirth}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.PetName">
                <span>{{"Identification" | translate }}</span> {{pet.PetName}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.Company">
                <span>{{"Company" | translate }}</span> {{pet.Company}}
              </div>
            </div>
          </div>
        </div>
        <div class="pet-location-map">
          <div class="row">
            <div class="col-md-12">
              <h4 class="lft-line-hdng">{{'Location on map' | translate}}</h4>
            </div>
            <div class="col-md-12">
              <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="false"></agm-marker>
              </agm-map>
            </div>
          </div>
        </div>

        
        <div class="last-social-icns">
          <div class="row">
            <div class="col-md-12">
              <div class="pet-info-small" *ngIf="pet.GDPRStatus">
                <span>
                  <a href=""><img src="assets/img/Ikon_mail.png" title=""> {{ pet.userEmailID}}</a>
                </span>
                <span>
                  <img src="assets/img/Ikon_mobil.png" title=""> {{ pet.userContactNumber}}
                </span>
              </div>

              <div class="pet-info-social">
                <a (click)="shareOnFacebook()" class="share-link share-facebook"><img src="assets/img/Ikon_facebook.png" title="facebook"></a>
                <!-- <a href="#"><img src="assets/img/ic-social_twitter.svg" title="twitter"></a> -->
                <a class="share-link share-mail pointer" href="#">
                  <img src="assets/img/ic-social_print.svg" title="Generate pdf file" (click)="printPDF()"></a>
                <!-- <a class="share-link share-mail" href="mailto:?subject={{ShareMessage}}&body={{detailMessageStringEMail}}{{pet.LostFoundID}}">
                  <img src="assets/img/ic-social_print.svg" title="Print Pdf"></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="showhomePageBrowseListing" class="row">
      <div class="col-md-12 list-right-cntnt" *ngIf="pet">
        <div class="row mob-margin">
          <div class="col-lg-12 mt-4 mb-3" *ngIf="! pet.Breed">
            <button class="pet-title nav-btn" *ngIf="showhideBackButton" (click)="goBack()"><img src="assets/img/back_icn_nw.svg" alt=""
              title=""> {{'Back' | translate}} </button>
            <h2 class="pet-title">
              <span class="pet-title" *ngIf="pet.PetColor">{{pet.PetColor +" "}}</span>{{ pet.Breed}} {{ 'in your neighbourhood | translate' }}
              <!-- <span *ngIf="pet.Color">{{pet.Color +" "}}</span>{{ pet.MasterBreed +" "+ pet.MasterSpecies}}  {{ (pet.OperationTypeID == "1") ? 'Lost' : 'Found'}} <span *ngIf="pet.City">I {{pet.City}}</span>  -->

              
            </h2>
          </div>
          <div class="col-lg-12 mt-4 mb-3" *ngIf="pet.Breed">
            <button class="pet-title nav-btn" *ngIf="showhideBackButton" (click)="goBack()"><img src="assets/img/back_icn_nw.svg" alt=""
              title=""> {{'Back' | translate}} </button>
            <h2 class="pet-title">
              <span class="pet-title" *ngIf="pet.PetColor">{{pet.PetColor +" "}}</span>{{pet.Breed }} ({{pet.Species }}) {{
                'in your neighbourhood' | translate}} 
              <!-- <span *ngIf="pet.Color">{{pet.Color +" "}}</span>{{ pet.MasterBreed +" "+ pet.MasterSpecies}} {{ (pet.OperationTypeID == "1") ? 'Lost' : 'Found'}} <span *ngIf="pet.City">I {{pet.City}}</span> -->
              
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 flex-box">
            <button class="nav-btn" (click)="previousItem()" [disabled]="isPreviousDisabled"><img src="assets/img/back_icn_nw.svg" > {{'Previous' | translate}} </button>
            <button class="nav-btn" (click)="nextItem()" [disabled]="isNextDisabled"> {{'Next' | translate}} <img src="assets/img/front_icn_nw-01.svg" > </button>
          </div>
          
        </div>
        <div class="row mt-4">
          <div class="col-lg-6">
            <!-- <div class="img-box">
              <img src="{{ petImagePath + pet.ImagePath }}" alt=""  style="">
            </div> -->
              
            <div class="list-card-img scroller" (swipeleft)="onSwipe($event)" (swiperight)="onSwipe($event)">
              <div class="image-row-dashboard" *ngIf="pet.PetOriginalPhoto" id="ThumbnailImageBlockID">
                <img src="{{ pet.PetOriginalPhoto }}" (click)="openModal($event)" (error)="errorHandler($event)"
                  class="hover-shadow-dashboard cursor-dashboard image-class-dashboard">
              </div>
              <div class="image-row-dashboard" *ngIf="! pet.PetOriginalPhoto">
                <img src="{{ pet.PetPhoto }}" (click)="openModal($event)" (error)="errorHandler($event)"
                  class="hover-shadow-dashboard cursor-dashboard image-class-dashboard">
              </div>
              <div attr.id="{{pet.ChipId}}" class="slide-modal-dashboard" style="justify-content: center;align-items: center;">
                <span class="close-dashboard cursor-dashboard" (click)="closeModal($event)">&times;</span>
                <div class="slide-modal-content-dashboard">
                  <img class="images-dashboard image-class-dashboard" src="{{ pet.PetPhoto }}" (error)="errorHandler($event)">
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="cntnt-detail-box">
              <div class="">
                <span *ngIf="! pet.Breed">
                  <!--<h1><span *ngIf="pet.Color">{{pet.Color +" "}}</span>{{ pet.MasterBreed +" "+ pet.MasterSpecies}} {{ (pet.OperationTypeID == "1") ? 'Lost' : 'Found'}} <span *ngIf="pet.City">I {{pet.City}}</span></h1>-->
                </span>

                <div class="pet-info-icn" *ngIf="pet.PetName">
                  <span class="img-box"><img src="assets/img/name_icn.svg" title=""></span>
                  {{"Name" | translate }}: <b>{{pet.PetName}}</b>
                </div>

                <div class="pet-info-icn" *ngIf="pet.AddressLine1">
                  <span class="img-box"><img src="assets/img/location_nw.svg" title=""></span>
                  <span class="location-address">
                    <span class="pet-info-areas">{{"Area"| translate }}: </span>
                    <span class="location-areddres">
                    <span class="addrespets"><b>{{pet.AddressLine1}}</b></span>
                    <!--<br class="mob"> -->
                    <span class="pet-zipcode"><b>{{' '+pet.ZipCode +' '+ pet.City}} </b></span>
                  </span>
                </span>
                </div>
                <div class="pet-info-small" *ngIf="pet.OwnerFullName">
                  <span>
                    <a href="mailto:?subject={{ShareMessage}}&body={{detailMessageStringEMail}}{{pet.LostFoundID}}"><img
                        src="assets/img/Ikon_mail.png" title=""> {{ pet.OwnerFullName}}</a>
                  </span>
                  <span>
                    <img src="assets/img/Ikon_mobil.png" title=""> {{ pet.PrimaryTelephoneNo}}
                  </span>
                </div>

                <div class="pet-info-social">
                  <a (click)="shareOnFacebook()" class="share-link share-facebook"><img src="assets/img/Ikon_facebook.png" title="facebook"></a>
                  <!-- <a href="#" target="popup" shareButton="facebook" [url]="detailMessageString + pet.LostFoundID"
                    class="share-link share-facebook"><img src="assets/img/ic-social_facebook.svg" title="facebook"></a> -->
                  <!-- <a href=""><img src="assets/img/ic-social_twitter.svg" title="twitter"></a> -->
                  <!-- <a class="share-link share-mail" href="mailto:?subject={{ShareMessage}}&body={{detailMessageStringEMail}}{{pet.LostFoundID}}"> -->
                  
                </div>


              </div>
            </div>

          </div>
        </div>
        <div class="pet-additional-info" *ngIf="pet.AdditionalInfo">
          <div class="row">
            <div class="col-md-12">
              <h4 class="lft-line-hdng">{{"Additional Info" | translate}}</h4>
            </div>
            <div class="col-md-12">
              <div class="additional-info-cntnt">
                <p [innerHTML]="pet.AdditionalInfo">{{pet.AdditionalInfo}} </p>
              </div>
            </div>
          </div>
        </div>
        <div class="pet-specifications">
          <div class="row">
            <div class="col-md-12">
              <h4 class="lft-line-hdng">{{"Specifications" | translate }}</h4>
            </div>
            <div class="col-md-6 border-rght-box">
              <div class="specification-cntnt" *ngIf="pet.Species">
                <span>{{"Species" | translate }}</span> {{pet.Species}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.Breed">
                <span>{{"Breed" | translate }}</span> {{pet.Breed}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.PetSex">
                <span>{{ 'Gender' | translate }}</span> {{ pet.PetSex}}
              </div>
            </div>

            <div class="col-md-6 padd-lft-box">
              <div class="specification-cntnt" *ngIf="pet.PetColor">
                <span>{{"Color" | translate }}</span> {{pet.PetColor}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.BirthDate">
                <span>{{"Birth Date"| translate }}</span> {{pet.BirthDate}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.PetName">
                <span>{{"Identification" | translate }}</span> {{pet.PetName}}
              </div>
              <div class="specification-cntnt" *ngIf="pet.ClinicName">
                <span>{{"Company" | translate }}</span> {{pet.ClinicName}}
              </div>
            </div>
          </div>
        </div>
        <div class="pet-location-map">
          <div class="row">
            <div class="col-md-12">
              <h4 class="lft-line-hdng">{{'Location on map' | translate}}</h4>
            </div>
            <div class="col-md-12">
              <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
                <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="false"></agm-marker>
              </agm-map>
            </div>
          </div>
        </div>

        
        <div class="last-social-icns">
          <div class="row">
            <div class="col-md-12">
              <div class="pet-info-small" *ngIf="pet.OwnerFullName">
                <span>
                  <a href=""><img src="assets/img/Ikon_mail.png" title=""> {{ pet.PrimaryEmail}}</a>
                </span>
                <span>
                  <img src="assets/img/Ikon_mobil.png" title=""> {{ pet.PrimaryTelephoneNo}}
                </span>
              </div>

              <div class="pet-info-social">
                <a (click)="shareOnFacebook()" class="share-link share-facebook"><img src="assets/img/Ikon_facebook.png" title="facebook"></a>
                <!-- <a href="#"><img src="assets/img/ic-social_twitter.svg" title="twitter"></a> -->
                <!--a class="share-link share-mail" href="#">
                  <img src="assets/img/ic-social_print.svg" title="Print Pdf"></a>-->
                <!-- <a class="share-link share-mail" href="mailto:?subject={{ShareMessage}}&body={{detailMessageStringEMail}}{{pet.LostFoundID}}">
                  <img src="assets/img/ic-social_print.svg" title="Print Pdf"></a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- template for the pdf file -->
<div id="print-me" class="magic">
  <!-- 
  <div style="width:100%;height: 400px;display: flex;justify-content: center;margin-top: 10px;">
    <img src="https://apilostfound.staging.dyreid.no/Static/Images/thumb_20210526193616_home_pet1.png" alt=""
      style="height: 85%;">
  </div> -->
  <table style="width:100%; margin:0; padding:0;" width="100%" border="0" cellpadding="0" cellspacing="0" height="100%">
    <tr style="background-color:#e84740;">
      <!-- <td align="left" valign="top" width="20%" style="height: 300px; line-height:250px; padding-left: 80px;"><img src="assets/img/logo-print.png" alt="" title="" /></td>
        <td  colspan="2"  align="left" valign="top" width="80%" style=" height: 300px; line-height:250px; padding-left: 80px;  font-size:120pt;  color: #fff;">Katt funnet</td> -->
      <td colspan="3" align="left" valign="top" style="height: 300px; line-height:250px;">
        <div style="width:20%; float: left;  padding-left: 80px;"><img src="assets/img/logo-print.png"
            style="width:150px;" alt="" title="" /></div>
        <div style="width:80%; float: left; font-size:120pt;  color: #fff;">Katt funnet</div>
      </td>
    </tr>
    <tbody>
      <tr>
        <td colspan="3" width="100%" align="center" valign="top" style="width:100%; padding:10px;">
          <img *ngIf="pet && pet.ThumbnailImagePath" [src]="petImagePath + pet.ThumbnailImagePath" height="600" />
        </td>
      </tr>
      <tr style="border-bottom: 1px solid #eee;">
        <td align="left" valign="top" width="50%"
          style="width:50%; padding-left: 80px; padding-top:20px; padding-bottom:20px;">
          <table cellpadding='0' cellspacing='0' border='0' width='100%'>
            <tr>
              <td align="left" valign="top" width="25%"
                style="padding: 2.2mm 2.2mm; font-size: 30pt; font-weight: bold;">{{"Name" | translate }}</td>
              <td align="left" valign="top" width="75%" style="padding: 2.2mm 2.2mm; font-size: 30pt;">{{pet?.PetName}}
              </td>
            </tr>
          </table>
        </td>
        <td align="left" valign="top" width="50%" style="width:50%; padding-top:20px; padding-bottom:20px;" colspan="2">
          <table cellpadding='0' cellspacing='0' border='0' width='100%'>
            <tr>
              <td align="left" valign="top" width="20%"
                style=" padding: 2.2mm 2.2mm ; font-size: 30pt; font-weight: bold;">{{"Breed" | translate }}</td>
              <td align="left" valign="top" width="80%" style=" padding: 2.2mm 2.2mm; font-size: 30pt;">
                {{pet?.MasterBreed}}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-bottom: 1px solid #eee;">
        <td align="left" valign="top" width="50%"
          style="width:50%; padding-left: 80px ;padding-top:20px; padding-bottom:20px;">
          <table cellpadding='0' cellspacing='0' border='0' width='100%'>
            <tr>
              <td align="left" valign="top" width="25%"
                style="padding: 2.2mm 2.2mm; font-size: 30pt; font-weight: bold;">{{ ((pet?.OperationTypeID == "1") ?
                'Lost' : 'Found')| translate }}</td>

              <td align="left" valign="top" width="75%" style=" padding: 2.2mm 2.2mm; font-size: 30pt;">
                {{pet?.DisplayLostFoundDate}}</td>
            </tr>
          </table>
        </td>
        <td align="left" valign="top" width="50" style="width:50%; padding-top:20px; padding-bottom:20px;" colspan="2">
          <table cellpadding='0' cellspacing='0' border='0' width='100%'>
            <tr>
              <td align="left" valign="top" width="20%"
                style=" padding: 2.2mm 2.2mm ; font-size: 30pt; font-weight: bold;">{{"Gender" | translate}}</td>
              <td align="left" valign="top" width="80%" style=" padding: 2.2mm 2.2mm; font-size: 30pt;">{{pet?.Gender}}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-bottom: 1px solid #eee;">
        <td align="left" valign="top" width="50%"
          style="width:50%; padding-left: 80px; padding-top:20px; padding-bottom:20px;">
          <table cellpadding='0' cellspacing='0' border='0' width='100%'>
            <tr>
              <td align="left" valign="top" width="25%"
                style=" padding: 2.2mm 2.2mm; font-size: 30pt; font-weight: bold;">{{"Area" | translate}}</td>
              <td align="left" valign="top" width="75%" style=" padding: 2.2mm 2.2mm; font-size: 30pt;">{{pet?.Area}}
              </td>
            </tr>
          </table>
        </td>
        <td align="left" valign="top" width="50%" style="width:50%; padding-top:20px; padding-bottom:20px;">
          <table cellpadding='0' cellspacing='0' border='0' width='100%' colspan="2">
            <tr>
              <td align="left" valign="top" width="20%"
                style=" padding: 2.2mm 2.2mm ; font-size: 30pt; font-weight: bold;">{{"HairType" | translate}}</td>
              <td align="left" valign="top" width="80%" style="padding: 2.2mm 2.2mm; font-size: 30pt;">{{
                pet?.HairType}}</td>
            </tr>
          </table>
        </td>
      </tr>
      <tr style="border-bottom: 1px solid #eee;">
        <td align="left" valign="top" width="50%"
          style="width:50%; padding-left: 80px; padding-top:20px; padding-bottom:20px;">
          <table cellpadding='0' cellspacing='0' border='0' width='100%'>
            <tr>
              <td align="left" valign="top" width="25%"
                style=" padding: 2.2mm 2.2mm; font-size: 30pt; font-weight: bold;">{{"Address" | translate}}</td>
              <td align="left" valign="top" width="75%" style=" padding: 2.2mm 2.2mm; font-size: 30pt;">
                {{pet?.AddressLine1}} {{' '+pet?.ZipCode +' '+ pet?.City}} </td>
            </tr>
          </table>
        </td>
        <td align="left" valign="top" width="50%"
          style="width:50%; padding-bottom: 160px; padding-top:22px; padding-bottom:20px;">
          <table cellpadding='0' cellspacing='0' border='0' width='100%' colspan="2">
            <tr>
              <td align="left" valign="top" width="20%"
                style=" padding: 2.2mm 2.2mm ; font-size: 30pt; font-weight: bold;">{{"Color" | translate}} </td>
              <td align="left" valign="top" width="80%" style=" padding: 2.2mm 2.2mm; font-size: 30pt;">{{pet?.Color}}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr>
        <td align="left" valign="top" width="100%" colspan="3"
          style="font-size:30pt; padding-left: 80px; font-weight: bold;  padding-top: 50px;" align="left" valign="top">
          {{"Additional Info" | translate}}</td>
      </tr>
      <tr>
        <td align="left" valign="top" width="100%" colspan="3"
          style="font-size:30pt; padding-left: 80px; padding-bottom: 60px;">{{pet?.AdditionalInfo}}</td>
      </tr>
      <tr>
        <td align="left" valign="top" width="100%" colspan="3"
          style="font-size:30pt; padding-left: 80px;  font-weight: bold; " align="left" valign="top">{{"History" | translate}}</td>
      </tr>
      <tr>
        <!-- history data here -->
        <td align="left" valign="top" colspan="3" style="font-size:30pt; padding-left: 80px; padding-bottom: 100px;"></td>
      </tr>
      <tr>
        <td align="left" valign="top" colspan="3">
          <div style="border:4px solid #000; width: 100%; float: left;">
            <div align="left" valign="top" style="font-size:30pt; padding-left:80px;  width:30%; float: left;">
              <div style="width:100%; float:left; font-size:30px; padding-top:18px;">{{"Contact" | translate}}:</div>
              <div style="font-weight:bold; width:100%; float:left;  font-size:30px;">{{pet?.userContactNumber}}</div>
            </div>
            <div align="center" valign="middle"
              style=" width:40%; height: 150px; line-height: 150px; float: left; background-color: #ec5e5e; color: #fff; font-size: 40pt; font-weight:bold; border-left:4px solid #000;  border-right:4px solid #000; ">
              <img src="assets/img/phone-call.png" alt="" title="" /> {{pet?.userContactNumber}}</div>
            <div align="left" valign="top"
              style=" width:30%; float: left; font-size:20pt; padding-top: 10px; padding-left: 10px;  ">
              <div style="width:100%; float:left;">
                <div style="width:16%; float: left; vertical-align: top; padding-top: 10px">
                  <qrcode [qrdata]="qrCodeString" [width]="80" [errorCorrectionLevel]="'M'" [elementType]="'svg'">
                  </qrcode>
                </div>
                <div style="width:80%; float: left; vertical-align: top; font-size: 15pt;">
                  Scan QR-koden for å gå <br />
                  til annonsen på <br />
                  dyrebar.no.
                </div>
              </div>
              <div
                style="width:100%; float:left; vertical-align: top; font-size: 20pt; font-weight:bold;  padding-top: 1px">
                Dyrebar.no/{{pet?.LostFoundID}}
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr>
        <td colspan="3" width="100%" align="center" valign="middle"
          style="font-size:20pt; color:#000; padding-top:10px;">Følg dyreid.no</td>
      </tr>
      <tr>
        <td colspan="3" width="100%" align="center" valign="middle" style="padding-top:10px;">
          <a href="#" style="padding-right:15px;"> <img src="assets/img/instagram-icon.png" alt="" title="" /> </a>
          <a href="#" style="padding-right:15px;"> <img src="assets/img/facebook-icon.png" alt="" title="" /> </a>
          <a href="#"> <img src="assets/img/twitter-icon.png" alt="" title="" /> </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>