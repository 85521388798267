<div class="profile-form">
    <div class="container">
       <div class="">
            <h2>{{'Minside Pet List' | translate}}</h2>
            <div class="search-pet-list-box">
                <div *ngIf="petCount > 0">
                    <div *ngFor="let item of petList"  class="search-pet-list">
                        <div class="search-pet-list-img">
                            <img src="assets/img/user_add.png" alt="" title="">
                        </div>
                        <div class="search-pet-list-cntnt">
                            <div class="search-pet-list-cntnt-inner">
                                <h3>{{item.PetName}}</h3>
                                <p>{{item.SpeciesName}}, {{item.BreedName}}</p>
                                <p>{{item.ChipId}}</p>
                            </div>
                        </div>
                        <div class="list-btn-box">
                            <a class="btn btn-secondary" [class.disabled]="item.isReportedLostInLostandFound" (click)="markPetAsLost(item)">Mark Lost</a>
                        </div>
                    </div>
                </div>
                <p *ngIf="petCount == 0" class="text-center">{{'No Record Found'|translate}}</p>
            </div> 
        </div>
    </div>
</div>  
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">{{'Please Wait.'| translate}} </p>  
</ngx-spinner> 