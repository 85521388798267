import { Component, OnInit, ViewChild, ElementRef, NgZone, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { AuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { Socialusers } from '../_models';
import { UserService,AuthenticationService, OwnerService } from '../_services';
import { AppComponent } from '../app.component';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})

export class UserProfileComponent implements OnInit {

  UserProfile: FormGroup;
  @Input() showPersonalInfo: boolean = false;
  constructor(
    public app: AppComponent,
    private formbuilder: FormBuilder,
    private router: Router,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private authService: AuthService,
    private authenticationService: AuthenticationService,
    private UserService: UserService,
    private ownerService: OwnerService,
    private SpinnerService: NgxSpinnerService,
  ) { 
  }
  submitted = false;
  showFoundSuggestion: boolean = true;
  public latitude: number;
  public longitude: number;
  public zoom: number;
  private geoCoder;
  private user: SocialUser;
  private loggedIn: boolean;
  private PersonalInfo:any;
  
  socialusers = new Socialusers(); 
  currentUser;
  componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'long_name',
    postal_code: 'short_name',
    postal_town: 'long_name',
    District: 'long_name',
    State:'long_name',
    administrative_area_level_2: 'long_name'
  };


  @ViewChild('searchp')
  private searchElementRef: ElementRef;

  ngOnInit() {
    this.UserProfile = this.formbuilder.group({
      FirstName: ['',[Validators.required,this.noWhitespaceValidator]],
      LastName: [''],
      EmailID: ['', [Validators.required,this.noWhitespaceValidator]],
      Address: [''],
      ZipCode: [''],
      City: [''],
      District: [''],
      State: [''],
      Country:[''],
      ContactNumber: ['', [Validators.required,this.noWhitespaceValidator]],
      GDPRUserStatus: [],
      Provider:[],
     });
     this.currentUser = this.authenticationService.currentUserValue; 
    
      if(this.currentUser){
        
        this.UserService.getUserByEmailID(this.currentUser.EmailID,this.currentUser.Provider).subscribe(res => {
          this.PersonalInfo = res.data;

          this.UserProfile.controls['FirstName'].patchValue(this.PersonalInfo.FirstName)
          this.UserProfile.controls['LastName'].patchValue(this.PersonalInfo.LastName)
          this.UserProfile.controls['EmailID'].patchValue(this.PersonalInfo.EmailID)
          this.UserProfile.controls['ContactNumber'].patchValue(this.PersonalInfo.ContactNumber)
          if(this.PersonalInfo.Address){
            this.UserProfile.controls['Address'].patchValue(this.PersonalInfo.Address)
            this.UserProfile.controls['ZipCode'].patchValue(this.PersonalInfo.Zipcode)
            this.UserProfile.controls['City'].patchValue(this.PersonalInfo.City)
            this.UserProfile.controls['District'].patchValue(this.PersonalInfo.District)
            this.UserProfile.controls['State'].patchValue(this.PersonalInfo.State)
            this.UserProfile.controls['Country'].patchValue(this.PersonalInfo.Country)
          }
          /*
          else{
            //Easy Connect
            this.SpinnerService.show();
            if(this.PersonalInfo.ContactNumber){
              this.ownerService.getUserDetailByPhone(this.PersonalInfo.ContactNumber).subscribe(resp => {
                
		if(resp.Data.IsSuccess){
                  this.UserProfile.controls['Address'].patchValue(resp.Data.fullstreet)
                  this.UserProfile.controls['ZipCode'].patchValue(resp.Data.zip)
                  this.UserProfile.controls['City'].patchValue(resp.Data.city)
                  this.UserProfile.controls['District'].patchValue(resp.Data.district)
                  this.UserProfile.controls['State'].patchValue(resp.Data.state)
                  this.UserProfile.controls['Country'].patchValue(resp.Data.country)
                }
                
              });
            }
            this.SpinnerService.hide();
          }
          */
        });
      }
   //load Places Autocomplete
   this.mapsAPILoader.load().then(() => {
    this.geoCoder = new google.maps.Geocoder;

    let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      types: []
    });
    autocomplete.addListener("place_changed", () => {
      this.ngZone.run(() => {
        //get the place result
        let place: google.maps.places.PlaceResult = autocomplete.getPlace();

        //verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        // Get each component of the address from the place details,
        // and then fill-in the corresponding field on the form.
        this.UserProfile.controls['Address'].patchValue(place.formatted_address);

        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];
          if (this.componentForm[addressType]) {
            var val = place.address_components[i][this.componentForm[addressType]];
            
            if (addressType == "locality") {              
              this.UserProfile.controls['City'].patchValue(val)
            }
            if(addressType == "postal_town"){
              this.UserProfile.controls['City'].patchValue(val)
            }
            if (addressType == "postal_code") {           
              this.UserProfile.controls['ZipCode'].patchValue(val)
            }
            if (addressType == "administrative_area_level_1") {
              this.UserProfile.controls['State'].patchValue(val)
            }
            if (addressType == "administrative_area_level_2") {
              this.UserProfile.controls['District'].patchValue(val)
            }
            if (addressType == "country") {
              this.UserProfile.controls['Country'].patchValue(val)
            }
          }
        }

        //set latitude, longitude and zoom
        this.latitude = place.geometry.location.lat();
        this.longitude = place.geometry.location.lng();
        this.zoom = 12;
      });
    });
  });


  }
  @Output() onPosted = new EventEmitter();
  @Output() onHide = new EventEmitter();
  get e() {
    return this.UserProfile.controls;
  }
   
  onSubmit() {
    debugger;
    this.submitted = true; 
    // console.log(this.UserProfile.value);
    if (this.UserProfile.invalid) {
      this.scrollToError();
      return;
    }    
    this.UserProfile.value.Provider = this.PersonalInfo.Provider;
    // this.onPosted.emit(this.UserProfile.value)   
    this.UserService.UpdateUserInfo(this.UserProfile.value).subscribe(Response =>{
      alert("User Info Updated Sucessfully !!!");
    });
  }
  getDetailByPhone(){
    let phone = this.UserProfile.controls['ContactNumber'].value;
    let address = this.UserProfile.controls['Address'].value;
    
    if(phone != '' && address == ''){
      this.SpinnerService.show();
      
      this.ownerService.getUserDetailByPhone(phone).subscribe(resp => {
        if(resp.Data.IsSuccess){
          this.UserProfile.controls['Address'].patchValue(resp.Data.fullstreet)
          this.UserProfile.controls['ZipCode'].patchValue(resp.Data.zip)
          this.UserProfile.controls['City'].patchValue(resp.Data.city)
          this.UserProfile.controls['District'].patchValue(resp.Data.district)
          this.UserProfile.controls['State'].patchValue(resp.Data.state)
          this.UserProfile.controls['Country'].patchValue(resp.Data.country)
        }
        
      });
      this.SpinnerService.hide();
      
    }
    
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true };
}

scrollTo(el: Element): void {
  if (el) {
     el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
}

scrollToError(): void {
  const firstElementWithError = document.querySelector('.ng-invalid[formControlName]');
  this.scrollTo(firstElementWithError);
}

}
