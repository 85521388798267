import { Component, OnInit } from '@angular/core';
import { AuthService } from 'angularx-social-login';
import { SocialUser } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { Router,ActivatedRoute } from '@angular/router';
import { Socialusers } from '../_models';
import { AuthenticationService } from '../_services';
import { environment } from '../../environments/environment';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  response;  
  socialusers = new Socialusers(); 
  returnUrl: string;
  referUrl: string;
  referPage: string;
  currentUser: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private authenticationService:AuthenticationService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit() {

    this.referUrl = this.route.snapshot.queryParams['refer'];
    this.referPage = this.route.snapshot.queryParams['referPage'];
    if (this.referUrl == "minside"){
      if(localStorage["socialusers"] == undefined || localStorage["socialusers"] == null)
      {
        this.LoginWithMinside();
      }else{
        
        // User Logged IN 
        this.currentUser = this.authenticationService.currentUserValue;
        
          var userProvider = this.currentUser.Provider.toLowerCase();
          var username = "";
          var email = this.currentUser.EmailID;
          if(email == null){
            username = this.currentUser.ContactNumber;
          }else{
            username = email.toLowerCase();
          }
         
          var flag = confirm("User ("+ username+") already logged in from ("+userProvider+"). Do you wanted to end Current Session ?");
          if (flag){
            this.authenticationService.logout();
            this.router.navigate(['/login']);
            this.LoginWithMinside();
          }else {
            if((userProvider == 'facebook') || (userProvider == 'google')){
              this.router.navigate(['/']);
            }
            else{
              if(this.referPage == "found"){
                window.location.href = `${environment.lostFoundBaseUrl}/report-found`;
              }else{
                window.location.href = `${environment.lostFoundBaseUrl}/report-lost`;
              }
            }
          }
          
        
        
      }
    }
    

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    if(localStorage["socialusers"] != undefined || localStorage["socialusers"] != null)
    {
       this.router.navigate([this.returnUrl]);
    }
  }

  public socialSignIn(socialProvider: string) {  
    let socialPlatformProvider;  
    if (socialProvider === 'facebook') {  
      socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;  
    } else if (socialProvider === 'google') {  
      socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;  
    }  
    
    this.authService.signIn(socialPlatformProvider).then(user => {  
      //console.log(socialProvider, user);  
      //console.log(user);  

      this.socialusers.SocialID = user.id;
      this.socialusers.FirstName = user.firstName;
      this.socialusers.LastName = user.lastName;
      this.socialusers.EmailID = user.email;
      this.socialusers.AuthToken = user.authToken;
      this.socialusers.Provider = user.provider;

      this.Savesresponse(this.socialusers);  
    });  
  }  
  Savesresponse(socialusers: Socialusers) {  
    this.authenticationService.login(socialusers).subscribe((res: any) => {  
      this.socialusers = res.data;  
      localStorage.setItem('socialusers', JSON.stringify( this.socialusers));  
      this.router.navigate([this.returnUrl]);  
    })  
  }  

  LoginWithMinside(){
    
    if(this.referPage != null){
      var minsideUrl = environment.urlMinsideLogin + '&referPage='+this.referPage;
    }else{
      var minsideUrl = environment.urlMinsideLogin ;
    }
    var popup = window.open(`${minsideUrl}`, "Popup", "width=400,height=400");
    popup.open();
  }
  signOut(): void {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }


}
