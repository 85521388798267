import { Component, OnInit } from '@angular/core';
import { StaticPagesService} from '../_services';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-staticpages',
  templateUrl: './staticpages.component.html',
  styleUrls: ['./staticpages.component.scss']
})
export class StaticpagesComponent implements OnInit {

  constructor(
    private common: StaticPagesService,
    private router:ActivatedRoute
  ) { }

  pageName;
  pagedata;
  aboutus;
  services;
  termsandconditions;
  privacypolicy;
  ngOnInit(): void {
    this.pageName = this.router.snapshot.routeConfig.path; 
    if(this.pageName.toLocaleLowerCase().trim() == "aboutus") 
    {
      this.aboutus=true;
      this.services=false;
      this.termsandconditions=false;
      this.privacypolicy=false;
    }
    else if(this.pageName.toLocaleLowerCase().trim()== "services") 
    {
      this.aboutus=false;
      this.services=true;
      this.termsandconditions=false;
      this.privacypolicy=false;
    } else
    if(this.pageName.toLocaleLowerCase().trim()== "termsandconditions") 
    {
      this.aboutus=false;
      this.services=false;
      this.termsandconditions=true;
      this.privacypolicy=false;
    } else
    if(this.pageName.toLocaleLowerCase().trim()== "privacypolicy") 
    {
      this.aboutus=false;
      this.services=false;
      this.termsandconditions=false;
      this.privacypolicy=true;
    }
    this.GetPageStaticData(this.pageName);
  }

  GetPageStaticData(pageName) {
    //let DisplayName='aboutus';
		 let filters = {
      "DisplayName":'aboutus'
		 }

     // var data = this.common.GetPageStaticData(pageName);
     this.common.GetPageStaticData(pageName).subscribe(res =>
     {
      this.pagedata = res.data;
    });
	}

}
