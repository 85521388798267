<div class="lost-pet-info">
  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <h2 class="mt-5 mb-5 text-center">
      <span class="active left-count">1</span>
      {{ 'Lost Pet Info' | translate }}
      <span class="right-count" (click)="onSubmit()">2</span>
    </h2>
    <div class="row">
      <div class="col-md-3 col-sm-12 text-center mb-5">
        <div class="choose-file-custom" [ngClass]="{'is-invalid':submitted && petf.Image.errors}">
          <img [src]="previewUrl" alt="" title="">
          <input type="file" (change)="fileProgress($event)" formControlName="Image" />
        </div>
        <div style="font-size: 80%;color: #dc3545;" id="error-msg">{{ imageError }}</div>
        <div *ngIf="submitted && petf.Image.errors" class="invalid-feedback">
          <div *ngIf="petf.Image.errors.required">{{'Pet Image is required' | translate}}</div>
        </div>
      </div>

      <div class="col-md-9 col-sm-12">
        <div class="form-group align-items-center">
          <label class="col-sm-3 col-form-label">{{ 'Do you have chip ID?' | translate }} </label>
          <div class="col-sm-8 d-flex form-input-side">
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="IsChipped" (change)="onIsChippedChange()"
                id="IsChippedYes" value="1">
              <label class="form-check-label" for="IsChippedYes">{{ 'Yes' | translate }}</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" formControlName="IsChipped" (change)="onIsChippedChange()"
                id="IsChippedNo" value="0">
              <label class="form-check-label" for="IsChippedNo">{{ 'No' | translate }}</label>
            </div>
            <div class="col pr-0" *ngIf="registerForm.controls.IsChipped.value == '1'">
              <input type="text" class="form-control-plaintext" #ChipID placeholder="Enter chip ID here"
                formControlName="ChipID" minlength="4" maxlength="20"
                [ngClass]="{'is-invalid':submitted && petf.ChipID.errors}" (blur)="getChipInfo();">
              <div *ngIf="submitted && petf.ChipID.errors" class="invalid-feedback">
                <div *ngIf="petf.ChipID.errors.required">{{'Chip Id is required' | translate}}</div>
                <div *ngIf="petf.ChipID.errors.minlength">{{'Chip Id is of more then 4 char'| translate}}</div>
                <div *ngIf="petf.ChipID.errors.maxlength">{{'Chip Id is not more then 20 char' | translate}}</div>
                <div *ngIf="petf.ChipID.errors.whitespace && !petf.ChipID.errors.required">{{'Space is not allowed'|
                  translate}}</div>
              </div>
              <span style="color: green;font-size: 13px;">{{ chipFound }}</span>
            </div>
          </div>
        </div>
        <div class="form-group align-items-center" *ngIf="registerForm.controls.IsChipped.value == '1'">
          <label class="col-sm-3 col-form-label">{{ 'Show chip numbers to others' | translate }}</label>
          <div class="col-sm-8 form-input-side">
            <input type="checkbox" checked="isChipIdToShowInDetail" (change)="isChipIdToShowInDetail = $event.target.checked" formControlName="IsChipIDinDetail">
          </div>
        </div>
        <div class="form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label required-star">{{ 'Pet Name' | translate }}</label>
          <div class="col-sm-8 form-input-side">
            <input type="text" class="form-control-plaintext" minlength="3" maxlength="60" formControlName="PetName"
              [ngClass]="{'is-invalid':submitted && petf.PetName.errors}">
            <div *ngIf="submitted && petf.PetName.errors" class="invalid-feedback">
              <div *ngIf="petf.PetName.errors.required">{{'Pet name is required'| translate}}</div>
              <div *ngIf="petf.PetName.errors.minlength">{{'Pet name is of more then 3 char'| translate}}</div>
              <div *ngIf="petf.PetName.errors.maxlength">{{'Pet name is not more then 60 char' | translate}}</div>
              <div *ngIf="petf.PetName.errors.whitespace && !petf.PetName.errors.required">{{'Space is not allowed'|
                translate}}</div>
            </div>
          </div>
        </div>

        <div class=" form-group align-items-center">
          <label for="MasterSpecies" class="col-sm-3 col-form-label required-star">{{ 'Choose Species' | translate
            }}</label>
          <div class="col-sm-8 form-input-side">
            <!-- <ng-select bindValue="Value" bindLabel="Text" [items]="MasterSpecies" formControlName="MasterSpeciesID"
              [ngClass]="{'is-invalid': petf.MasterSpeciesID.errors}" (change)=getBreeds()>
            </ng-select> -->
            <select formControlName="MasterSpeciesID" style="width: 100%;"
              [ngClass]="{'is-invalid':submitted && petf.MasterSpeciesID.errors}" (change)=getBreeds()>
              <option value="" selected disabled>Select</option>
              <option *ngFor="let spc of MasterSpecies" [value]="spc.Value">{{spc.Text}}</option>
            </select>
            <div *ngIf="submitted && petf.MasterSpeciesID.errors" class="invalid-feedback">
              <div *ngIf="petf.MasterSpeciesID.errors.required">{{'Species is required' | translate}}</div>
              <!-- <div *ngIf="registerForm.get('MasterSpeciesID').errors.required">{{'Species is required' | translate}}</div> -->
            </div>

          </div>
        </div>

        <div class="  form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label required-star">{{ 'Breed' | translate }}</label>
          <div class="col-sm-8 form-input-side">

            <!-- <ng-select bindValue="Value" bindLabel="Text" [items]="MasterBreeds" formControlName="MasterBreedID"
              [ngClass]="{'is-invalid':submitted && petf.MasterBreedID.errors}" (change)=getSuggestion()>
            </ng-select> -->
            <select formControlName="MasterBreedID" style="width: 100%;"
              [ngClass]="{'is-invalid':submitted && petf.MasterBreedID.errors}" (change)=getSuggestion()>
              <option value="" selected disabled>Select</option>
              <option *ngFor="let brd of MasterBreeds" [value]="brd.Value">{{brd.Text}}</option>
            </select>
            <div *ngIf="submitted && petf.MasterBreedID.errors" class="invalid-feedback">
              <div *ngIf="petf.MasterBreedID.errors.required">{{'Breed is required'| translate}}</div>
            </div>
          </div>
        </div>

        <div class="form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label">{{ 'Gender' | translate }}</label>
          <div class="col-sm-8 form-input-side">
            <div class="gender-optn">
              <div class="form-check form-check-inline col-md-3 col-sm-12">
                <input class="form-check-input" type="radio" formControlName="Gender" id="male" [value]="1">
                <label class="form-check-label" for="male">{{ 'Male' | translate }}</label>
              </div>
              <div class="form-check form-check-inline col-md-3 col-sm-12">
                <input class="form-check-input" type="radio" formControlName="Gender" id="female" [value]="2">
                <label class="form-check-label" for="female">{{ 'Female' | translate }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label">{{ 'HairType' | translate }}</label>
          <div class="col-sm-8 form-input-side">
            <div class="gender-optn">
              <div class="form-check form-check-inline col-md-3 col-sm-12">
                <input class="form-check-input" type="radio" formControlName="HairType" id="longhair" value="1">
                <label class="form-check-label" for="longhair">{{ 'LongHair' | translate }}</label>
              </div>
              <div class="form-check form-check-inline col-md-3 col-sm-12">
                <input class="form-check-input" type="radio" formControlName="HairType" id="sorthair" value="2">
                <label class="form-check-label" for="sorthair">{{ 'ShortHair' | translate }}</label>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label">{{ 'Color' | translate }}</label>
          <div class="col-sm-8 form-input-side">
            <input type="text" class="form-control-plaintext" value="" formControlName="Color">
          </div>
        </div>

        <div class="form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label">{{ 'Date of Birth' | translate }}</label>
          <div class="col-sm-8 form-input-side">
            <mat-form-field>
              <mat-label>{{ 'Choose a date' | translate }}</mat-label>
              <input matInput [matDatepicker]="DateOfBirth" [max]="lostMaxDate" formControlName="DateOfBirth">
              <mat-datepicker-toggle matSuffix [for]="DateOfBirth"></mat-datepicker-toggle>
              <mat-datepicker #DateOfBirth></mat-datepicker>
            </mat-form-field>

          </div>
        </div>

        <div class="form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label required-star">{{ 'Date when Lost' | translate }}</label>
          <div class="col-sm-5 form-input-side">

            <mat-form-field>
              <mat-label>{{ 'Choose a date' | translate }}</mat-label>
              <input matInput [matDatepicker]="LostFoundDate" [max]="maxDate" formControlName="LostFoundDate"
                (dateChange)="UpdateDobMaxDate();">
              <mat-datepicker-toggle matSuffix [for]="LostFoundDate"></mat-datepicker-toggle>
              <mat-datepicker #LostFoundDate></mat-datepicker>
            </mat-form-field>
            <div *ngIf="submitted && petf.LostFoundDate.errors" class="invalid-feedback">
              <div *ngIf="petf.LostFoundDate.errors.required">{{'Date Of found is required'| translate}}</div>
            </div>
          </div>
          <div *ngIf="isMinsideUser" class="col-sm-3 form-input-side">
            <button type="button" (click)="onClickFillDate()" class="btn btn-secondary">{{ 'Fill date' | translate
              }}</button>
          </div>
        </div>

        <div class="form-group align-items-center">
          <label for="" class="col-sm-3 col-form-label required-star">{{ 'Area where lost' | translate }}</label>
          <div class="col-sm-5 form-input-side">
            <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" formControlName="Area"
              placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off" spellcheck="off" type="text"
              #search [ngClass]="{'is-invalid':submitted && petf.Area.errors}">
            <span class="map-info">{{ 'MapInfo' | translate }}</span>
            <div *ngIf="submitted && petf.Area.errors" class="invalid-feedback">
              <div *ngIf="petf.Area.errors.required">{{'Area is required'| translate}}</div>
            </div>
          </div>
          <div *ngIf="isMinsideUser" class="col-sm-3 form-input-side">
            <button type="button" (click)="onClickFillAddress()" class="btn btn-secondary">{{ 'Fill Address' | translate
              }}</button>
          </div>
        </div>

        <div class="form-group map-lbl1">
          <label class="col-sm-3 col-form-label">{{ 'Map' | translate }}</label>
        </div>

        <div class="form-group">
          <label class="col-sm-3 col-form-label map-lbl2">{{ 'Map' | translate }}</label>
          <div class="col-sm-8 form-input-side map-mob" style="width: 600px;height: 300px;">
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)">
              </agm-marker>
            </agm-map>
          </div>
        </div>

        <div class="  form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label required-star">{{ 'Zipcode' | translate }}</label>
          <div class="col-sm-8 form-input-side">
            <input type="text" class="form-control-plaintext" value="" formControlName="ZipCode"
              [ngClass]="{'is-invalid':submitted && petf.ZipCode.errors}">
            <div *ngIf="submitted && petf.ZipCode.errors" class="invalid-feedback">
              <div *ngIf="petf.ZipCode.errors.required">{{'Zipcode is required'| translate}}</div>
              <div *ngIf="petf.ZipCode.errors.whitespace">{{'Space is not allowed'| translate}}</div>
            </div>
          </div>
        </div>

        <div class="  form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label required-star">{{ 'City' | translate }}</label>
          <div class="col-sm-8 form-input-side">

            <input type="text" class="form-control-plaintext" value="" formControlName="City"
              [ngClass]="{'is-invalid':submitted && petf.City.errors}">

            <div *ngIf="submitted && petf.City.errors" class="invalid-feedback">
              <div *ngIf="petf.City.errors.required">{{'City is required'| translate}}</div>
              <div *ngIf="petf.City.errors.whitespace && !petf.City.errors.required">{{'Space is not allowed'|
                translate}}</div>
            </div>
          </div>
        </div>

        <div class="  form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label">{{ 'District' | translate }}</label>
          <div class="col-sm-8 form-input-side">

            <input type="text" class="form-control-plaintext" value="" formControlName="District"
              [ngClass]="{'is-invalid':submitted && petf.District.errors}">

            <div *ngIf="submitted && petf.District.errors" class="invalid-feedback">
              <div *ngIf="petf.District.errors.whitespace">{{'Space is not allowed'| translate}}</div>
            </div>
          </div>
        </div>

        <div class="  form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label required-star">{{ 'State' | translate }}</label>
          <div class="col-sm-8 form-input-side">

            <input type="text" class="form-control-plaintext" value="" formControlName="State"
              [ngClass]="{'is-invalid':submitted && petf.State.errors}">

            <div *ngIf="submitted && petf.State.errors" class="invalid-feedback">
              <div *ngIf="petf.State.errors.required">{{'State is required'| translate}}</div>
              <div *ngIf="petf.State.errors.whitespace && !petf.State.errors.required">{{'Space is not allowed'|
                translate}}</div>
            </div>
          </div>
        </div>
        <div class="  form-group align-items-center">
          <label for=" " class="col-sm-3 col-form-label required-star">{{ 'Country' | translate }}</label>
          <div class="col-sm-8 form-input-side">
            <!-- <ng-select bindValue="Text" bindLabel="Text" [items]="country" formControlName="Country"
            [ngClass]="{'is-invalid':submitted && petf.Country.errors}">
          </ng-select> -->
            <select formControlName="Country" style="width: 100%;">
              <option value="" selected disabled>Select</option>
              <option *ngFor="let cntry of country" [value]="cntry.Text">{{cntry.Text}}</option>
            </select>
            <div *ngIf="submitted && petf.Country.errors" class="invalid-feedback">
              <div *ngIf="petf.Country.errors.required">{{'Country is required'| translate}}</div>
            </div>
          </div>
        </div>

        <div class="  form-group">
          <label for=" " class="col-sm-3 col-form-label">{{ 'Additional Info' | translate }}</label>
          <div class="col-sm-8 form-input-side">
            <textarea class="form-control" id="exampleFormControlTextarea1" formControlName="AdditionalInfo"
              rows="3"></textarea>
          </div>
        </div>

        <div class="btns-box row">
          <div class="mt-2 mb-4 col-md-12">
            <button type="submit" class="btn btn-primary mr-3">{{ 'Next' | translate }}</button>
            <button type="button" class="btn btn-secondary" (click)=this.app.Cancel()>{{ 'Cancel' | translate
              }}</button>
            <button class="btn btn-lg btn-outline-primary" id="contentNew" (click)="open(content)"
              style="display: none;">Modal</button>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{'Crop Image'| translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">{{'Save'|
      translate}}</button>
  </div>
  <div class="modal-body">
    <div class="text-center col-md-12">
      <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="4 / 4"
        [resizeToWidth]="256" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
        (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </div>
    <div class="text-center col-md-4">
      <h5>{{'Preview'|translate}}</h5>
      <img [src]="croppedImage" />
    </div>
  </div>

</ng-template>