<div class="profile-form companyprofile">
    <div class="container">
        <div class="text-center mt-3">
            <h3>{{'Company User Login' | translate }}</h3>
          </div>
          <div class="card">
    <div class="card-body">
         <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                <div class="form-group align-items-center">
                <label for="username" class="col-sm-3 col-form-label required-star">{{'Email'| translate}}</label>
                <div class="col-sm-9 form-input-side">
                <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
                <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required">{{'Username is required' | translate}}</div>
                    <div *ngIf="f.username.errors.pattern">{{'Please Enter a valid Email-ID' | translate}}</div>
                    <div *ngIf="f.username.errors.whitespace && !f.username.errors.required">{{'Space is not allowed'| translate}}</div>
                </div>
                </div>
            </div>

           
           <div class="form-group align-items-center">
                <label for="password" class="col-sm-3 col-form-label required-star">{{'Password'| translate}}</label>
                <div class="col-sm-9">
                   <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                 </div>
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required">{{'Password is required' | translate}}</div>
                </div>
            </div>
            <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    {{'Login'| translate}}
                </button>
                <!-- <a routerLink="../register" class="btn btn-link">Register</a> -->
            </div>
        </form> 
    </div>
</div>
</div>
</div>