import { Component, OnInit, ViewChild, ElementRef, NgZone, Inject } from '@angular/core';
import { PetsService } from '../../_services';
import { MapsAPILoader } from '@agm/core';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DOCUMENT, Location } from '@angular/common';
import { Options } from 'ng5-slider';
import * as $ from 'jquery'

@Component({
	selector: 'app-list',
	templateUrl: './list.component.html',
	styleUrls: ['./list.component.scss']
})

export class ListComponent implements OnInit {

	constructor(
		private pets: PetsService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private formbuilder: FormBuilder,
		private mapsAPILoader: MapsAPILoader,
		private ngZone: NgZone,
		private location: Location,
	) { }

	searchForm: FormGroup;
	MapView = true;
	GridView = false;
	hideLostFoundDetail = false;
	OperationTypeID: any = "1";
	MasterSpecies: any;
	MasterBreeds: any;
	petImagePath = environment.apiBaseUrl + "/Static/Images/";
	petDetailPath = environment.apiBaseUrl + "/pet-detail?PetID=";
	petList: any;
	submitted = false;
	page = 1;
	pageSize = 12;
	private geoCoder;
	@ViewChild('search')
	private searchElementRef: ElementRef;
	totalCount: any;
	totalLost: any;
	totalFound: any;
	totalReUnite: any;
	calledFrom: string = "";
	queryParams: any;
	options: Options = {
		floor: 0,
		ceil: 100
	};
	petDetailsList: any[];

	// google maps zoom level
	zoom: any = 13;

	// initial center position for the map
	lat: any;
	lng: any;
	radiousMils: any;
	minClusterSize = 50;
	tabActive: number = 1;
	Pets: any = {};

	ngOnInit() {
		// window.addEventListener('scroll', this.handleScroll, true);
		this.searchForm = this.formbuilder.group({
			SpeciesID: [''],
			BreedID: [''],
			Area: [''],
			lat: [''],
			lng: [''],
			radiousDistance: ['10'],
			OperationTypeID: ['1']
		});

		$(document).ready(function () {
			$('#showmenu').click(function () {
				$('.mbl-fliterdropw').slideToggle("fast");
			});
		});

		$(document).ready(function () {
			$('#gobtnclk').click(function () {
				$('.mbl-fliterdropw').hide();
			});
		});

		$(document).ready(function () {
			$('.mbllist-map-filter .icon-view.map-icn').click(function () {
				$('.mbllist-map-filter .icon-view.list-icn').show();
				$('.mbllist-map-filter .icon-view.map-icn').hide();
			});
			$('.mbllist-map-filter .icon-view.list-icn').click(function () {
				$('.mbllist-map-filter .icon-view.map-icn').show();
				$('.mbllist-map-filter .icon-view.list-icn').hide();
			});
		});


		const firstElementWithError = document.querySelector('.xng-breadcrumb-root');
		this.scrollTo(firstElementWithError);
		//load Places Autocomplete
		this.mapsAPILoader.load().then(() => {

			this.geoCoder = new google.maps.Geocoder;

			let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
				types: []
			});
			autocomplete.addListener("place_changed", () => {
				this.ngZone.run(() => {
					//get the place result
					let place: google.maps.places.PlaceResult = autocomplete.getPlace();

					//verify result
					if (place.geometry === undefined || place.geometry === null) {
						return;
					}
					this.searchForm.controls['Area'].patchValue(place.formatted_address)
					this.searchForm.controls['lat'].patchValue(place.geometry.location.lat());
					this.searchForm.controls['lng'].patchValue(place.geometry.location.lng());

					//set latitude, longitude and zoom
					this.lat = place.geometry.location.lat();
					this.lng = place.geometry.location.lng();
					this.zoom = 13;
					localStorage.setItem('ListPageNo', "");
					this.onSubmit();

				});
			});
		});
		let ListView = 'Map';
		ListView = localStorage.getItem('ListView');
		if (ListView) {
			this.GridView = (ListView == 'List') ? true : false;
			this.MapView = (ListView == 'Map') ? true : false;
		}

		this.queryParams = this.activatedRoute.snapshot.queryParams;
		if ((this.queryParams.callingFrom == "home" || localStorage.getItem('_calledFrom') == "home") && this.queryParams.callingFrom !== "menu") {
			this.hideLostFoundDetail = true;
		}
		this.calledFrom = this.queryParams.callingFrom;
		let SearchFilters = localStorage.getItem('SearchFilters');
		if (SearchFilters) {
			this.queryParams = JSON.parse(SearchFilters);
		}

		this.pets.getSpecies().subscribe(species => {
			//if calling from home show dog and cat only in thr species dropdown
			this.MasterSpecies = this.queryParams.callingFrom == "home" ? [{ Text: "Dog", Value: 1 }, { Text: "Cat", Value: 2 }] : species;
			if (this.queryParams.speciesId) {
				this.searchForm.controls['SpeciesID'].patchValue(Number(this.queryParams.speciesId))
				this.tabActive = this.queryParams.speciesId;
				this.getBreeds();
				this.Pets = {
					petName: this.queryParams.speciesId == 1 ? "Dogs" : "Cats"
				}
			}
			if (this.queryParams.SpeciesID) {
				this.searchForm.controls['SpeciesID'].patchValue(Number(this.queryParams.SpeciesID))
				this.tabActive = this.queryParams.SpeciesID;
				this.getBreeds();
				this.Pets = {
					petName: this.queryParams.SpeciesID == 1 ? "Dogs" : "Cats"
				}
			}
		});
		if (this.queryParams.lat) {
			this.searchForm.controls['lat'].patchValue(this.queryParams.lat)
			this.searchForm.controls['lng'].patchValue(this.queryParams.lng)
			this.lat = Number(this.queryParams.lat);
			this.lng = Number(this.queryParams.lng);
			this.mapsAPILoader.load().then(() => {
				this.zoom = 13;
				this.getAddress(Number(this.queryParams.lat), Number(this.queryParams.lng));
			});
		}
		this.searchForm.controls['radiousDistance'].patchValue(10);
		if (this.queryParams.radiousDistance) {
			this.searchForm.controls['radiousDistance'].patchValue(this.queryParams.radiousDistance)
		}
		this.searchForm.controls['OperationTypeID'].patchValue("1")
		if (this.queryParams.OperationTypeID) {
			this.searchForm.controls['OperationTypeID'].patchValue(this.queryParams.OperationTypeID)
			this.OperationTypeID = this.queryParams.OperationTypeID;
		}
		this.searchForm.controls['Area'].patchValue("")
		if (this.queryParams.Area) {
			this.searchForm.controls['Area'].patchValue(this.queryParams.Area)
		}

		if (this.queryParams.radiousDistance) {
			this.radiousMils = this.queryParams.radiousDistance;
		}
		if (!this.queryParams.lat || this.queryParams.lat == '') {
			let self = this;
			navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
				if (result.state === 'granted') {
					self.mapsAPILoader.load().then(() => {
						self.setCurrentLocation();
					});
				} else if (result.state === 'prompt' || result.state === 'denied') {
					window.alert("GPS Is turned off or Location access permission denied");
				}
				// Don't do anything if the permission was denied.
			});

		}
	}

	// handleScroll = (event) => {
	// 	console.log(event.srcElement.scrollTop)
	// }

	scrollPosition = (e: HTMLElement) => {
		// console.log('HERE')
		// this.pageScrollService.scroll({
		// 	document: this.documentD,
		// 	scrollTarget: '.theEnd',
		// 	// scrollOffset:100
		//   });
	}

	formatLabel(value: number) {
		this.radiousMils = value;
		return value;
	}

	infoWindowOpened = null
	previous_info_window = null

	markers: marker[] = [
		{
			lat: 51.673858,
			lng: 7.815982,
			draggable: true
		}
	]

	close_window() {
		if (this.previous_info_window != null) {
			if(this.previous_info_window)this.previous_info_window.close()
		}
	}

	clickedMarker(label: string, index: number, infoWindow) {
		// console.log(this)

		if (this.previous_info_window == null)
			this.previous_info_window = infoWindow;
		else {
			this.infoWindowOpened = infoWindow
			if(this.previous_info_window)this.previous_info_window.close()
		}
		this.previous_info_window = infoWindow

		// console.log(`clicked the marker: ${label || index}`)
	}

	onSubmit() {
		this.submitted = true;
		this.page = 1;
		this.zoom = (Math.round(14 - Math.log(this.searchForm.value.radiousDistance) / Math.LN2)) + 1;
		this.OperationTypeID = this.searchForm.value.OperationTypeID;
		if (this.searchForm.invalid) {
			return;
		}
		let ListPageNo = localStorage.getItem('ListPageNo');
		if (ListPageNo) {
			this.page = Number(ListPageNo);
		}
		if (this.GridView) {
			this.getFilterPetsGrid(this.page);
		}
		if (this.MapView) {
			this.getFilterPetsMap();
		}

		//	this.location.replaceState('/asd');

	}

	toggleCluster() {
		this.minClusterSize = (this.minClusterSize < 5) ? 50 : 2;
		// console.log('toggleCluster  minClusterSize = ' + this.minClusterSize);
	}

	private setCurrentLocation() {
		if ("geolocation" in navigator) {
			navigator.geolocation.getCurrentPosition((position) => {
				this.lat = position.coords.latitude;
				this.lng = position.coords.longitude;
				// this.zoom = 13;
				this.zoom = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 11 : 13;

				(this.MapView) ? this.showMapView() : this.showGridView();
				this.getAddress(this.lat, this.lng);
			});

		}
	}

	public showGridView() {
		this.GridView = true;
		this.MapView = false;
		localStorage.setItem('ListView', (this.MapView) ? 'Map' : 'List');
		let ListPageNo = localStorage.getItem('ListPageNo');
		if (ListPageNo) {
			this.page = Number(ListPageNo);
		}
		this.getFilterPetsGrid(this.page);
	}

	public showMapView() {
		this.MapView = true;
		this.GridView = false;
		localStorage.setItem('ListView', (this.MapView) ? 'Map' : 'List');
		this.getFilterPetsMap();
	}

	getBreeds() {
		this.tabActive = (this.searchForm.value.SpeciesID == 1 || this.searchForm.value.SpeciesID == 2) ? this.searchForm.value.SpeciesID : 3

		this.searchForm.controls['BreedID'].patchValue("");
		localStorage.setItem('ListPageNo', "");
		this.Pets = {
			petName: this.queryParams.SpeciesID == 1 || this.searchForm.value.SpeciesID == 1 ? "Dogs" : "Cats"
		}
		this.pets.getBreeds(this.searchForm.value.SpeciesID).subscribe(breeds => {
			this.MasterBreeds = breeds;
			if (this.queryParams.BreedID && this.searchForm.value.SpeciesID == this.queryParams.SpeciesID) {
				this.searchForm.controls['BreedID'].patchValue(Number(this.queryParams.BreedID))
				this.onSubmit();
			}
			else {
				this.onSubmit();
			}

		});

		//this.getFilterPetsGrid();
	}

	getFilterPetsGrid(page: number, isPagination?: boolean) {
		this.page = page;
		let filters = {};
		let startIndex = (this.page == 1) ? 0 : (page - 1) * 10;
		let endIndex = startIndex + 10;
		if ((this.calledFrom == "home" || localStorage.getItem('_calledFrom') == "home") && this.calledFrom !== "menu") {
			filters = {
				"Radius": this.searchForm.value.radiousDistance,
				"latitude": this.lat,
				"longitude": this.lng,
				"petName": "",
				"SpeciesID": this.searchForm.value.SpeciesID == undefined || this.searchForm.value.SpeciesID == "" ? this.queryParams.speciesId : this.searchForm.value.SpeciesID,
				"BreedID": this.searchForm.value.BreedID == "" ? null : this.searchForm.value.BreedID,
				"LanguageId": 2,
				"PageSize": 10,
				"PageNumber": this.page
			}
			this.pets.GetLostFoundPetsListfromMincide(filters).subscribe(response => {
				this.petList = response;
				this.totalCount = this.petList.Data.RecordsTotal;
				this.petDetailsList = this.petList.Data.data;
				if (this.petDetailsList.length > 0) {
					for (let i = 0; i <= this.petDetailsList.length - 1; i++) {
						if (!this.petDetailsList[i].ThumbImagePath)
							this.petDetailsList[i].ThumbImagePath = "assets/img/home/lost-animl-bg.png";
						if (!this.petDetailsList[i].ImagePath)
							this.petDetailsList[i].ImagePath = "assets/img/home/lost-animl-bg.png";
						if (this.petDetailsList[i].ThumbImagePath) {
							const img = new Image();
							img.src = this.petDetailsList[i].ThumbImagePath;
							img.onerror = () => {
								this.petDetailsList[i].ThumbImagePath = "assets/img/home/lost-animl-bg.png";
							};
						}
						if (this.petDetailsList[i].ImagePath) {
							const img = new Image();
							img.src = this.petDetailsList[i].ImagePath;
							img.onerror = () => {
								this.petDetailsList[i].ImagePath = "assets/img/home/lost-animl-bg.png";
							};
						}

					}
				}
				this.pets.assignListData(this.petDetailsList);
				if (isPagination) this.scrollTop();
			});
			localStorage.setItem('ListPageNo', this.page.toString());
		}
		else {
			filters = {
				"startIndex": startIndex,
				"endIndex": endIndex,
				"latitude": this.lat,
				"longitude": this.lng,
				"radiousMils": this.searchForm.value.radiousDistance,
				"OperationTypeID": this.searchForm.value.OperationTypeID,
				"SpeciesID": this.searchForm.value.SpeciesID,
				"BreedID": this.searchForm.value.BreedID
			}
			this.pets.GetLostFoundPetsList(filters).subscribe(response => {
				// console.log(response)
				// console.log(response.totalCount)
				this.totalLost = response.TotalLost;
				this.totalFound = response.TotalFound;
				this.totalReUnite = response.TotalReUnite;
				this.totalCount = response.RecordsTotal;
				this.petDetailsList = response.data;
				this.pets.assignListData([]);
				if (isPagination) this.scrollTop();
			});
			localStorage.setItem('ListPageNo', this.page.toString());
		}
	}

	scrollTop = () => {
		const element = document.querySelector('#scrollId');
		element.scrollIntoView({ behavior: 'smooth' });
	}

	getFilterPetsMap() {
		// console.log(this.radiousMils);
		let filters = {};
		if ((this.calledFrom == "home" || localStorage.getItem('_calledFrom') == "home") && this.calledFrom !== "menu") {
			filters = {
				"Radius": this.searchForm.value.radiousDistance,
				"latitude": this.lat,
				"longitude": this.lng,
				"petName": "",
				"SpeciesID": this.searchForm.value.SpeciesID == undefined || this.searchForm.value.SpeciesID == "" ? this.queryParams.speciesId : this.searchForm.value.SpeciesID,
				"BreedID": this.searchForm.value.BreedID == "" ? null : this.searchForm.value.BreedID,
				"LanguageId": 2,
				"PageSize": 10000000,
				"PageNumber": this.page
			}
			this.pets.GetLostFoundPetsListfromMincide(filters).subscribe(res => {
				this.petList = res;
				this.petList.Data.data.forEach(function (element) {
					if (element.SpeciesId == 2)
						element.petIcon = "assets/img/location-caticon.png";
					else
						element.petIcon = "assets/img/location-dogicon.png";
				});
				//this.petList.Data.data.petIcon="assets/img/home/home_pet1.png";
				// if (this.petList.RecordsFiltered > 0) {
				//this.totalLost = this.petList.TotalLost;
				//this.totalFound = this.petList.TotalFound;
				//this.totalReUnite = this.petList.TotalReUnite;
				this.markers = this.petList.Data.data;
				this.totalCount = this.petList.Data.RecordsTotal;
				// console.log(this.markers);
				// }
			});
		}
		else {
			filters = {
				"lat": this.lat,
				"lng": this.lng,
				"radiousDistance": this.searchForm.value.radiousDistance,
				"OperationTypeID": this.searchForm.value.OperationTypeID,
				"SpeciesID": this.searchForm.value.SpeciesID,
				"BreedID": this.searchForm.value.BreedID
			}
			this.pets.GetLostFoundPetsListPartialFields(filters).subscribe(res => {
				this.petList = res;
				this.petList.data.forEach(function (element) {
					if (element.SpeciesName == "Hund")
						element.petIcon = "assets/img/location-dogicon.png";
					else
						element.petIcon = "assets/img/location-caticon.png";
				});
				// if (this.petList.RecordsFiltered > 0) {
				this.totalLost = this.petList.TotalLost;
				this.totalFound = this.petList.TotalFound;
				this.totalReUnite = this.petList.TotalReUnite;
				this.markers = this.petList.data,
					this.totalCount = this.petList.RecordsFiltered;
				// console.log(this.markers);
				// }
			});
		}
	}

	getAddress(latitude, longitude) {
		const localArea = JSON.parse(localStorage.getItem('_locArea'));
		//if same lat lng dont need to call geocoder api again
		if(localArea && localArea.lat == latitude && localArea.lng == longitude) {
			this.searchForm.controls['Area'].patchValue(localArea.area);
			this.searchForm.controls['lat'].patchValue(latitude);
			this.searchForm.controls['lng'].patchValue(longitude);
			(this.MapView) ? this.showMapView() : this.showGridView();
			return;
		}
		this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
			if (status === 'OK') {
				if (results[0]) {
					this.zoom = 11;
					// console.log(results[0].formatted_address);
					this.searchForm.controls['Area'].patchValue(results[0].formatted_address);
					this.searchForm.controls['lat'].patchValue(latitude);
					this.searchForm.controls['lng'].patchValue(longitude);
					const locObject = {lat:latitude, lng:longitude, area:results[0].formatted_address};
					localStorage.setItem('_locArea', JSON.stringify(locObject));

					(this.MapView) ? this.showMapView() : this.showGridView();
				} else {
					window.alert('No results found');
				}
			} else {
				window.alert('Geocoder failed due to: ' + status);
			}

		});
	}

	openModal(event) {
		document.getElementById(event.currentTarget.parentElement.nextElementSibling.id).style.display = "block";
	}

	closeModal(event) {
		document.getElementById(event.currentTarget.parentElement.id).style.display = "none";
	}

	resetSearchFilters() {
		this.OperationTypeID = "1";
		this.searchForm.controls.Area.reset();
		this.searchForm.controls['OperationTypeID'].patchValue("1")
		if(this.queryParams.callingFrom == "home"){
			this.searchForm.controls.SpeciesID.patchValue(this.queryParams.speciesId);
		}else{
			this.searchForm.controls.SpeciesID.patchValue('');
		}
		this.searchForm.controls.BreedID.patchValue('');
		// this.searchForm.controls.lat = this.lat;
		// this.searchForm.controls.lng = this.lng;
		localStorage.setItem("SearchFilters", "");
		this.setCurrentLocation();
		if(this.queryParams.value)this.queryParams.value = "";
		this.onSubmit();
		this.router.navigate(['/list-pets'], { queryParams: { OperationTypeID: this.OperationTypeID, radiousDistance: 10 } });
		//this.router.navigate([this.router.url]);

	}

	SearchPetByType(SearchPetID: any) {
		if (SearchPetID == 1) {
			this.tabActive = 1;
			this.searchForm.controls['SpeciesID'].patchValue(1);
			this.getBreeds();
		} else if (SearchPetID == 2) {
			this.tabActive = 2;
			this.searchForm.controls['SpeciesID'].patchValue(2);
			this.getBreeds();

		} else if (SearchPetID == 3) {
			this.tabActive = 3;
			this.searchForm.controls['SpeciesID'].patchValue('');
			this.searchForm.controls['BreedID'].patchValue('');
		}
		this.onSubmit()
	}

	ngOnDestroy(): void {
		//Called once, before the instance is destroyed.
		//Add 'implements OnDestroy' to the class.
		localStorage.setItem('SearchFilters', JSON.stringify(this.searchForm.value));
	}

	changeOperationType(id, event: any) {
		localStorage.setItem('ListPageNo', "");
		this.totalCount = '';
		this.searchForm.controls['OperationTypeID'].patchValue(id)
		var els = document.querySelectorAll('.active-stat');
		for (var i = 0; i < els.length; i++) {
			els[i].classList.remove('active-stat')
		}
		event.srcElement.classList.add("active-stat");
		this.onSubmit();
	}

	scrollTo(el: Element): void {
		if (el) {
			el.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}

	//go to pet details page
	goToPetDetails = (petDetail: any) => {
		let atobStr: string = "";
		const petName = this.calledFrom == "home" || localStorage.getItem('_calledFrom') == 'home' ? `${petDetail.Color ? petDetail.Color : ''} ${petDetail.BreedsName ? petDetail.BreedsName : ''} ${petDetail.SpeciesName ? petDetail.SpeciesName : ''} ${'i ditt nærområde'}` : `${petDetail.Color ? petDetail.Color : ''} ${petDetail.MasterBreed ? petDetail.MasterBreed : ''} ${petDetail.MasterSpecies ? petDetail.MasterSpecies : ''} ${(this.OperationTypeID == "1") ? 'Tapt' : 'Funnet'} I ${petDetail.City ? petDetail.City : ''}`;
		const petNameWithoutChar = petName.replace(/[^a-zA-Z ]/g, "");
		const urlString = petNameWithoutChar.trim().toLowerCase().split(' ').join('-');
		const removeChar = urlString.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
		const encodedString = encodeURI(removeChar);
		if ((this.calledFrom == "home" || localStorage.getItem('_calledFrom') == 'home') && this.calledFrom !== "menu") {
			atobStr = btoa(petDetail.ChipId);
			localStorage.setItem('_calledFrom', 'home');
		}
		else {
			atobStr = btoa(petDetail.LostFoundID);
			localStorage.setItem('_calledFrom', '');
		}
		localStorage.setItem('_page', 'list_page');
		this.router.navigate([`list-pets/pet-detail/${atobStr}/${encodedString}`], { queryParams: { opType: this.OperationTypeID } });

	}

	errorHandler = (e) => {
		e.target.src = environment.placeholderImg;
	}

}


// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable?: boolean;
	LostFoundID?: number;
	UserLoginID?: number;
	OperationTypeID?: number;
	PetName?: string;
	DateOfBirth?: string;
	ImagePath?: string;
	City?: string;
	MasterSpecies?: string;
	MasterBreed?: string;
	Color?: string;
}


interface Ipetdetalils {
	LostFoundID: number;
	UserLoginID: number;
	IsChipped: string;
	ChipID: string;
	OperationTypeID: number;
	PetName: string;
	PetType: string;
	SpeciesID?: number;
	MasterSpecies: string;
	BreedID?: number;
	MasterBreed: string;
	Gender: string;
	Casted: string;
	DateOfBirth?: string;
	DisplayDateOfBirth: string;
	LostFoundDate?: string;
	DisplayLostFoundDate: string;
	Area: string;
	Commune: string;
	City: string;
	State: string;
	StateID?: number;
	Country: string;
	CountryID?: number;
	AdditionalInfo: string;
	ImageID?: number;
	ImagePath: string;
	ThumbnailImagePath: string;
	Color: string;
	CoatLength: string;
	Description: string;
	lat: string;
	lng: string;
	DistancePoint: string;
	OwnerContactEmail: string;
	IsReunite: boolean;
	CreatedDate: string;
	CreatedBy: string;
	UpdatedDate: string;
	UpdatedBy: string;
	Status: boolean;
}