<div class="profile-form list-view mt-0">
  <div class="container">
    <div class="row">
      <div>&nbsp;</div>
      <div class="mt-4 mb-3 pl-2">
        <div class="col-md-12 search-filter-stats-box">
          <div class="search-filter-stats" style="border: none;">
            <div class="stats-inner" [ngClass]="{'active-stat' : OperationTypeID == 1}" (click)="ChangePetList( 1)"> {{
              (totalCount > 0 && OperationTypeID == 1)? DastotalLost : DastotalLost }} <span>{{'Lost'| translate
                }}</span></div>
            <div class="stats-inner" [ngClass]="{'active-stat' : OperationTypeID == 2}" (click)="ChangePetList( 2)"> {{
              (totalCount > 0 && OperationTypeID == 2)? DastotalFound :DastotalFound }} <span>{{'Found' |
                translate}}</span></div>
            <div class="stats-inner" [ngClass]="{'active-stat' : OperationTypeID == 3}" (click)="ChangePetList( 3)"> {{
              (totalCount >= 0 && OperationTypeID == 3)? DastotalReUnite :DastotalReUnite }} <span>{{'Re-Unite' |
                translate}}</span></div>
          </div>

          <!-- </div> -->
          <!-- </div> -->
        </div>
      </div>
      <div>&nbsp;</div>
    </div>
    <h1>{{ OperationTypeHeading | translate}} {{'Pets' | translate}}</h1>
    <div class="all-list-box" id="scrollId">
      <div class="row pet-box">
        <div class="col-md-6"
          *ngFor="let petDetail of petDetailsList | paginate: { id: 'server', itemsPerPage: 10, currentPage: page, totalItems: totalCount }">
          <div class="list-card">
            <div class="list-card-img q">
              <div class="image-row-dashboard" *ngIf="petDetail.ThumbImagePath">
                <img src="{{ petImagePath + petDetail.ThumbImagePath }}" (click)="openModal($event)"
                  class="hover-shadow-dashboard cursor-dashboard image-class-dashboard">
              </div>
              <div class="image-row-dashboard" *ngIf="! petDetail.ThumbImagePath">
                <img src="{{ petImagePath + petDetail.ImagePath }}" (click)="openModal($event)"
                  class="hover-shadow-dashboard cursor-dashboard image-class-dashboard">
              </div>
              <div attr.id="{{petDetail.LostFoundID}}" class="slide-modal-dashboard">
                <span class="close-dashboard cursor-dashboard" (click)="closeModal($event)">&times;</span>
                <div class="slide-modal-content-dashboard">
                  <img class="images-dashboard image-class-dashboard" src="{{ petImagePath + petDetail.ImagePath }}">
                </div>
              </div>
            </div>
            <!-- <div class="list-card-img q">                        
                       <img  [routerLink]="['pet-detail']" [queryParams]="{ PetID:petDetail.LostFoundID}" src="{{ petImagePath + petDetail.ThumbImagePath }}" alt="">
                      </div>
                      <div *ngIf="! petDetail.ThumbImagePath" class="list-card-img p">                        
                        <img  [routerLink]="['pet-detail']" [queryParams]="{ PetID:petDetail.LostFoundID}" src="{{ petImagePath + petDetail.ImagePath }}" alt="">
                       </div> -->
            <div class="list-card-cntnt">
              <div class="open-detail-click" (click)="goToPetDetails(petDetail)">
                <b *ngIf="! petDetail.MasterBreed">
                  <h5 class="petdetils"><span *ngIf="petDetail.Color">{{petDetail.Color +" "}}</span>{{
                    petDetail.MasterBreed +" "+
                    petDetail.MasterSpecies}} {{ ((OperationTypeID == "1") ? 'Lost' : 'Found') | translate}} <span
                      *ngIf="petDetail.City">i {{petDetail.City}}</span></h5>
                </b>
                <b *ngIf="petDetail.MasterBreed">
                  <h5 class="petdetils"><span *ngIf="petDetail.Color">{{petDetail.Color +" "}}</span>{{
                    petDetail.MasterBreed }}
                    ({{petDetail.MasterSpecies}}) {{ ((OperationTypeID == "1") ? 'Lost' : 'Found') | translate}} <span
                      *ngIf="petDetail.City">i {{petDetail.City}}</span></h5>
                </b>
                <!-- <h5>{{ petDetail.PetName }}</h5> -->

                <!-- <div class="spice-fullwdth"> <div class="spice-name">{{'Species' | translate}}:</div> <div  class="breed-namedetails">{{ petDetail.MasterSpecies }} </div> </div>
                <div  *ngIf="petDetail.MasterBreed" class="spice-fullwdth"> <div class="breed-name">{{'Breed' | translate}}:</div> <div class="breed-namedetails">{{ petDetail.MasterBreed }}</div> </div>
                <div *ngIf="showReuniteDate">{{ OperationTypeHeading | translate}}: {{
                  petDetail.DisplayUpdatedDate}}</div>
                <div *ngIf="!showReuniteDate" class="spice-fullwdth">
                  <div class="breed-name">{{ OperationTypeHeading | translate}}: </div> 
                  <div  class="breed-namedetails"> {{petDetail.DisplayLostFoundDate}}</div> </div> -->

                <!-- added from browser list page -->
                <p>{{ (petDetail.Area == null) ? "" : petDetail.Area }} {{ ((petDetail.ZipCode == null) ? "" :
                  petDetail.ZipCode) +" "+((petDetail.City == null) ? "" : petDetail.City)}}</p>
                <p style="display: block;"><span>{{ ((OperationTypeID == "1") ? 'Lost' : 'Found') | translate}} {{
                    'Date' | translate
                    }}:&nbsp;{{ petDetail.DisplayLostFoundDate}}</span> </p>
                <!-- added from browser list page -->
              </div>
              <div class="btn-box">
                <!--<button type="button" *ngIf="showReunitePetButton" class="btn btn-primary mr-3" >Share</button>-->
                <!-- <button type="button" *ngIf="showReunitePetButton" class="dashboard-btn btn btn-primary mr-3" >Edit</button> -->
                <img src="assets/img/Ikon_endre.png" ngbTooltip="Update" *ngIf="showReunitePetButton"
                  [routerLink]="['pet-detailupdate']" [queryParams]="{ PetID:petDetail.LostFoundID}" class="mr-3"
                  title="">
                <img src="assets/img/ic_view_orange.svg" class="mr-3" ngbTooltip="View"
                  (click)="goToPetDetails(petDetail)" title="">
                <!-- <a src="" class="dashboard-btn btn btn-secondary" [routerLink]="['pet-detail']" [queryParams]="{ PetID:petDetail.LostFoundID}">View</a> -->

                <img src="assets/img/Ikon_liste.png" ngbTooltip="Re-Unite Suggestions" class="mr-3"
                  *ngIf="showReunitePetButton" (click)="ReunitePetSuggestion(petDetail.LostFoundID)" title="">
                <img src="assets/img/ic_re-unite_orange.svg" ngbTooltip="Re-Unite Pet" class="mr-3"
                  *ngIf="showReunitePetButton" (click)="ReunitePet(petDetail.LostFoundID)" title="">
                <img src="assets/img/Ikon_slett.png" ngbTooltip="Delete" class="mr-3" *ngIf="showReunitePetButton"
                  (click)="RemoveReportedPet(petDetail.LostFoundID)" title="">
                <!-- <button type="button" *ngIf="showReunitePetButton" class="dashboard-btn btn btn-primary mr-3" (click)="ReunitePet(petDetail.LostFoundID)">Reunite</button>
                          <button type="button" *ngIf="showReunitePetButton" class="dashboard-btn btn btn-primary mr-3" (click)="RemoveReportedPet(petDetail.LostFoundID)">Remove</button> -->
              </div>
              <div class="btn-box">
                <a class="mr-3" *ngIf="showReunitePetButton"
                  href="mailto:?subject={{petDetail.ShareMessage}}&body={{detailMessageStringEMail}}{{petDetail.LostFoundID}}">
                  <img src="assets/img/Ikon_mail.png" class="mr-3" title="" ngbTooltip="Share message"></a>
                <img src="assets/img/Ikon_facebook.png" ngbTooltip="Share with Facebook" class="mr-3"
                  *ngIf="showReunitePetButton" (click)="shareWithFacebook(petDetail.LostFoundID)" title="">

                <!-- <a class="dashboard-btn btn btn-secondary" *ngIf="showReunitePetButton" href="mailto:?subject={{petDetail.ShareMessage}}&body={{detailMessageStringEMail}}{{petDetail.LostFoundID}}" ><img src="assets/img/ic_re-unite_orange.svg" class="mr-3"  title=""></a> 
                        <button type="button" *ngIf="showReunitePetButton" class="dashboard-btn btn btn-primary mr-3" (click)="shareWithFacebook(petDetail.LostFoundID)">Facebook</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center" *ngIf="petDetailsList && petDetailsList.length > 0">

        <pagination-controls (pageChange)="getPaginationGrid($event)" id="server"></pagination-controls>
      </div>
    </div>


    <div *ngIf="! HideSugg">
      <div class="bottom-search-msg-box" *ngIf="petSuggestionList">
        <div class="bottom-search-msg" (click)="toggleReportedPetSuggestion()">
          <a><span>{{ petSuggestionList.RecordsFiltered }}</span> {{'pets found on the basis of your inputs.' |
            translate}}</a>
          <a class="see-all-btn">{{'see all' | translate}}</a>
          <a class="see-all-btn Close-suggestion" (click)="CloseSuggestion($event)">X</a>
        </div>
        <div class="search-pet-list-box" [hidden]="!showReportedPetSuggestion">
          <div class="search-pet-list" *ngFor="let pets of petSuggestionList.data">
            <div class="search-pet-list-img">
              <img src="{{ petImagePath + pets.ImagePath }}" alt="" title="">
            </div>
            <div class="search-pet-list-cntnt">
              <div class="search-pet-list-cntnt-inner">
                <!-- <h3>{{ pets.LostFoundID }}</h3> -->
                <h3>{{ pets.PetName }}</h3>
                <p><b>{{'Species' | translate}}:</b> {{ pets.SpeciesName }}</p>
                <p><b>{{'Breed' | translate}}:</b> {{ pets.BreedsName }}</p>
              </div>
              <div class="list-btn-box">
                <a class="btn btn-primary" (click)="associatePetandReunite(pets.LostFoundID)">{{'This is The Pet' |
                  translate}}</a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>