<ng-sidebar-container (onBackdropClicked)="_onBackdropClicked()">
  <ng-sidebar [(opened)]="_opened" [mode]="'push'" [closeOnClickOutside]="_closeOnClickOutside" [position]="'left'">
    <button class="demo-control" (click)="_toggleOpened()">
      <svg height="311pt" viewBox="0 0 311 311.07733" width="311pt" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m16.035156 311.078125c-4.097656 0-8.195312-1.558594-11.308594-4.695313-6.25-6.25-6.25-16.382812 0-22.632812l279.0625-279.0625c6.25-6.25 16.382813-6.25 22.632813 0s6.25 16.382812 0 22.636719l-279.058594 279.058593c-3.136719 3.117188-7.234375 4.695313-11.328125 4.695313zm0 0" />
        <path
          d="m295.117188 311.078125c-4.097657 0-8.191407-1.558594-11.308594-4.695313l-279.082032-279.058593c-6.25-6.253907-6.25-16.386719 0-22.636719s16.382813-6.25 22.636719 0l279.058594 279.0625c6.25 6.25 6.25 16.382812 0 22.632812-3.136719 3.117188-7.230469 4.695313-11.304687 4.695313zm0 0" />
      </svg>
    </button>
    <div class="sidenav-options">
      <ul>
        <li routerLink="/" routerLinkActive="active" class=""><a>{{ 'Home' | translate }}</a></li>
        <!-- <li routerLink="/dashboard" routerLinkActive="active" class=""><a>{{ "Login" | translate }} </a></li> -->
        <!-- <li><a routerLink="/" routerLinkActive="active"> {{ "Logout" | translate }} </a></li> -->
        <li routerLink="/dashboard" routerLinkActive="active" class=""><a>{{ 'My Reported Pets' | translate }}</a></li>
        <li><a class="a-menu-dropdown" href="" (click)="ReportAddRemove();" [ngClass]="report ? 'open' : ''">{{ 'Report'
            | translate }} </a>
          <ul class="menu-dropdown">
            <li routerLink="/report-lost" routerLinkActive="active" class=""><a>{{ 'Report Lost Pet' | translate }}</a></li>
            <li routerLink="/report-found" routerLinkActive="active" class=""><a>{{ 'Report Found Pet' | translate }}</a></li>
          </ul>
        </li>
        <li><a class="a-menu-dropdown" href="" (click)="ListAddRemove();" [ngClass]="list ? 'open' : ''">{{'Browse' |
            translate}} </a>
          <ul class="menu-dropdown">
            <li routerLink="/list-pets" [queryParams]="{ OperationTypeID:1,radiousDistance:10, callingFrom:'menu'}" routerLinkActive="active" class=""><a href="list-pets?OperationTypeID=1&radiousDistance=10&callingFrom=menu">{{ 'Browse Lost Pets' |
                translate }}</a></li>
            <li routerLink="/list-pets" [queryParams]="{ OperationTypeID:2,radiousDistance:10, callingFrom:'menu'}" routerLinkActive="active" class=""><a href="list-pets?OperationTypeID=2&radiousDistance=10&callingFrom=menu">{{ 'Browse Found Pets' |
                translate }}</a></li>
          </ul>
        </li>
        <li><a class="a-menu-dropdown" href="" (click)="StaticAddRemove();" [ngClass]="static ? 'open' : ''">{{'About'|
            translate}} </a>
          <ul class="menu-dropdown">
            <li routerLink="/aboutus" routerLinkActive="active" class=""><a>{{ 'About Us' | translate }}</a></li>
            <li routerLink="/services" routerLinkActive="active" class=""><a>{{ 'Services' | translate }}</a></li>
            <li routerLink="/termsandconditions" routerLinkActive="active" class=""><a>{{ 'Terms & Conditions' | translate }}</a></li>
            <li routerLink="/privacypolicy" routerLinkActive="active" class=""><a>{{ 'Privacy Policy' | translate }}</a></li>
          </ul>
        </li>


        <!-- <li><a href="userprofile">{{ 'User Profile' | translate }}</a></li> -->
        <!-- <li *ngIf="currentUser"> <a onclick="signOut()">{{ 'Logout' | translate }}</a></li> -->
        <!-- href="privacypolicy">{{ 'Privacy Policy' | translate }}</a></li> -->
        <li><a href="">{{ 'Contact Us' | translate }}</a></li>
      </ul>
    </div>

  </ng-sidebar>
  <div ng-sidebar-content>
    <app-header></app-header>
    <div class="profile-form" [hidden]="this.router.url == '/'">
      <div class="container" *ngIf="!currentPath.includes('login')">
        <xng-breadcrumb [autoGenerate]="false" [separator]="iconTemplate">
          <ng-container *xngBreadcrumbItem="let breadcrumb; let info = info; let first = first">
            <mat-icon *ngIf="info">{{ info.icon }}</mat-icon>
            <!-- <ng-container *ngIf="!first">{{ breadcrumb |translate}}</ng-container> -->
            <ng-container>{{ breadcrumb |translate}}</ng-container>
          </ng-container>
        </xng-breadcrumb>
      </div>
    </div>
    <router-outlet (activate)="onActivate($event)"></router-outlet>
  </div>
</ng-sidebar-container>

<ng-template #iconTemplate>
  <mat-icon>arrow_right</mat-icon>
</ng-template>