
 <div class="profile-form">
  <div class="container">  
    <div *ngIf="showMinsidePetList == true; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
      <div style="margin: 10px 16px 10px 0px" class="text-right" >
        <button type="button" class="btn btn-primary mt-2" (click)="newLostPet()" >{{'Report Lost' | translate}}</button>
        <!--a routerLink="/report-found" class=" btn btn-secondary">{{ 'Report Found' | translate }}</a-->
      </div>
      
      <app-lost-petlist (onMarkLost)="onMarkLost($event)"></app-lost-petlist>
    </ng-template>
    <ng-template #elseBlock>
       <app-lostpetinfo (onPosted)="onPosted($event)" [showPersonalInfo] = "showPersonalInfo" [hidden] = "showPersonalInfo" [minsidePetInfo]="minsidePetInfo"></app-lostpetinfo>
       <app-lostpersonalinfo [hidden]="!showPersonalInfo"  (onHide)="onHide($event)" [showPersonalInfo] = "showPersonalInfo" (onPosted)="onPosted($event)" [minsideOwnerInfo]= "minsideOwnerInfo"></app-lostpersonalinfo> 
  
     <div class="bottom-search-msg-box" *ngIf="petsSuggestion">
      <div class="bottom-search-msg" (click)="toggleFoundSuggestion()">
        <a><span>{{ petsSuggestion.RecordsFiltered }}</span> {{ 'pets found on the basis of your inputs.' | translate }}</a>
        <a class="see-all-btn">{{ 'see all' | translate }}</a>
        <a class="see-all-btn Close-suggestion" (click)="CloseSuggestion($event)">X</a>
      </div>
      <div class="search-pet-list-box" [hidden] = "!showFoundSuggestion">          
        <div class="search-pet-list"  *ngFor="let pets of petsSuggestion.data">
          <div class="search-pet-list-img" *ngIf = "pets.ThumbImagePath">
            <img src="{{ petImagePath + pets.ThumbImagePath }}" alt="" title="">
          </div>
          <div class="search-pet-list-img" *ngIf = "! pets.ThumbImagePath">
            <img src="{{ petImagePath + pets.ImagePath }}" alt="" title="">
          </div>
          <div class="search-pet-list-cntnt">
            <div class="search-pet-list-cntnt-inner">
              <!-- <h3>{{ pets.LostFoundID }}</h3> -->
              <h3>{{ pets.PetName }}</h3>
              <p><b>{{ 'Species'| translate }}:</b> {{ pets.SpeciesName }}</p>
              <p><b>{{ 'Breed' | translate}}:</b> {{ pets.BreedsName }}</p>                
            </div>
            <div class="list-btn-box">
              <button class="btn btn-primary" mat-raised-button (click)="openDialog()">{{ 'Contact Reports' | translate}} </button>
              <a class="btn btn-secondary" (click)="goToPetDetails(pets)">{{ 'See Details' | translate}}</a>
            </div>
          </div>
        </div>  
      </div> 
    </div>
    </ng-template>
</div>
</div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">  
  <p style="color: white">{{'Please Wait.'| translate}} </p>  
  </ngx-spinner> 
